import { Stack } from '@mui/system';
import { RHFEditor } from '../../../components/hook-form';

const ProductLessonContentForm = () => (
  <Stack gap={2}>
    <RHFEditor name="content" />
  </Stack>
);

export default ProductLessonContentForm;

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { createContext, useMemo, useState, useReducer, useCallback, useRef } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import {
  deleteProductImageService,
  deleteProductService,
  getProductCategoriesService,
  getProductService,
  getAffiliateProductService,
  updateProductImageService,
  updateProductService,
  updateAffiliatePixelsService,
  getPaymentMethods,
} from '@/services/products';
import { getFee } from '@/services/financial';

export const EditProductContext = createContext({
  product: {},
  isFetchingProduct: false,
  updateProduct: () => {},
  isUpdatingProduct: false,
  categories: [],
  isFetchingCategories: false,
  deleteProduct: () => {},
  isDeletingProduct: false,
  cancelSubscriptions: () => {},
  isCancelingSubscriptions: false,
  updatePixels: () => {},
  isUpdatingFixels: false,
  differentOffers: false,
  setDifferentOffers: () => {},
  firstError: null,
  setFirstError: () => {},
  paymentMethodsOptions: [],
  isLoadingPaymentMethodsOptions: false,
  onMovePaymentOption: () => {},
  productFees: {},
});

export const ProductType = {
  subscription: {
    value: 'subscription',
    label: 'Assinatura',
  },
  unique: {
    value: 'unique',
    label: 'Pagamento único',
  },
};

const initialState = {
  paymentMethodsOptions: [],
};

const reducer = (state, action) => {
  if (action.type === 'SET_PAYMENT_OPTION') {
    return {
      ...state,
      paymentMethodsOptions: action.payload,
    };
  }

  if (action.type === 'ON_MOVE_PAYMENT_OPTION') {
    const updatedArray = [...state.paymentMethodsOptions];
    const { from, to } = action.payload;

    const movedItem = updatedArray[from];

    updatedArray.splice(from, 1);
    updatedArray.splice(to, 0, movedItem);

    return {
      ...state,
      paymentMethodsOptions: updatedArray,
    };
  }

  return state;
};

const EditProductProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const { id, short_id } = useParams();

  const { pathname } = useLocation();

  const isAffiliate = pathname.includes('affiliates');

  const [differentOffers, setDifferentOffers] = useState(false);
  const [firstError, setFirstError] = useState();

  const { data: product, isLoading: isFetchingProduct } = useQuery(
    ['product', { id, short_id }],
    () => (isAffiliate ? getAffiliateProductService({ short_id }) : getProductService({ id })),
    {
      enabled: !!id,
      staleTime: 5 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
      select(data) {
        return {
          ...data,
          offers: data.offers?.map((offer) => ({
            ...offer,
            offerId: offer.id,
          })),
          bumps: data.bumps?.sort((a, b) => a.position - b.position),
        };
      },
    }
  );

  const { data: categories, isFetching: isFetchingCategories } = useQuery(
    ['categories'],
    () => getProductCategoriesService(),
    {
      staleTime: 5 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
    }
  );

  const { mutateAsync: updatePixels, isLoading: isUpdatingFixels } = useMutation({
    mutationFn: async (tracking_pixels) => updateAffiliatePixelsService({ id, tracking_pixels }),
    onSuccess(data) {
      const productKey = ['product', { id, short_id }];

      const previous = queryClient.getQueryData(productKey);
      const newData = {
        ...previous,
        tracking_pixels: data,
      };

      queryClient.setQueryData(productKey, newData);
      enqueueSnackbar('Dados atualizados com sucesso', { variant: 'success' });
    },
    onError(error) {
      const fbErrorMessage = error?.response?.data?.facebook_pixels?.filter(
        (pixel) => Object.keys(pixel).length > 0
      );
      const errorMessage =
        fbErrorMessage[0]?.non_field_errors[0] ||
        error.message ||
        'Erro ao atualizar dados do produto';
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
  });

  const { mutateAsync: updateProduct, isLoading: isUpdatingProduct } = useMutation({
    mutationFn: async ({ image, paymentsOrder = [], ...data }) => {
      if (typeof image === 'string') {
        fetch(image)
          .then((response) => response.blob())
          .then(async (blob) => {
            const defaultImage = new File([blob], 'defaultProductCover.svg', {
              type: 'image/svg+xml',
            });

            await updateProductImageService({ id, image: defaultImage });
          });
      }
      if (image instanceof File) await updateProductImageService({ id, image });
      if (product?.image && !image) await deleteProductImageService({ id });

      const contentDeliveriesToRemove = [];

      const allData = {
        ...data,
        contentDeliveries: data.contentDeliveries.filter(
          (contentDelivery) => !contentDeliveriesToRemove.includes(contentDelivery)
        ),
        paymentsOrder: state.paymentMethodsOptions?.map(({ value }) => value),
        bumps: data?.bumps.map((bump, index) => ({
          ...bump,
          position: index,
        })),
        offers: data.offers
          ?.map((offer) => {
            if (!differentOffers)
              return {
                ...offer,
                delete: offer.default ? undefined : true,
              };

            return offer;
          })
          ?.map((offer) => {
            const { offerId, ...rest } = offer;

            return {
              ...rest,
              id: offerId || offer.id,
            };
          }),
      };

      const result = await updateProductService({ ...allData, id });

      return result;
    },
    onSuccess: async () => {
      enqueueSnackbar('Dados atualizados com sucesso', { variant: 'success' });
      queryClient.invalidateQueries('products');
    },
    onError: (error) => {
      queryClient.invalidateQueries('products');
      enqueueSnackbar(error.message ?? 'Erro ao atualizar dados do produto', { variant: 'error' });
    },
  });

  const { mutateAsync: deleteProduct, isLoading: isDeletingProduct } = useMutation(
    () => deleteProductService({ id }),
    {
      onSuccess: () => {
        enqueueSnackbar('Produto deletado com sucesso', { variant: 'success' });
        navigate('/dashboard/products');
        queryClient.invalidateQueries('products');
      },
    },
    {
      onError: () => {
        enqueueSnackbar('Erro ao deletar produto', { variant: 'error' });
        queryClient.invalidateQueries('products');
      },
    }
  );

  const { mutateAsync: cancelSubscriptions, isLoading: isCancelingSubscriptions } = useMutation(
    () => new Promise((resolve) => setTimeout(resolve, 1000)),
    {
      onSuccess: () => {
        enqueueSnackbar('Inscrições canceladas com sucesso', { variant: 'success' });
      },
    },
    {
      onError: () => {
        enqueueSnackbar('Erro ao cancelar inscrições', { variant: 'error' });
      },
    }
  );

  const hasFetchedOnce = useRef(false);

  const { isLoading: isLoadingPaymentMethodsOptions } = useQuery(
    ['paymentMethods', product?.id],
    getPaymentMethods,
    {
      enabled: !!product && !hasFetchedOnce.current,
      onSuccess: (values) => {
        hasFetchedOnce.current = true;
        function loadLabel(label) {
          return {
            pix: (
              <div className="flex flex-col items-center justify-center gap-2">
                <svg
                  fill="currentColor"
                  width="800px"
                  height="800px"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                  className="box-border h-8 w-8"
                >
                  <path d="M11.917 11.71a2.046 2.046 0 0 1-1.454-.602l-2.1-2.1a.4.4 0 0 0-.551 0l-2.108 2.108a2.044 2.044 0 0 1-1.454.602h-.414l2.66 2.66c.83.83 2.177.83 3.007 0l2.667-2.668h-.253zM4.25 4.282c.55 0 1.066.214 1.454.602l2.108 2.108a.39.39 0 0 0 .552 0l2.1-2.1a2.044 2.044 0 0 1 1.453-.602h.253L9.503 1.623a2.127 2.127 0 0 0-3.007 0l-2.66 2.66h.414z" />
                  <path d="m14.377 6.496-1.612-1.612a.307.307 0 0 1-.114.023h-.733c-.379 0-.75.154-1.017.422l-2.1 2.1a1.005 1.005 0 0 1-1.425 0L5.268 5.32a1.448 1.448 0 0 0-1.018-.422h-.9a.306.306 0 0 1-.109-.021L1.623 6.496c-.83.83-.83 2.177 0 3.008l1.618 1.618a.305.305 0 0 1 .108-.022h.901c.38 0 .75-.153 1.018-.421L7.375 8.57a1.034 1.034 0 0 1 1.426 0l2.1 2.1c.267.268.638.421 1.017.421h.733c.04 0 .079.01.114.024l1.612-1.612c.83-.83.83-2.178 0-3.008z" />
                </svg>{' '}
                {label}
              </div>
            ),
            boleto: (
              <div className="flex flex-col items-center justify-center gap-2">
                <svg
                  fill="currentColor"
                  width="800px"
                  height="800px"
                  viewBox="-4 0 32 32"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  className="box-border h-8 w-8"
                >
                  <path d="M0 25.281h0.781v-18.563h-0.781v18.563zM2.344 25.281h1.531v-18.563h-1.531v18.563zM5.406 25.281h1.563v-18.563h-1.563v18.563zM8.5 25.281h3.125v-18.563h-3.125v18.563zM13.156 25.281h2.344v-18.563h-2.344v18.563zM17.031 25.281h1.563v-18.563h-1.563v18.563zM20.125 25.281h0.781v-18.563h-0.781v18.563zM22.469 25.281h1.531v-18.563h-1.531v18.563z" />
                </svg>{' '}
                {label}
              </div>
            ),
            credit_card: (
              <div className="flex flex-col items-center justify-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                  className="box-border h-8 w-8"
                >
                  <path
                    fill="currentColor"
                    d="M2.5 4A1.5 1.5 0 001 5.5V6h18v-.5A1.5 1.5 0 0017.5 4h-15zM19 8.5H1v6A1.5 1.5 0 002.5 16h15a1.5 1.5 0 001.5-1.5v-6zM3 13.25a.75.75 0 01.75-.75h1.5a.75.75 0 010 1.5h-1.5a.75.75 0 01-.75-.75zm4.75-.75a.75.75 0 000 1.5h3.5a.75.75 0 000-1.5h-3.5z"
                    clipRule="evenodd"
                  />
                </svg>{' '}
                {label}
              </div>
            ),
            picpay: (
              <div className="flex flex-col items-center justify-center gap-2">
                <svg
                  width="25"
                  height="22"
                  viewBox="0 0 25 22"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.963 0.586975V8.12397H24.5V0.586975H16.963ZM18.219 1.84298H23.244V6.86798H18.219V1.84298ZM19.475 3.09898V5.61198H21.988V3.09898H19.475ZM4.27 4.35498V7.52997H7.646C9.788 7.52997 11.004 8.56997 11.004 10.469C11.004 12.416 9.788 13.48 7.646 13.48H4.269L4.27 7.52997H0.5V21.414H4.269V16.654H7.839C12.172 16.654 14.654 14.302 14.654 10.334C14.654 6.56297 12.172 4.35598 7.84 4.35598L4.27 4.35498Z"
                    fill="currentColor"
                  />
                </svg>{' '}
                {label}
              </div>
            ),
            applepay: (
              <div className="flex flex-col items-center justify-center gap-2">
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.5502 20.28C16.5702 21.23 15.5002 21.08 14.4702 20.63C13.3802 20.17 12.3802 20.15 11.2302 20.63C9.79016 21.25 9.03016 21.07 8.17016 20.28C3.29016 15.25 4.01016 7.59 9.55016 7.31C10.9002 7.38 11.8402 8.05 12.6302 8.11C13.8102 7.87 14.9402 7.18 16.2002 7.27C17.7102 7.39 18.8502 7.99 19.6002 9.07C16.4802 10.94 17.2202 15.05 20.0802 16.2C19.5102 17.7 18.7702 19.19 17.5402 20.29L17.5502 20.28ZM12.5302 7.25C12.3802 5.02 14.1902 3.18 16.2702 3C16.5602 5.58 13.9302 7.5 12.5302 7.25Z"
                    fill="currentColor"
                  />
                </svg>{' '}
                {label}
              </div>
            ),
            googlepay: (
              <div className="flex flex-col items-center justify-center gap-2">
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.9307 10.1977C22.0474 10.8689 22.1055 11.5489 22.1044 12.2302C22.1044 15.2726 21.0169 17.845 19.1245 19.5862H19.127C17.4721 21.1149 15.1971 21.9999 12.4997 21.9999C9.84761 21.9999 7.30415 20.9463 5.42884 19.071C3.55354 17.1957 2.5 14.6523 2.5 12.0002C2.5 9.34809 3.55354 6.80463 5.42884 4.92932C7.30415 3.05401 9.84761 2.00048 12.4997 2.00048C14.9818 1.97283 17.3786 2.90522 19.1895 4.6029L16.3346 7.45781C15.3024 6.47441 13.9252 5.93575 12.4997 5.95786C9.89103 5.95786 7.67484 7.7178 6.88487 10.0877C6.46675 11.3297 6.46675 12.6744 6.88487 13.9164H6.88862C7.68234 16.2825 9.89478 18.0425 12.5035 18.0425C13.8509 18.0425 15.0084 17.6975 15.9058 17.0875H15.9021C16.4233 16.7424 16.8692 16.2952 17.2127 15.7729C17.5562 15.2506 17.7903 14.6641 17.9008 14.0489H12.4997V10.199L21.9307 10.1977Z"
                    fill="currentColor"
                  />
                </svg>{' '}
                {label}
              </div>
            ),
          };
        }

        const { paymentsOrder } = product;

        const result = values
          ?.sort((a, b) => paymentsOrder.indexOf(a.type) - paymentsOrder.indexOf(b.type))
          .map(({ type, name }) => ({
            value: type,
            label: loadLabel(name)[type] || name,
            name,
          }));

        dispatch({
          type: 'SET_PAYMENT_OPTION',
          payload: result,
        });
      },
    }
  );

  const onMovePaymentOption = useCallback((removedIndex, addedIndex) => {
    dispatch({
      type: 'ON_MOVE_PAYMENT_OPTION',
      payload: {
        from: removedIndex,
        to: addedIndex,
      },
    });
  }, []);

  const { data: productFees, isLoading: isLoadingFees } = useQuery({
    queryKey: ['product-fees'],
    queryFn: getFee,
    select(data) {
      const {
        creditCardFixed,
        creditCardPercentage,
        ticketFixed,
        ticketPercentage,
        pixFixed,
        pixPercentage,
        picpayFixed,
        picpayPercentage,
        nupayFixed,
        nupayPercentage,
        googlepayFixed,
        googlepayPercentage,
        applepayFixed,
        applepayPercentage,
      } = data;
      return {
        card: {
          fixed: creditCardFixed || 0,
          percentage: creditCardPercentage || 0,
        },
        boleto: {
          fixed: ticketFixed || 0,
          percentage: ticketPercentage || 0,
        },
        pix: {
          fixed: pixFixed || 0,
          percentage: pixPercentage || 0,
        },
        picpay: {
          fixed: picpayFixed || 0,
          percentage: picpayPercentage || 0,
        },
        nupayFixed: {
          fixed: nupayFixed || 0,
          percentage: nupayPercentage || 0,
        },
        googlepay: {
          fixed: googlepayFixed || 0,
          percentage: googlepayPercentage || 0,
        },
        applepay: {
          fixed: applepayFixed || 0,
          percentage: applepayPercentage || 0,
        },
      };
    },
    staleTime: 5 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
  });

  const value = useMemo(
    () => ({
      product,
      isFetchingProduct,
      updateProduct,
      categories,
      isFetchingCategories,
      isUpdatingProduct,
      deleteProduct,
      isDeletingProduct,
      cancelSubscriptions,
      isCancelingSubscriptions,
      updatePixels,
      isUpdatingFixels,
      differentOffers,
      setDifferentOffers,
      firstError,
      setFirstError,
      paymentMethodsOptions: state.paymentMethodsOptions,
      isLoadingPaymentMethodsOptions: isLoadingPaymentMethodsOptions || isLoadingFees,
      onMovePaymentOption,
      productFees,
    }),
    [
      product,
      isFetchingProduct,
      updateProduct,
      categories,
      isFetchingCategories,
      isUpdatingProduct,
      deleteProduct,
      isDeletingProduct,
      cancelSubscriptions,
      isCancelingSubscriptions,
      updatePixels,
      isUpdatingFixels,
      differentOffers,
      setDifferentOffers,
      firstError,
      setFirstError,
      isLoadingPaymentMethodsOptions,
      state,
      onMovePaymentOption,
      productFees,
      isLoadingFees,
    ]
  );

  return <EditProductContext.Provider value={value}>{children}</EditProductContext.Provider>;
};

EditProductProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default EditProductProvider;

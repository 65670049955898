import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { createContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import useTableFilter from '@/hooks/useTableFilter';
import {
  createProductService,
  getProductCategoriesService,
  getProductsService,
  getContentDeliveries,
  duplicateProductService,
} from '@/services/products';
import { CONTENT_DELIVERY_OPTIONS } from '@/consts/product-settings';

export const ProductContentDelivery = {
  cakto: {
    label: 'Área de membros Cakto',
    value: 'cakto',
  },
  external: {
    label: 'Área de membros Externa',
    value: 'external',
  },
  disabled: {
    label: 'Somente para receber pagamentos',
    value: 'disabled',
  },
  emailAccess: {
    label: 'Acesso por e-mail',
    value: 'emailAccess',
  },
};

export const ProductsContext = createContext({
  products: [],
  isFetchingProducts: false,
  categories: [],
  isFetchingCategories: false,
  createProduct: () => {},
  duplicateProduct: () => {},
  table: {},
  total: 0,
  status: 'active',
  search: '',
  setStatus: () => {},
  setSearch: () => {},
  contentDeliveriesOptions: [],
  isLoadingContentDeliveriesOptions: false,
});

const ProductsProvider = ({ children }) => {
  const table = useTableFilter({
    defaultCurrentPage: 1,
  });

  const [status, setStatus] = useState('active,blocked');

  const { data: categories, isFetching: isFetchingCategories } = useQuery(
    ['categories'],
    getProductCategoriesService,
    {
      initialData: [],
    }
  );

  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const { data: products, isFetching: isFetchingProducts } = useQuery(
    ['products', status, table.search, table.page, table.rowsPerPage],
    () =>
      getProductsService({
        search: table.search,
        status,
        page: table.page,
        limit: table.rowsPerPage,
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
    }
  );

  const { mutateAsync: createProduct } = useMutation({
    mutationFn: createProductService,
    onSuccess: ({ id }) => {
      queryClient.invalidateQueries('products');

      enqueueSnackbar('Produto criado com sucesso', { variant: 'success' });

      navigate(`/dashboard/products/${id}/edit?tab=general`);
    },
    onError: () => {
      enqueueSnackbar('Erro ao criar produto', { variant: 'error' });
    },
  });

  const { mutateAsync: duplicateProduct } = useMutation({
    mutationFn: duplicateProductService,
    onSuccess: () => {
      queryClient.invalidateQueries('products');

      enqueueSnackbar('Produto duplicado com sucesso', { variant: 'success' });

      // navigate(`/dashboard/products/${id}/edit?tab=general`);
    },
    onError: () => {
      enqueueSnackbar('Erro ao duplicar produto', { variant: 'error' });
    },
  });

  const { data: contentDeliveriesOptions, isLoading: isLoadingContentDeliveriesOptions } = useQuery(
    {
      queryKey: ['content-deliveries'],
      queryFn: getContentDeliveries,
      select(result) {
        return result?.map((item) => {
          const content = CONTENT_DELIVERY_OPTIONS.find(({ value }) => value === item.type);

          if (content)
            return {
              ...content,
              title: item?.name,
            };

          return {
            title: item?.name,
            description: '',
            value: item?.type,
            icon: (
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="40" height="40" rx="20" fill="#212B36" />
                <circle
                  cx="20"
                  cy="20"
                  r="8"
                  stroke="#36B37E"
                  strokeWidth="2"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14 26 Q20 30, 26 26"
                  stroke="#36B37E"
                  strokeWidth="2"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ),
          };
        });
      },
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
    }
  );

  const value = useMemo(
    () => ({
      products: products?.products || [],
      categories,
      isFetchingCategories,
      createProduct,
      duplicateProduct,
      status,
      setStatus,
      isFetchingProducts,
      table,
      count: products?.count || 0,
      contentDeliveriesOptions,
      isLoadingContentDeliveriesOptions,
    }),
    [
      products,
      categories,
      isFetchingCategories,
      createProduct,
      duplicateProduct,
      status,
      isFetchingProducts,
      table,
      contentDeliveriesOptions,
      isLoadingContentDeliveriesOptions,
    ]
  );

  return <ProductsContext.Provider value={value}>{children}</ProductsContext.Provider>;
};

ProductsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProductsProvider;

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import PropTypes from 'prop-types';

export function ConfirmLeave(props) {
  const {
    title = 'Confirmação',
    message = 'Você tem certeza que deseja sair sem salvar?',
    confirmLabel = 'Sim',
    cancelLabel = 'Não',
    confirmColor = 'primary',
    cancelColor = 'inherit',
    onConfirm = () => {},
    onCancel = () => {},
    isOpen = false,
  } = props;
  return (
    <Dialog open={isOpen} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onCancel();
          }}
          color={cancelColor}
          variant="outlined"
        >
          {cancelLabel}
        </Button>
        <Button
          onClick={() => {
            onConfirm();
          }}
          color={confirmColor}
          variant="contained"
        >
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmLeave.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  confirmColor: PropTypes.string,
  cancelColor: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  isOpen: PropTypes.bool,
};

import { useState } from 'react';
import { useTable } from '../components/table';
import { useDebounce } from './useDebounce';

const useTableFilter = (
  { defaultFilter, tableProps } = {
    defaultFilter: {},
    tableProps: {},
  }
) => {
  const [filter, setFilter] = useState(defaultFilter);

  const [showFilter, setShowFilter] = useState(false);

  const [dirtyFilters, setDirtyFilters] = useState(0);

  const [search, setSearch] = useState('');

  const table = useTable({
    defaultCurrentPage: 1,
    defaultRowsPerPage: 10,
    ...tableProps,
  });

  const debouncedSearch = useDebounce(search, 500);

  return {
    filter,
    setFilter,
    dirtyFilters,
    setDirtyFilters,
    search: debouncedSearch,
    setSearch,
    showFilter,
    setShowFilter,
    ...table,
  };
};

export default useTableFilter;

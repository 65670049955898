const useDirtyFieldsCount = ({ dirtyFields }) => {
  const calculate = () => {
    let count = 0;

    const traverse = (object) => {
      Object.values(object).forEach((value) => {
        if (value === true) {
          count += 1;
        }
        if (typeof value === 'object') {
          traverse(value);
        }
      });
    };

    traverse(dirtyFields);

    return count;
  };

  return {
    dirties: calculate(),
  };
};

export default useDirtyFieldsCount;

/* eslint-disable no-eval */
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
// @mui
import { Box } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// config
import { HEADER, NAV } from '../../config-global';
// components
import { useSettingsContext } from '../../components/settings';

// ----------------------------------------------------------------------

const SPACING = 8;

Main.propTypes = {
  sx: PropTypes.object,
  children: PropTypes.node,
};

export default function Main({ children, sx, ...other }) {
  const { themeLayout } = useSettingsContext();

  const isNavHorizontal = themeLayout === 'horizontal';

  const isNavMini = themeLayout === 'mini';

  const isDesktop = useResponsive('up', 'lg');

  const { pathname } = useLocation();

  const isDashboardHome = pathname === '/dashboard/home';

  if (isNavHorizontal) {
    return (
      <>
        <Helmet>
          <script type="text/javascript">
            {`
              window.$crisp = [];
              window.CRISP_WEBSITE_ID = "${process.env.REACT_APP_CRISP_WEBSITE_ID}";
              (function() {
                let d = document;
                let s = d.createElement("script");
                s.src = "https://client.crisp.chat/l.js";
                s.async = 1;
                let head = document.getElementsByTagName("head")[0];
                head.appendChild(s);

                function adjustCrispZIndex() {
                  let crispElements = document.querySelectorAll("#crisp-chatbox, .crisp-client");
                  crispElements.forEach(element => {
                    element.style.zIndex = '99';
                  });
                }

                let observer = new MutationObserver((mutations) => {
                  mutations.forEach((mutation) => {
                    if (mutation.addedNodes.length > 0) {
                      adjustCrispZIndex();
                    }
                  });
                });

                observer.observe(document.body, {
                  childList: true,
                  subtree: true
                });

                let chat = document.getElementById("crisp-chatbox");
                if (!${isDashboardHome}) {
                  chat.style.setProperty('display', 'none', 'important');
                } else {
                  chat.style.setProperty('display', '');
                }
              })();
            `}
          </script>
        </Helmet>

        <Box
          component="main"
          sx={{
            pt: `${HEADER.H_MOBILE + SPACING}px`,
            pb: `${HEADER.H_MOBILE + SPACING}px`,
            ...(isDesktop && {
              px: 2,
              pt: `${HEADER.H_DASHBOARD_DESKTOP + 80}px`,
              pb: `${HEADER.H_DASHBOARD_DESKTOP + SPACING}px`,
            }),
          }}
        >
          {children}
        </Box>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <script type="text/javascript">
          {`
              window.$crisp = [];
              window.CRISP_WEBSITE_ID = "${process.env.REACT_APP_CRISP_WEBSITE_ID}";
              (function() {
                let d = document;
                let s = d.createElement("script");
                s.src = "https://client.crisp.chat/l.js";
                s.async = 1;
                let head = document.getElementsByTagName("head")[0];
                head.appendChild(s);
               
                function adjustCrispZIndex() {
                  let crispElements = document.querySelectorAll("#crisp-chatbox, .crisp-client");
                  crispElements.forEach(element => {
                    element.style.zIndex = '99'; 
                  });
                }
              
                let observer = new MutationObserver((mutations) => {
                  mutations.forEach((mutation) => {
                    if (mutation.addedNodes.length > 0) {
                      adjustCrispZIndex();
                    }
                  });
                });

               
                observer.observe(document.body, {
                  childList: true,
                  subtree: true
                });

              
                let chat = document.getElementById("crisp-chatbox");
                if (!${isDashboardHome}) {
                  chat.style.setProperty('display', 'none', 'important');
                } else {
                  chat.style.setProperty('display', '');
                }
              })();
            `}
        </script>
      </Helmet>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: `${HEADER.H_MOBILE + SPACING}px`,
          ...(isDesktop && {
            px: 2,
            py: `${HEADER.H_DASHBOARD_DESKTOP + SPACING}px`,
            width: `calc(100% - ${NAV.W_DASHBOARD}px)`,
            ...(isNavMini && {
              width: `calc(100% - ${NAV.W_DASHBOARD_MINI}px)`,
            }),
          }),
          ...sx,
        }}
        {...other}
      >
        {children}
      </Box>
    </>
  );
}

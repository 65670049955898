import { AuthenticatedHttp, GuestHttp } from '../../http/axios';

export const updateUserService = async ({ picture, ...info }) =>
  AuthenticatedHttp.put('/user/', {
    ...info,
    cpf: info.cpf?.replace(/\D/g, ''),
    cep: info.cep?.replace(/\D/g, ''),
  })
    .then(({ data: { success } }) => success)
    .catch(() => {
      throw new Error('Erro ao atualizar usuário');
    });

export const updateAmbientService = async ({ viewPageAs }) =>
  AuthenticatedHttp.put('/user/', {
    viewPageAs,
  }).then(({ data: { success } }) => {
    if (!success) {
      throw new Error('Erro ao atualizar usuário');
    }
    return success;
  });

export const updatePictureService = async ({ file }) => {
  const data = new FormData();
  data.append('picture', file);
  return AuthenticatedHttp.put('/user/picture/', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then(({ data: success }) => success)
    .catch((error) => {
      throw new Error('Erro ao atualizar foto de perfil');
    });
};

export const updatePasswordService = async ({ oldPassword, newPassword }) =>
  AuthenticatedHttp.post('user/change-password/', {
    oldPassword,
    newPassword,
  }).then(({ data: { success } }) => {
    if (!success) {
      throw new Error('Erro ao atualizar senha');
    }
    return success;
  });

export const createPassword = async ({ password, token }) => {
  GuestHttp.interceptors.request.use((config) => {
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  await GuestHttp.post('/user/create-password/', { password });

  GuestHttp.interceptors.request.use((config) => {
    if (token) {
      config.headers.Authorization = '';
    }
    return config;
  });

  return password;
};

export const requestUserEmailToken = async () =>
  AuthenticatedHttp.post('/user/validation/email/request_token/');

export const requestUserWhatsappToken = async () =>
  AuthenticatedHttp.post('/user/validation/whatsapp/request_token/');

export const validateEmail = async (token) =>
  AuthenticatedHttp.post('/user/validation/email/validate/', { token });

export const validateWhatsapp = async (token) =>
  AuthenticatedHttp.post('/user/validation/whatsapp/validate/', { token });

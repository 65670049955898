import { Card, CardContent, Box, Typography, Fab } from '@mui/material';
import { Stack } from '@mui/system';
import { PlayArrow } from '@mui/icons-material';
import { useFormContext } from 'react-hook-form';
import Iconify from '../../../components/iconify';
import Label from '../../../components/label';

const ProductModuleCoverPreview = () => {
  const form = useFormContext();

  return (
    <Stack
      sx={{
        aspectRatio: '9 / 16',
        height: '450px',
        ...(form.watch('coverImage') && {
          backgroundImage: `url(${form.watch('coverImage').preview})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }),
      }}
      component={Card}
      bgcolor="background.default"
    >
      <Stack component={CardContent} justifyContent="center" alignItems="center" height={1}>
        <Label
          variant="filled"
          color="primary"
          sx={{
            position: 'absolute',
            top: 10,
            right: 10,
          }}
        >
          0 Aulas
        </Label>
        {!form.watch('coverImage') && (
          <Iconify
            icon="bi:image-fill"
            width={80}
            height={80}
            sx={{
              color: 'text.disabled',
              justifyContent: 'center',
            }}
          />
        )}
      </Stack>

      <Box
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        bgcolor="rgba(0, 0, 0, 0.7)"
        color="white"
        sx={{
          opacity: 0,
          transition: 'opacity 0.3s',
          '&:hover': {
            opacity: 1,
          },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 4,
        }}
      >
        <Typography variant="h6" component="div" fontWeight="bold" mb={2}>
          {form.watch('name')}
        </Typography>
        <Fab>
          <PlayArrow />
        </Fab>
      </Box>
    </Stack>
  );
};

ProductModuleCoverPreview.propTypes = {};

export default ProductModuleCoverPreview;

import {
  Box,
  Button,
  Dialog,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.css';
import PropTypes from 'prop-types';
import { createRef, useEffect, useMemo, useState } from 'react';
import { Cropper } from 'react-cropper';
import Iconify from '../../iconify';

export const ImageCropTypes = {
  custom: {
    id: 'custom',
    label: 'Personalizado',
    ratio: 0,
  },
  square: {
    id: 'square',
    label: 'Quadrado',
    ratio: 1,
  },
  circle: {
    id: 'circle',
    label: 'Círculo',
    ratio: 1,
  },
  portrait: {
    id: 'portrait',
    label: 'Retrato',
    ratio: 10 / 16,
  },
  landscape: {
    id: 'landscape',
    label: 'Paisagem',
    ratio: 16 / 10,
  },
};

const FileCropperDialog = ({ src, open, onClose, onCrop, lockAspectRatio, rounded, ...props }) => {
  const cropperRef = createRef();

  const handleCrop = () => {
    if (typeof cropperRef.current?.cropper === 'undefined') {
      return;
    }
    cropperRef.current?.cropper.getCroppedCanvas().toBlob((blob) => {
      const file = Object.assign(new File([blob], 'image.png', { type: 'image/png' }), {
        preview: URL.createObjectURL(blob),
      });
      onCrop(file);
      onClose();
    });
  };

  const enableAspectRatioPicker = !lockAspectRatio && !rounded;

  const [type, setType] = useState(ImageCropTypes.square.id);

  const aspectRatio = useMemo(() => {
    if (lockAspectRatio) {
      return lockAspectRatio;
    }
    if (rounded) {
      return ImageCropTypes.circle.ratio;
    }
    return ImageCropTypes[type].ratio;
  }, [lockAspectRatio, rounded, type]);

  useEffect(() => {
    if (typeof cropperRef.current?.cropper === 'undefined') {
      return;
    }
    cropperRef.current?.cropper.setAspectRatio(aspectRatio);
  }, [aspectRatio, cropperRef]);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <Stack
        p={2}
        gap={2}
        sx={{
          ...(rounded ||
            (type === ImageCropTypes.circle.id && {
              '& .cropper-crop-box, & .cropper-view-box': {
                borderRadius: '50%',
              },
            })),
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <ListItem>
            <ListItemIcon>
              <Iconify icon="eva:crop-fill" width={20} height={20} />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body2" fontWeight="thin">
                  Editar Imagem
                </Typography>
              }
              secondary={
                <Typography variant="caption" fontWeight="thin" color="text.secondary">
                  Recorte a imagem para o tamanho desejado
                </Typography>
              }
            />
          </ListItem>
          <IconButton size="small" onClick={onClose}>
            <Iconify icon="mdi:close" width={20} height={20} />
          </IconButton>
        </Stack>
        <Stack gap={2} position="relative">
          <Box
            width={1}
            sx={{
              aspectRatio: '1 / 1',
            }}
          >
            <Cropper
              src={src}
              style={{ width: '100%', height: '100%' }}
              guides={false}
              ref={cropperRef}
              aspectRatio={aspectRatio}
              initialAspectRatio={aspectRatio}
              crossOrigin="anonymous"
              {...props}
            />
          </Box>
          <Stack
            spacing={1}
            direction="row"
            justifyContent={enableAspectRatioPicker ? 'space-between' : 'flex-end'}
          >
            {enableAspectRatioPicker && (
              <Select value={type} onChange={(e) => setType(e.target.value)} size="small">
                {Object.values(ImageCropTypes).map((ratio) => (
                  <MenuItem key={ratio.id} value={ratio.id}>
                    {ratio.label}
                  </MenuItem>
                ))}
              </Select>
            )}
            <Stack spacing={1} direction="row">
              <Button variant="outlined" onClick={() => onClose()} color="inherit">
                Cancelar
              </Button>
              <Button variant="contained" onClick={handleCrop}>
                Salvar
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  );
};

FileCropperDialog.propTypes = {
  src: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onCrop: PropTypes.func,
  lockAspectRatio: PropTypes.number,
  rounded: PropTypes.bool,
};

export default FileCropperDialog;

import { useMutation, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { createContext, useMemo, useState } from 'react';
import useTableFilter from '../hooks/useTableFilter';
import { getProductsService } from '../services/products';
import { exportSuscriptionsService, getSuscriptionsService } from '../services/subscriptions';
import { OrderCoproductionType } from './OrdersContext';

export const SubscriptionStatus = {
  processing: {
    value: 'processing',
    label: 'Processando',
    color: 'warning',
  },
  authorized: {
    value: 'authorized',
    label: 'Autorizado',
    color: 'success',
  },
  paid: {
    value: 'paid',
    label: 'Pago',
    color: 'success',
  },
  refunded: {
    value: 'refunded',
    label: 'Reembolsado',
    color: 'error',
  },
  waiting_payment: {
    value: 'waiting_payment',
    label: 'Aguardando pagamento',
    color: 'warning',
  },
  refused: {
    value: 'refused',
    label: 'Recusado',
    color: 'error',
  },
  chargedback: {
    value: 'chargedback',
    label: 'Chargeback',
    color: 'error',
  },
  canceled: {
    value: 'canceled',
    label: 'Cancelado',
    color: 'error',
  },
  active: {
    value: 'active',
    label: 'Ativo',
    color: 'success',
  },
  in_protest: {
    value: 'in_protest',
    label: 'Em protesto',
    color: 'error',
  },
  partially_paid: {
    value: 'partially_paid',
    label: 'Parcialmente pago',
    color: 'warning',
  },
};

export const PERIODS = {
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  LAST_WEEK: 'last_week',
  LAST_MONTH: 'last_month',
  CUSTOM: 'custom',
  ALL_TIME: 'all_time',
};

export const SubscriptionContext = createContext({
  table: {},
  subscriptions: [],
  fetching: false,
  plans: [],
  fetchingPlans: false,
  products: [],
  fetchingProducts: false,
  count: 0,
  cancel: () => {},
  canceling: false,
  exportSubscription: () => {},
  exporting: false,
  tab: 'active',
  setTab: () => {},
  activeCount: 0,
  recurringAmount: 0,
});

export const defaultFilter = {
  period: PERIODS.ALL_TIME,
  startDate: null,
  endDate: null,
  coproductionType: OrderCoproductionType.all.value,
  products: [],
  plans: [],
  paymentMethods: [],
  status: ['active'],
};

const SubscriptionsProvider = ({ children }) => {
  const table = useTableFilter({
    defaultCurrentPage: 1,
    defaultFilter,
  });

  const [tab, setTab] = useState('active');

  const { enqueueSnackbar } = useSnackbar();

  const { data: products = [], isFetching: fetchingProducts } = useQuery({
    queryFn: () =>
      getProductsService({
        page: 1,
        limit: 1000,
      }),
    select: (data) =>
      data.products.map((product) => ({ value: product?.id, label: product?.name })),
    queryKey: ['products'],
    initialData: {
      products: [],
    },
  });

  const { data: plans = [], isFetching: fetchingPlans } = useQuery({
    queryFn: () => ({
      plans: [
        {
          id: '1',
          name: 'Plano 1',
        },
        {
          id: '2',
          name: 'Plano 2',
        },
      ],
    }),
    queryKey: ['plans'],
    select: (data) => data.plans.map((plan) => ({ value: plan.id, label: plan.name })),
    initialData: {
      plans: [],
    },
  });

  const {
    data: { results: subscriptions = [], count = 0, activeCount = 0, recurringAmount = 0 },
    isFetching: fetching,
  } = useQuery({
    queryFn: () =>
      getSuscriptionsService({ page: table.page, limit: table.limit, ...table.filter }),
    queryKey: ['orders', table.page, table.search, table.filter],
    initialData: {
      results: [],
      count: 0,
      activeCount: 0,
      recurringAmount: 0,
    },
  });

  const { mutateAsync: exportSubscription, isLoading: exporting } = useMutation({
    mutationFn: ({ extension }) =>
      exportSuscriptionsService({
        extension,
        page: table.page,
        limit: table.limit,
        ...table.filter,
      }),
    onSuccess: () => {
      enqueueSnackbar('Exportação realizada com sucesso', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('Erro ao realizar exportação', {
        variant: 'error',
      });
    },
  });

  const { mutateAsync: cancel, isLoading: canceling } = useMutation(
    ({ id }) =>
      () =>
        true,
    {
      onSuccess: () => {
        enqueueSnackbar('Cancelamento realizado com sucesso', {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('Erro ao realizar cancelamento', {
          variant: 'error',
        });
      },
    }
  );

  const value = useMemo(
    () => ({
      table,
      subscriptions,
      fetching,
      plans,
      fetchingPlans,
      products,
      fetchingProducts,
      count,
      cancel,
      canceling,
      exportSubscription,
      exporting,
      tab,
      setTab,
      activeCount,
      recurringAmount,
    }),
    [
      table,
      subscriptions,
      fetching,
      plans,
      fetchingPlans,
      products,
      fetchingProducts,
      count,
      cancel,
      canceling,
      exportSubscription,
      exporting,
      tab,
      activeCount,
      recurringAmount,
    ]
  );

  return <SubscriptionContext.Provider value={value}>{children}</SubscriptionContext.Provider>;
};

SubscriptionsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SubscriptionsProvider;

import { Alert, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { RHFUpload } from '../../../components/hook-form';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import '@uppy/file-input/dist/style.css';
import '@uppy/progress-bar/dist/style.css';
import '@uppy/status-bar/dist/style.css';

export default function ProductModulesHeaderForm() {
  const form = useFormContext();

  return (
    <>
      <RHFUpload
        name="coverImage"
        maxSize={10000000}
        onDrop={([file]) => {
          form.setValue(`coverImage`, Object.assign(file, { preview: URL.createObjectURL(file) }));
        }}
        onRemove={(_, i) => {
          form.setValue(`coverImage`, null);
        }}
        onCrop={(cropped, i) => {
          form.setValue(`coverImage`, cropped);
        }}
        imagesOnly
        helperText={
          <Typography variant="body2" color="text.secondary">
            Formatos aceitos: JPG ou PNG. Tamanho máximo: 10MB
          </Typography>
        }
        accept={{ 'image/jpeg': [], 'image/png': [] }}
        multiple={false}
      />
      <Alert severity="info" sx={{ mt: 1 }}>
        Tamanho recomendado: 1920x1080
      </Alert>
    </>
  );
}

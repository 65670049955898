import { useMutation, useQuery } from '@tanstack/react-query';
import { subMonths } from 'date-fns';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { createContext, useMemo, useState } from 'react';
import useTableFilter from '../hooks/useTableFilter';
import { exportReport, getProductReport } from '../services/reports';
import { fDate } from '../utils/formatTime';

export const ReportProductsContext = createContext({
  table: {},
  productsReport: [],
  countProducts: 0,
  fetchingProducts: false,
  dates: {},
  setDates: () => {},
  mutateExport: () => {},
  loadingExport: false,
});

const ReportProductsProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  const table = useTableFilter({
    defaultCurrentPage: 1,
  });
  const [currentDates, setDates] = useState({
    startDate: subMonths(new Date(), 1),
    endDate: new Date(),
  });

  const dates = {
    startDate: fDate(currentDates?.startDate || new Date(), 'dd-MM-yyyy'),
    endDate: fDate(currentDates?.endDate || new Date(), 'dd-MM-yyyy'),
  };

  const {
    data: { results: productsReport = [], count: countProducts },
    isLoading: fetchingProducts,
  } = useQuery({
    queryKey: ['reports/product', table.page, dates],
    queryFn: () =>
      getProductReport({
        page: table.page,
        dates,
      }),
    initialData: {
      results: [],
      count: 0,
    },
  });

  const { mutateAsync: mutateExport, isLoading: loadingExport } = useMutation({
    async mutationFn(extension) {
      await exportReport({
        report: 'product',
        extension,
        ...dates,
        type: 'producer',
      });
    },
    onSuccess() {
      enqueueSnackbar('Exportação realizada com sucesso', { variant: 'success' });
    },
  });

  const value = useMemo(
    () => ({
      table,
      productsReport,
      countProducts,
      fetchingProducts,
      dates: currentDates,
      setDates,
      mutateExport,
      loadingExport,
    }),
    [
      table,
      productsReport,
      countProducts,
      fetchingProducts,
      currentDates,
      setDates,
      mutateExport,
      loadingExport,
    ]
  );

  return <ReportProductsContext.Provider value={value}>{children}</ReportProductsContext.Provider>;
};

ReportProductsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ReportProductsProvider;

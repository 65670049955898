import { AuthenticatedHttp } from '../../http/axios';

export const createVideo = async ({ title }) =>
  AuthenticatedHttp.post(`/members/videos/`, {
    videoTitle: title,
  }).then((response) => response.data);

export const deleteVideo = async ({ id }) => AuthenticatedHttp.delete(`/members/videos/${id}/`);

export const getVideos = async ({ search, page }) =>
  AuthenticatedHttp.get(`/members/videos/`, {
    params: {
      search,
      limit: 5,
      page,
    },
  })
    .then(({ data }) => data)
    .catch(() => []);

export const getVideoById = async ({ id }) =>
  AuthenticatedHttp.get(`/members/videos/${id}/`).then((response) => response.data);

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Box, Divider, MenuItem, Stack, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
// routes
import { PATH_AUTH, PATH_DASHBOARD, PATH_STUDENTS } from '../../../routes/paths';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
// components
import { IconButtonAnimate } from '../../../components/animate';
import { CustomAvatar } from '../../../components/custom-avatar';
import MenuPopover from '../../../components/menu-popover';
import { useSnackbar } from '../../../components/snackbar';

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const { user, logout, updateAmbient } = useAuthContext();
  const isStudentPath = user?.viewPageAs === 'student';

  const OPTIONS = [
    {
      // eslint-disable-next-line no-useless-concat
      label: 'Página Inicial',
      linkTo: !isStudentPath ? PATH_DASHBOARD.root : PATH_STUDENTS.courses,
    },
    {
      label: 'Meu Perfil',
      linkTo: !isStudentPath ? PATH_DASHBOARD.user.profile : PATH_STUDENTS.profile,
    },
    {
      label: isStudentPath ? 'Mudar para produtor' : 'Mudar para painel do aluno',
      linkTo: isStudentPath ? PATH_DASHBOARD.root : PATH_STUDENTS.courses,
      onClick: async (option) => {
        await updateAmbient({
          viewPageAs: isStudentPath ? 'producer' : 'student',
        });

        handleClickItem(option.linkTo);
      },
    },
  ];

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const [openPopover, setOpenPopover] = useState(null);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleLogout = async () => {
    try {
      logout();
      navigate(PATH_AUTH.login, { replace: true });
      handleClosePopover();
      localStorage.removeItem('next');
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleClickItem = (path) => {
    handleClosePopover();
    navigate(path);
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <CustomAvatar src={user?.picture} alt={user?.firstName} name={user?.firstName} />
      </IconButtonAnimate>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 200, p: 0 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.firstName}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              onClick={() =>
                option.onClick ? option.onClick(option) : handleClickItem(option.linkTo)
              }
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}

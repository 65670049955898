import { useMutation, useQuery } from '@tanstack/react-query';
import { subMonths } from 'date-fns';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { createContext, useMemo, useState } from 'react';
import useTableFilter from '../hooks/useTableFilter';
import { exportReport, getAffiliateReport } from '../services/reports';
import { fDate } from '../utils/formatTime';

export const ReportAffiliateContext = createContext({
  table: {},
  affiliateReport: [],
  fetchingAffiliate: false,
  countAffiliate: 0,
});

const ReportAffiliateProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  const table = useTableFilter({
    defaultCurrentPage: 1,
  });
  const [currentDate, setDates] = useState({
    startDate: subMonths(new Date(), 1),
    endDate: new Date(),
  });

  const dates = {
    startDate: fDate(currentDate?.startDate || new Date(), 'dd-MM-yyyy'),
    endDate: fDate(currentDate?.endDate || new Date(), 'dd-MM-yyyy'),
  };

  const {
    data: { results: affiliateReport = [], count: countAffiliate },
    isLoading: fetchingAffiliate,
  } = useQuery({
    queryKey: ['reports/affiliate', table.page, dates],
    queryFn: () =>
      getAffiliateReport({
        page: table.page,
        dates,
      }),
    initialData: {
      results: [],
      count: 0,
    },
  });

  const { mutateAsync: mutateExport, isLoading: loadingExport } = useMutation({
    async mutationFn(extension) {
      await exportReport({
        report: 'affiliate',
        extension,
        ...dates,
      });
    },
    onSuccess() {
      enqueueSnackbar('Exportação realizada com sucesso', { variant: 'success' });
    },
  });

  const value = useMemo(
    () => ({
      affiliateReport,
      table,
      fetchingAffiliate,
      countAffiliate,
      dates: currentDate,
      setDates,
      mutateExport,
      loadingExport,
    }),
    [
      affiliateReport,
      table,
      fetchingAffiliate,
      countAffiliate,
      currentDate,
      setDates,
      mutateExport,
      loadingExport,
    ]
  );

  return (
    <ReportAffiliateContext.Provider value={value}>{children}</ReportAffiliateContext.Provider>
  );
};

ReportAffiliateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ReportAffiliateProvider;

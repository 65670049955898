import { AuthenticatedHttp } from '../../http/axios';
import { parsePaginationResponse } from '../../utils/api';

export const getCollaboratorsService = async ({ page, search, limit }) =>
  AuthenticatedHttp.get('/collaborators/', {
    params: {
      page,
      search,
      limit,
    },
  }).then(parsePaginationResponse);

export const createCollaboratorService = async ({ email, permissions }) =>
  AuthenticatedHttp.post('/collaborators/', {
    email,
    permissions,
  });

export const reinviteCollaboratorService = async (body) => {
  AuthenticatedHttp.post('/collaborators/', {
    email: body.user.email,
    permissions: body.permissions,
  });
}

export const updateCollaboratorService = async ({ id, ...data }) =>
  AuthenticatedHttp.put(`/collaborators/${id}/`, data);

export const acceptCollaboratorService = async ({ id }) =>
  AuthenticatedHttp.post(`/collaborators/${id}/accept/`);

export const rejectCollaboratorService = async ({ id }) =>
  AuthenticatedHttp.post(`/collaborators/${id}/reject/`);

export const deleteCollaboratorService = async ({ id }) =>
  AuthenticatedHttp.delete(`/collaborators/${id}/`);

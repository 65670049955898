import { Outlet } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
// hooks
// components
//
import Main from './Main';
import CoursesHeader from './header/CoursesHeader';

// ----------------------------------------------------------------------

export default function StudentLayout() {
  return (
    <>
      <CoursesHeader />

      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
        }}
      >
        <Main>
          <Outlet />
        </Main>
      </Box>
    </>
  );
}

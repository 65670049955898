import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { InputAdornment, TextField } from '@mui/material';
import { CurrencyInput } from 'react-currency-mask';

RHFCurrencyTextField.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.node,
};

export default function RHFCurrencyTextField({ name, helperText, ...other }) {
  const { control } = useFormContext();

  const inputRef = useRef();

  useEffect(() => {
    if (other?.focus) {
      inputRef.current.focus();
    }
  }, [other?.focus]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <CurrencyInput
          {...other}
          hideSymbol
          max={other.max}
          value={field.value || '0.00'}
          onChangeValue={(_, value) => {
            if (other?.onChange) {
              other.onChange(value);
            }
            field.onChange(value || '0.00');
          }}
          InputElement={
            <TextField
              error={!!error}
              inputRef={inputRef}
              helperText={error ? error?.message : helperText}
              InputProps={{
                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
              }}
            />
          }
        />
      )}
    />
  );
}

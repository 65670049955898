import PropTypes from 'prop-types';
import { subDays, startOfDay, isAfter } from 'date-fns';
// @mui
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { RHFSwitch, RHFTextField } from '../../../components/hook-form';
import RHFDatePicker from '../../../components/hook-form/RHFDatePicker';
import FormProvider from '../../../components/hook-form/FormProvider';
import Iconify from '../../../components/iconify';
// import Label from '../../../components/label';
import { ProductStudentsContext } from '../../../contexts/ProductStudentsContext';

ProductStudentForm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default function ProductStudentForm({ open, onClose }) {
  const { formValues, addProductStudentAccess, isLoadingAddProductStudentAccess } =
    useContext(ProductStudentsContext);

  const today = startOfDay(new Date());

  const schema = yup.object().shape({
    name: yup.string().required('Campo obrigatório'),
    email: yup
      .string()
      .required('Campo obrigatório')
      .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Email inválido'),
    access_time: yup
      .string()
      .when('lifetimeAccess', {
        is: false,
        then: yup.string().required('Campo obrigatório').test(
          'is-valid-date-or-lifetime',
          'A data não pode ser menor que hoje',
          (value) => (
            isAfter(startOfDay(new Date(value)), today)
            || startOfDay(new Date(value)).getTime() === today.getTime()
          )
        ),
        otherwise: yup.string(),
      })
  });

  const defaultValues = {
    name: formValues?.name || '',
    email: formValues?.email || '',
    access_time: formValues?.access_time || '',
    lifetimeAccess: formValues?.lifetimeAccess || true,
  };

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = (values) => {
    const { access_time, lifetimeAccess, ...rest } = values;

    let accessTimeDate;
    if (!lifetimeAccess) {
      accessTimeDate = subDays(startOfDay(new Date(access_time)), 1).toISOString();
    }

    addProductStudentAccess({ ...rest, access_time: accessTimeDate || 'lifetime' });
  };

  useEffect(() => {
    form.reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => {
        form.reset();
        onClose();
      }}
    >
      <FormProvider methods={form} onSubmit={form.handleSubmit(onSubmit)}>
        <Stack p={2} gap={2}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <ListItem>
              <ListItemIcon>
                <Iconify icon="ph:student-bold" width={20} height={20} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body2" fontWeight="thin">
                    Alunos
                  </Typography>
                }
                secondary={
                  <Typography variant="caption" fontWeight="thin" color="text.secondary">
                    Gerencie os alunos do seu produto
                  </Typography>
                }
              />
            </ListItem>
            <IconButton size="small" onClick={onClose}>
              <Iconify icon="mdi:close" width={20} height={20} />
            </IconButton>
          </Stack>

          <RHFTextField
            name="name"
            label="Nome do Aluno"
            placeholder="..."
            fullWidth
            size="small"
          />

          <RHFTextField name="email" label="E-mail" placeholder="..." fullWidth size="small" />

          <RHFSwitch name="lifetimeAccess" label="Acesso vitalício" />

          {!form.watch('lifetimeAccess') && (
            <RHFDatePicker
              name="access_time"
              label="Data de expiração do acesso"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ mt: 1 }}
            />
          )}

          {/* <RHFSelect name="class" label="Turma" size="small">
            {classes.map(({ id, name, default: _default }) => (
              <MenuItem key={id} value={id}>
                {name}
                {_default && (
                  <Label
                    variant="soft"
                    color="primary"
                    sx={{
                      ml: 1,
                    }}
                  >
                    Padrão
                  </Label>
                )}
              </MenuItem>
            ))}
          </RHFSelect> */}

          <Stack spacing={1} direction="row" justifyContent="end">
            <Button variant="outlined" onClick={() => onClose()} color="inherit">
              Cancelar
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isLoadingAddProductStudentAccess}
            >
              Salvar
            </LoadingButton>
          </Stack>
        </Stack>
      </FormProvider>
    </Dialog>
  );
}

import { Button, Card, CircularProgress, MenuItem, Stack, Typography } from '@mui/material';
import { arrayMoveImmutable } from 'array-move';
import { useContext, useState } from 'react';
import { Container, Draggable } from 'react-smooth-dnd';
import Iconify from '../../../components/iconify';
import MenuPopover from '../../../components/menu-popover/MenuPopover';

import ConfirmDialog from '../../../components/confirm-dialog';
import Label from '../../../components/label/Label';
import { BatchVideoUploadContext, UploadScopes } from '../../../contexts/BatchVideoUploadContext';
import { ProductLessonsContext } from '../../../contexts/ProductLessonsContext';
import { ProductModulesContext } from '../../../contexts/ProductModulesContext';
import { FormScopes } from '../../../utils/form';
import ProductLessonForm from '../product-lessons/ProductLessonForm';
import ProductVideosUploader from '../product-videos/ProductVideosUploader';
import ProductVideosLibrary from '../product-videos/ProductVideosLibrary';
import ProductCourseHeaderEditor from './ProductCourseHeaderEditor';
import ProductModuleCard from './ProductModuleCard';
import ProductModulesForm from './ProductModulesForm';

const ProductCoursePanel = () => {
  const {
    modules,
    fetching,
    formValues,
    setFormValues,
    create: createModule,
    update: updateModule,
    scope,
    setScope,
    expanded,
    setExpanded,
    reorder: reorderModules,
    syncing: syncingModules,
    isError,
  } = useContext(ProductModulesContext);

  const {
    scope: lessonsScope,
    setScope: setLessonsScope,
    selected,
    publish,
    unpublish,
    remove,
    syncing: syncingLessons,
  } = useContext(ProductLessonsContext);

  const [anchor, setAnchor] = useState(null);

  const [addAnchor, setAddAnchor] = useState(null);

  const { scope: uploadScope, setScope: setUploadScope } = useContext(BatchVideoUploadContext);

  return (
    <>
      <ProductVideosUploader
        open={uploadScope === UploadScopes.PICKING}
        onClose={() => setUploadScope(UploadScopes.IDLE)}
      />

      <ProductVideosLibrary
        open={scope === FormScopes.OPEN_LIB}
        onClose={() => setScope(FormScopes.INDEX)}
      />

      <ProductModulesForm
        open={[FormScopes.CREATE, FormScopes.EDIT].includes(scope)}
        onClose={() => {
          setScope(FormScopes.INDEX);
        }}
        onSubmit={(values) => {
          if (scope === FormScopes.CREATE) {
            return createModule(values);
          }
          return updateModule({
            id: formValues.id,
            ...values,
          });
        }}
      />
      <ProductLessonForm
        scope={lessonsScope}
        open={[FormScopes.CREATE, FormScopes.EDIT].includes(lessonsScope)}
        onClose={() => setLessonsScope(FormScopes.INDEX)}
      />
      <ConfirmDialog
        action={
          <Button
            variant="contained"
            color="error"
            startIcon={<Iconify icon="eva:trash-2-outline" />}
            onClick={() => remove({ ids: selected })}
          >
            Remover
          </Button>
        }
        content={`Tem certeza que deseja remover os ${selected.length} conteúdos selecionados?`}
        title="Remover conteúdos"
        open={lessonsScope === FormScopes.DELETE_MULTIPLE}
        onClose={() => setLessonsScope(FormScopes.INDEX)}
      />

      <Stack gap={2}>
        <Stack position="relative" component={Card} bgcolor="transparent" overflow="hidden">
          <ProductCourseHeaderEditor />
          <Stack gap={2} p={2} pt={0}>
            <Stack
              direction={{
                xs: 'column',
                sm: 'row',
              }}
              justifyContent="flex-end"
              alignItems="center"
              gap={1}
            >
              {(syncingModules || syncingLessons) && (
                <Label variant="soft" startIcon={<Iconify icon="eos-icons:loading" />}>
                  Sincronizando...
                </Label>
              )}
              <MenuPopover open={anchor} onClose={() => setAnchor(null)}>
                <MenuItem
                  onClick={() => {
                    publish({
                      ids: selected,
                    });
                    setAnchor(null);
                  }}
                >
                  <Iconify icon="eva:eye-outline" width={20} height={20} />
                  Publicar
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    unpublish({
                      ids: selected,
                    });
                    setAnchor(null);
                  }}
                >
                  <Iconify icon="eva:eye-off-outline" width={20} height={20} />
                  Despublicar
                </MenuItem>
              </MenuPopover>
              <MenuPopover open={addAnchor} onClose={() => setAddAnchor(null)}>
                <MenuItem
                  onClick={() => {
                    setFormValues({});
                    setScope(FormScopes.CREATE);
                    setAddAnchor(null);
                  }}
                >
                  <Iconify icon="codicon:file-submodule" width={20} height={20} />
                  Adicionar Módulo
                </MenuItem>
              </MenuPopover>

              <Button
                variant="outlined"
                color="inherit"
                startIcon={<Iconify icon="majesticons:library-line" />}
                onClick={() => setScope(FormScopes.OPEN_LIB)}
              >
                Biblioteca de vídeos
              </Button>

              {/* <Button
                variant="contained"
                color="inherit"
                startIcon={<Iconify icon="eva:upload-outline" />}
                onClick={() => setUploadScope(UploadScopes.PICKING)}
              >
                Upload de vídeos
              </Button> */}

              {expanded?.length > 0 ? (
                <Button
                  variant="outlined"
                  color="inherit"
                  startIcon={<Iconify icon="eva:collapse-fill" />}
                  onClick={() => setExpanded([])}
                >
                  Minimizar
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="inherit"
                  startIcon={<Iconify icon="eva:expand-outline" />}
                  disabled={isError}
                  onClick={() => {
                    setExpanded(() => {
                      const ids = [];
                      modules.forEach((module) => {
                        ids.push(module.id);
                      });
                      return ids;
                    });
                  }}
                >
                  Expandir
                </Button>
              )}

              <Button
                variant="contained"
                startIcon={<Iconify icon="eva:plus-outline" />}
                color="primary"
                onClick={(e) => setAddAnchor(e.currentTarget)}
                disabled={isError}
              >
                Adicionar
              </Button>
            </Stack>
            {modules?.length > 0 && (
              <Container
                dragHandleSelector=".drag-handle-modules"
                lockAxis="y"
                onDrop={({ removedIndex, addedIndex }) => {
                  const ordered = arrayMoveImmutable(modules, removedIndex, addedIndex).map(
                    (l, index) => ({
                      ...l,
                      order: index + 1,
                    })
                  );
                  reorderModules({
                    ordered,
                  });
                }}
              >
                {modules.map((module) => (
                  <Draggable key={module.id}>
                    <ProductModuleCard
                      key={module.id}
                      module={module}
                      onLessonsReorder={({ ordered }) => {
                        reorderModules({
                          ordered: modules.map((m) => {
                            if (m.id === module.id) {
                              m.lessons = ordered;
                            }
                            return m;
                          }),
                        });
                      }}
                    />
                  </Draggable>
                ))}
              </Container>
            )}
            {modules?.length === 0 && (
              <Stack direction="row" justifyContent="center" width={1} my={2}>
                {isError && (
                  <Typography variant="h5" color="error">
                    Erro ao carregar dados, tente novamente mais tarde.
                  </Typography>
                )}
                {!isError && fetching && <CircularProgress />}
                {!isError && !fetching && (
                  <Typography variant="caption" color="text.secondary">
                    Você ainda não adicionou nenhum módulo.
                  </Typography>
                )}
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default ProductCoursePanel;

import { useState, useEffect } from 'react';
import PropTyped from 'prop-types';
import { TableCell, IconButton, Button } from '@mui/material';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useFormContext } from 'react-hook-form';
import ConfirmDialog from '../confirm-dialog/ConfirmDialog';
import SearchableTable from '../table/SearchableTable';
import Label from '../label';
import Iconify from '../iconify';
import { useAuthContext } from '../../auth/useAuthContext';
import { getVideos, deleteVideo } from '../../services/members/videos';
import useTableFilter from '../../hooks/useTableFilter';
import { fDate } from '../../utils/formatTime';

export default function VideLibrary({ isToAdd }) {
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [videoToRemove, setVideoToRemove] = useState({});

  const { user } = useAuthContext();

  const form = useFormContext();

  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const libraryTable = useTableFilter({
    tableProps: {
      defaultRowsPerPage: 5,
    },
  });

  useEffect(() => {
    if (libraryTable.search?.length) {
      queryClient.invalidateQueries(['videos']);
    }
  }, [queryClient, libraryTable.search]);

  useEffect(() => {
    queryClient.invalidateQueries(['videos']);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: videos, isFetching: fetchingVideos } = useQuery({
    queryKey: ['videos', { search: libraryTable.search, page: libraryTable.page }],
    queryFn: async () => getVideos({ search: libraryTable.search, page: libraryTable.page }),
    enabled: !!user.id,
    staleTime: 5 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
    keepPreviousData: true,
  });

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (id) => deleteVideo({ id }),
    async onMutate(data) {
      const previousData = queryClient.getQueryData([
        'videos',
        { search: libraryTable.search, page: libraryTable.page },
      ]);

      const newData = previousData;
      newData.results = (previousData.results || []).filter(({ id }) => id !== data);

      queryClient.setQueryData(
        ['videos', { search: libraryTable.search, page: libraryTable.page }],
        newData
      );

      return { previousData };
    },
    onSuccess() {
      enqueueSnackbar('Vídeo removido com sucesso!', {
        variant: 'success',
      });
      setIsRemoveModalOpen(false);
    },
    onError() {
      enqueueSnackbar('Não foi possível remover o vídeo!', {
        variant: 'error',
      });

      queryClient.invalidateQueries([
        'videos',
        { search: libraryTable.search, page: libraryTable.page },
      ]);
    },
  });

  const openRemoveDialog = (video) => {
    setVideoToRemove(video);
    setIsRemoveModalOpen(true);
  };

  const confirmRemove = () => {
    mutateAsync(videoToRemove.id);
  };

  const VideosStatus = {
    queued: {
      label: 'Aguardando codificação',
      color: 'warning',
    },
    processing: {
      label: 'Processando',
      color: 'warning',
    },
    encoding: {
      label: 'Codificação',
      color: 'warning',
    },
    finished: {
      label: 'Finalizado',
      color: 'success',
    },
    videoPlayable: {
      label: 'Vídeo reprodutível',
      color: 'success',
    },
    encodingFailed: {
      label: 'Falha na codificação',
      color: 'error',
    },
    uploadStarted: {
      label: 'Upload iniciado',
      color: 'warning',
    },
    uploadFinished: {
      label: 'Upload finalizado',
      color: 'success',
    },
    created: {
      label: 'Pendente',
      color: 'warning',
    },
    uploadFailed: {
      label: 'Falha no upload',
      color: 'error',
    },
    captionsGenerated: {
      label: 'Legendas automáticas geradas',
      color: 'default',
    },
    titleOrDescriptionGenerated: {
      label: 'Geração automática do título ou descrição finalizada',
      color: 'default',
    },
  };

  return (
    <>
      <SearchableTable
        fetching={fetchingVideos}
        labels={[
          {
            id: 'adds',
            label: 'Selecionar',
          },
          {
            id: 'title',
            label: 'Nome',
          },
          {
            id: 'status',
            label: 'Status',
          },
          {
            id: 'uploadedAt',
            label: 'Data de Upload',
          },
          {
            id: 'remove',
            label: 'Remover',
          },
        ].filter(({ id }) => {
          if (!isToAdd) return id !== 'adds';

          return true;
        })}
        rows={videos?.results}
        count={videos?.count}
        table={libraryTable}
        renderRow={(row) => (
          <>
            {isToAdd && (
              <TableCell>
                <IconButton
                  onClick={() => {
                    form.setValue('video', row.id, { shouldValidate: true, shouldDirty: true });
                  }}
                  aria-label="select"
                >
                  <Iconify width={24} icon="eva:checkmark-fill" />
                </IconButton>
              </TableCell>
            )}
            <TableCell>{row.title}</TableCell>
            <TableCell>
              <Label variant="soft" color={VideosStatus[row.status]?.color || 'default'}>
                {VideosStatus[row.status]?.label}
              </Label>
            </TableCell>
            <TableCell>{row.uploadedAt ? fDate(row.uploadedAt, 'dd/MM/yyyy') : '-'}</TableCell>
            <TableCell>
              <IconButton
                onClick={() => {
                  openRemoveDialog(row);
                }}
                aria-label="delete"
              >
                <Iconify width={24} icon="eva:trash-2-outline" />
              </IconButton>
            </TableCell>
          </>
        )}
      />

      <ConfirmDialog
        open={isRemoveModalOpen}
        onClose={() => setIsRemoveModalOpen(false)}
        action={
          <Button
            variant="contained"
            color="error"
            startIcon={<Iconify icon="eva:trash-2-outline" />}
            onClick={confirmRemove}
            disabled={isLoading}
          >
            Remover
          </Button>
        }
        title="Remover conteúdo"
        content={`Tem certeza que deseja remover o vídeo "${videoToRemove?.title}"?`}
      />
    </>
  );
}

VideLibrary.propTypes = {
  isToAdd: PropTyped.bool,
};

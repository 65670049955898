import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { createContext, useMemo, useState } from 'react';
import useTableFilter from '../hooks/useTableFilter';
import { FormScopes } from '../utils/form';

export const ProductClassesContext = createContext({
  table: {},
  classes: [],
  fetching: false,
  count: 0,
  scope: FormScopes.INDEX,
  setScope: () => {},
  formValues: {},
  setFormValues: () => {},
});

const ProductClassesProvider = ({ children }) => {
  const table = useTableFilter({
    defaultCurrentPage: 1,
  });

  const [scope, setScope] = useState(FormScopes.INDEX);

  const [formValues, setFormValues] = useState({});

  const {
    data: { results: classes, count },
    isFetching: fetching,
  } = useQuery({
    queryKey: ['classes', table.page, table.limit, table.search, table.filter],
    queryFn: () =>
      new Promise((resolve) =>
        setTimeout(
          () =>
            resolve({
              results: [
                {
                  name: 'Turma A',
                  default: true,
                  students: 10,
                },
                {
                  name: 'Turma B',
                  default: false,
                  students: 5,
                },
              ],
              count: 2,
            }),
          1000
        )
      ),
    initialData: {
      results: [],
      count: 0,
    },
  });

  const value = useMemo(
    () => ({
      table,
      classes,
      count,
      fetching,
      scope,
      setScope,
      formValues,
      setFormValues,
    }),
    [table, classes, count, fetching, scope, formValues]
  );

  return <ProductClassesContext.Provider value={value}>{children}</ProductClassesContext.Provider>;
};

ProductClassesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProductClassesProvider;

import { format } from 'date-fns';
import { AuthenticatedHttp } from '../../http/axios';
import { parsePaginationResponse } from '../../utils/api';
import { downloadFile } from '../../utils/files';

export const getProductReport = async ({ page, search, dates }) =>
  AuthenticatedHttp.get('/reports/product/', {
    params: {
      page,
      search,
      ...dates,
    },
  }).then(parsePaginationResponse);

export const getAffiliateReport = async ({ page, search, dates }) =>
  AuthenticatedHttp.get('/reports/affiliate/', {
    params: {
      page,
      search,
      ...dates,
    },
  }).then(parsePaginationResponse);

export const getCoProductionReport = async ({ page, search, type, dates }) =>
  AuthenticatedHttp.get('/reports/coproduction/', {
    params: {
      page,
      search,
      type,
      ...dates,
    },
  }).then(parsePaginationResponse);

export const getBalanceReport = async ({ page, status, dates }) =>
  AuthenticatedHttp.get('/reports/pending_balance/', {
    params: {
      page,
      status,
      ...dates,
    },
  }).then(({ data }) => data);

export const getAbandonedSalesReport = async ({
  page,
  search,
  startDate,
  endDate,
  products = [],
}) =>
  AuthenticatedHttp.get('/reports/checkout_abandonment/', {
    params: {
      page,
      search,
      ...(products.length && { products }),
      ...(startDate && { startDate: format(startDate, 'dd-MM-yyyy') }),
      ...(endDate && { endDate: format(endDate, 'dd-MM-yyyy') }),
    },
  }).then(parsePaginationResponse);

export const exportAbandonedSalesReport = async ({
  page,
  search,
  startDate,
  endDate,
  products = [],
  extension,
}) => {
  AuthenticatedHttp.defaults.responseType = 'blob';
  AuthenticatedHttp.get(`/reports/checkout_abandonment/export/${extension}/`, {
    params: {
      page,
      search,
      ...(products.length && { products }),
      ...(startDate && { startDate: format(startDate, 'dd-MM-yyyy') }),
      ...(endDate && { endDate: format(endDate, 'dd-MM-yyyy') }),
    },
  }).then((response) => {
    downloadFile({
      url: window.URL.createObjectURL(new Blob([response.data])),
      filename: `checkout_abandonment.${extension}`,
    });
  });
};

export const exportReport = async ({ startDate, endDate, type, report, extension, ...rest }) => {
  AuthenticatedHttp.defaults.responseType = 'blob';
  AuthenticatedHttp.get(`/reports/${report}/export/${extension}/`, {
    params: {
      startDate,
      endDate,
      type,
      ...rest,
    },
  }).then((response) => {
    downloadFile({
      url: window.URL.createObjectURL(new Blob([response.data])),
      filename: `${report}_report${type ? `_${type}` : ''}.${extension}`,
    });

    AuthenticatedHttp.defaults.responseType = 'json';
  });
};

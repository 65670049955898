import PropTypes from 'prop-types';
// components
import { yupResolver } from '@hookform/resolvers/yup';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
// hooks
import { RHFTextField } from '../../../components/hook-form';
import TableFilterDrawer from '../../../components/table/TableFilterDrawer';
import {
  ProductStudentDefaultFilter,
  ProductStudentsContext,
} from '../../../contexts/ProductStudentsContext';

ProductStudentsFilter.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default function ProductStudentsFilter({ open, onClose }) {
  const { table } = useContext(ProductStudentsContext);

  const validationSchema = yup.object().shape({
    lastEmail: yup
      .string()
      .transform((value) => (value === '' ? undefined : value))
      .nullable()
      .test('is-email', 'Email inválido', (value) => {
        if (value && value !== '') {
          return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);
        }
        return true;
      }),
  });
  const { formState, ...form } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: ProductStudentDefaultFilter,
  });

  const { dirtyFields } = formState;

  return (
    <TableFilterDrawer
      open={open}
      onClose={onClose}
      form={form}
      defaultFilter={ProductStudentDefaultFilter}
      onSubmit={(data) => {
        onClose();
        table.setDirtyFilters(Object.keys(dirtyFields).length);
        table.setFilter(data);
      }}
    >
      <RHFTextField
        label="Último Email"
        placeholder="Digite a partir de qual email será pesquisado"
        name="lastEmail"
      />
    </TableFilterDrawer>
  );
}

import {
  CheckoutBuilderComponentTypes,
  CheckoutBuilderDeviceTypes,
} from '../../sections/@checkout-builder/constants';
import { updateCheckoutService } from '../product-checkout';
import { uploadImage } from '../product-gallery';

export const saveCheckout = async ({ id, product: { id: productID }, ...checkout }) => {
  const checkImageAndUpload = async (value) => {
    if (value instanceof File) {
      return uploadImage({ file: value, productID });
    }
    return value;
  };

  const components = {
    [CheckoutBuilderComponentTypes.image.id]: ['image'],
    [CheckoutBuilderComponentTypes.header.id]: ['backgroundImage', 'productImage'],
    [CheckoutBuilderComponentTypes.testimonial.id]: ['avatar'],
  };

  const extras = {
    [CheckoutBuilderComponentTypes.exitPopup.id]: ['image'],
  };

  const config = {
    mobile: checkout.config.mobile,
    desktop: checkout.config.desktop,
  };

  const checkImages = async (device) => {
    config[device].settings = {
      ...config[device].settings,
      background: {
        ...config[device].settings.background,
        image: await checkImageAndUpload(
          config[device].settings.background.image?.file || config[device].settings.background.image
        ),
      },
    };

    config[device].rows = await Promise.all(
      config[device].rows.map(async (row) => ({
        ...row,
        columns: await Promise.all(
          row.columns.map(async (column) => ({
            ...column,
            type: column?.type || 'column',
            components: await Promise.all(
              column.components.map(async (component) => {
                if (component.type in components) {
                  await Promise.all(
                    Object.keys(component.attributes).map(async (attribute) => {
                      component.attributes[attribute] = await checkImageAndUpload(
                        component.attributes[attribute]
                      );
                    })
                  );
                }
                return component;
              })
            ),
          }))
        ),
      }))
    );

    await Promise.all(
      Object.entries(extras).map(async ([type, attributes]) => {
        await Promise.all(
          attributes.map(async (attribute) => {
            config[device].extra[type].attributes[attribute] = await checkImageAndUpload(
              config[device].extra[type].attributes[attribute]
            );
          })
        );
      })
    );
  };

  await Promise.all(Object.keys(CheckoutBuilderDeviceTypes).map(checkImages));

  return updateCheckoutService({
    id,
    config,
    default: checkout.default,
    name: checkout.name,
    offers: checkout.offers?.map((offer) => offer.id),
  }).then(() => config);
};

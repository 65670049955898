import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { createContext, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import useTableFilter from '../hooks/useTableFilter';
import {
  createLesson,
  deleteLesson,
  getLessonById,
  updateLesson,
  downloadLessonFile,
  getLesson,
} from '../services/members/lessons';
import { FormScopes } from '../utils/form';

export const ProductLessonsContext = createContext({
  table: {},
  create: () => {},
  creating: false,
  reorder: () => {},
  reordering: false,
  update: () => {},
  updating: false,
  remove: () => {},
  removing: false,
  publish: () => {},
  publishing: false,
  unpublish: () => {},
  unpublishing: false,
  scope: FormScopes.INDEX,
  setScope: () => {},
  selected: [],
  setSelected: () => {},
  formValues: {},
  setFormValues: () => {},
  syncing: false,
  loadLesson: () => {},
  lesson: {},
  isLoadingLesson: false,
  uppyStates: {},
  setUppyStates: () => {},
  download: () => {},
  isLoadingDownload: false,
  currentLesson: {},
  isLoadingCurrentLesson: false,
  lessonsValues: '',
  setLessonsValues: () => {},
});

export const ProductModuleLessonTypes = {
  lesson: {
    id: 'lesson',
    name: 'Conteúdo',
    icon: 'mdi:video',
  },
  quiz: {
    id: 'quiz',
    name: 'Quiz',
    icon: 'healthicons:i-exam-multiple-choice',
  },
};

export const ProductModuleLessonVideoSources = {
  upload: {
    id: 'upload',
    label: 'Novo Vídeo',
  },
  library: {
    id: 'library',
    label: 'Biblioteca',
  },
  class: {
    id: 'class',
    label: 'Reutilizar de outra aula',
  },
  youtube: {
    id: 'youtube',
    label: 'Youtube',
  },
};

export const ProductModuleLessonVideoProvider = {
  device: {
    id: 'device',
    name: 'Dispositivo',
    icon: 'akar-icons:smartphone-fill',
  },
  youtube: {
    id: 'youtube',
    name: 'Youtube',
    icon: 'akar-icons:youtube-fill',
  },
  dropbox: {
    id: 'dropbox',
    name: 'Dropbox',
    icon: 'akar-icons:dropbox-fill',
  },
  googleDrive: {
    id: 'googleDrive',
    name: 'Google Drive',
    icon: 'akar-icons:google-fill',
  },
};

export const ProductLessonstatus = {
  published: {
    id: 'published',
    name: 'Publicado',
    color: 'success',
  },
  unpublished: {
    id: 'unpublished',
    name: 'Não Publicado',
    color: 'error',
  },
};

export const ProductLessonDefaultValues = {
  type: ProductModuleLessonTypes.lesson.id,
  title: '',
  video: '',
  videoSource: ProductModuleLessonVideoSources.upload.id,
  description: '',
  thumbnail: null,
  allowMultipleAnswers: false,
  randomizeQuestions: false,
  randomizeAnswers: false,
  releaseType: 'immediate',
  releaseDays: '0',
  releaseDate: new Date(),
  limitDuration: false,
  limitDurationDays: '0',
  startIn: 'immediately',
  startDays: '',
  startDate: '',
  questions: [
    {
      title: '',
      required: false,
      answers: [
        {
          title: '',
          correct: false,
        },
        {
          title: '',
          correct: false,
        },
      ],
    },
    {
      title: '',
      required: false,
      answers: [
        {
          title: '',
          correct: false,
        },
      ],
    },
  ],
};

const ProductLessonsProvider = ({ children }) => {
  const table = useTableFilter({
    defaultCurrentPage: 1,
  });

  const [scope, setScope] = useState(FormScopes.INDEX);

  const [selected, setSelected] = useState([]);

  const [uppyStates, setUppyStates] = useState({});

  const [formValues, setFormValues] = useState(ProductLessonDefaultValues);

  const [lessonsValues, setLessonsValues] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const { id: courseId } = useParams();

  const { mutateAsync: create, isLoading: creating } = useMutation({
    mutationFn: ({ moduleId, youtubeUrl, pandaVideoUrl, ...data }) => {
      if (youtubeUrl) data.video = youtubeUrl;

      if (pandaVideoUrl) data.video = pandaVideoUrl;

      return createLesson({ courseId, moduleId, ...data });
    },
    onSuccess: (newLesson) => {
      enqueueSnackbar('Conteúdo adicionado com sucesso!', {
        variant: 'success',
      });

      setScope(FormScopes.INDEX);

      queryClient.setQueryData(['course', { courseId }], (previous) => {
        const module = previous.modules.find(({ id }) => id === newLesson.moduleId);

        if (module) {
          module.lessons.push(newLesson);
        }

        return previous;
      });
    },
    onError: () => {
      enqueueSnackbar('Não foi possível adicionar o Conteúdo!', {
        variant: 'error',
      });
    },
  });

  const { mutateAsync: update, isLoading: updating } = useMutation({
    async onMutate(data) {
      await queryClient.cancelQueries(['lesson', { id: formValues.id }]);

      const previous = queryClient.getQueryData(['lesson', { id: formValues.id }]);
      const newData = {
        ...previous,
        ...data,
      };

      setLessonsValues((prev) =>
        prev.map((item) => {
          if (item.id === data.id) {
            return {
              ...item,
              title: data.title,
            };
          }

          return item;
        })
      );

      queryClient.setQueryData(['lesson', { id: formValues.id }], newData);

      return { previous };
    },
    mutationFn: ({ id: lessonId, ...values }) => {
      const { filesKeys, files, youtubeUrl, pandaVideoUrl, ...data } = values;
      const allFiles = files?.reduce((acc, item) => {
        const fileKey = filesKeys?.find((key) => key.includes(item));
        if (fileKey) {
          acc.push(fileKey);
        } else {
          acc.push(item);
        }
        return acc;
      }, []);

      const allData = {
        ...data,
        files: allFiles,
        thumbnail: values?.thumbnail || '',
      };

      if (youtubeUrl) allData.video = youtubeUrl;

      if (pandaVideoUrl) allData.video = pandaVideoUrl;

      return updateLesson({ courseId, lessonId, ...allData });
    },
    onSuccess: () => {
      setScope(FormScopes.INDEX);
      enqueueSnackbar('Conteúdo atualizado com sucesso!', {
        variant: 'success',
      });
    },
    onError: async () => {
      enqueueSnackbar('Não foi possível atualizar o Conteúdo!', {
        variant: 'error',
      });

      await queryClient.invalidateQueries(['lesson', { id: formValues.id }]);
    },
  });

  const changeStatusOfSelectedLessons = useCallback(
    ({ ids, status }) => {
      queryClient.setQueryData(['sections'], (old) => {
        old.sections.forEach((s) => {
          s.modules.forEach((m) => {
            m.lessons.forEach((l) => {
              if (ids.includes(l.id)) {
                l.status = status;
              }
            });
          });
        });

        old.modules.forEach((m) => {
          m.lessons.forEach((l) => {
            if (ids.includes(l.id)) {
              l.status = status;
            }
          });
        });

        return old;
      });
      setSelected([]);
    },
    [queryClient]
  );

  const { mutateAsync: publish, isLoading: publishing } = useMutation({
    mutationFn: (data) =>
      new Promise((resolve) =>
        setTimeout(() => {
          console.log(data);
          resolve();
        }, 1000)
      ),
    onMutate: async ({ ids }) => {
      const previous = queryClient.getQueryData(['sections']);

      changeStatusOfSelectedLessons({
        ids,
        status: ProductLessonstatus.published.id,
      });

      return { previous };
    },
    onSuccess: () => {
      setScope(FormScopes.INDEX);
    },
    onError: (err, data, context) => {
      enqueueSnackbar('Não foi possível publicar o Conteúdo!', {
        variant: 'error',
      });

      queryClient.setQueryData(['sections'], context.previous);
    },
  });

  const { mutateAsync: unpublish, isLoading: unpublishing } = useMutation({
    mutationFn: (data) =>
      new Promise((resolve) =>
        setTimeout(() => {
          console.log(data);
          resolve();
        }, 1000)
      ),
    onMutate: async ({ ids }) => {
      const previous = queryClient.getQueryData(['sections']);

      changeStatusOfSelectedLessons({
        ids,
        status: ProductLessonstatus.unpublished.id,
      });

      return { previous };
    },
    onSuccess: () => {
      setScope(FormScopes.INDEX);
    },
    onError: (err, data, context) => {
      enqueueSnackbar('Não foi possível despublicar o Conteúdo!', {
        variant: 'error',
      });

      queryClient.setQueryData(['sections'], context.previous);
    },
  });

  const { mutateAsync: remove, isLoading: removing } = useMutation({
    mutationFn: ({ ids: [lessonId] }) => deleteLesson({ courseId, lessonId }),
    onMutate: async ({ ids }) => {
      const previous = queryClient.getQueryData(['course', { courseId }]);

      const filter = (m) => m.lessons.filter(({ id }) => !ids.includes(id));

      queryClient.setQueryData(['course', { courseId }], () => ({
        ...previous,
        modules: previous.modules.map((m) => ({
          ...m,
          lessons: filter(m),
        })),
      }));

      setScope(FormScopes.INDEX);

      return { previous };
    },
    onSuccess: () => {
      enqueueSnackbar('Conteúdo removido com sucesso!', {
        variant: 'success',
      });

      setSelected([]);

      setScope(FormScopes.INDEX);
    },
    onError: (err, data, context) => {
      enqueueSnackbar('Não foi possível remover o Conteúdo!', {
        variant: 'error',
      });

      queryClient.setQueryData(['course', { courseId }], context.previous);
    },
  });

  const { mutateAsync: reorder, isLoading: reordering } = useMutation({
    mutationFn: (data) =>
      new Promise((resolve) =>
        setTimeout(() => {
          console.log(data);
          resolve();
        }, 1000)
      ),
    onMutate: async ({ id, lessons }) => {
      const previous = queryClient.getQueryData(['sections']);

      queryClient.setQueryData(['sections'], () => ({
        sections: previous.sections.map((s) => ({
          ...s,
          modules: s.modules.map((m) => ({
            ...m,
            lessons: m.id === id ? lessons : m.lessons,
          })),
        })),
        modules: previous.modules.map((m) => ({
          ...m,
          lessons: m.id === id ? lessons : m.lessons,
        })),
      }));

      return { previous };
    },
    onError: (error, data, context) => {
      enqueueSnackbar('Não foi possível reordenar os Conteúdos!', {
        variant: 'error',
      });

      queryClient.setQueryData(['sections'], context.previous);
    },
  });

  const {
    data: lesson,
    isLoading: isLoadingLesson,
    mutateAsync: loadLesson,
  } = useMutation({
    mutationFn: (lessonId) =>
      getLessonById({
        courseId,
        lessonId,
      }),
  });

  const { mutateAsync: download, isLoading: isLoadingDownload } = useMutation({
    mutationFn: async (fileKey) => downloadLessonFile(fileKey),
  });

  const { data: currentLesson, isFetching: isLoadingCurrentLesson } = useQuery({
    queryKey: ['lesson', { id: formValues.id }],
    queryFn: () => (formValues?.id ? getLesson(formValues.id) : {}),
  });

  const syncing = creating || updating || removing || reordering || publishing || unpublishing;

  const value = useMemo(
    () => ({
      table,
      create,
      creating,
      update,
      updating,
      remove,
      removing,
      reorder,
      reordering,
      scope,
      setScope,
      selected,
      setSelected,
      publish,
      publishing,
      unpublish,
      unpublishing,
      formValues,
      setFormValues,
      syncing,
      loadLesson,
      lesson,
      isLoadingLesson,
      uppyStates,
      setUppyStates,
      download,
      isLoadingDownload,
      currentLesson,
      isLoadingCurrentLesson,
      lessonsValues,
      setLessonsValues,
    }),
    [
      table,
      create,
      creating,
      update,
      updating,
      remove,
      removing,
      reorder,
      reordering,
      scope,
      selected,
      publish,
      publishing,
      unpublish,
      unpublishing,
      formValues,
      syncing,
      loadLesson,
      lesson,
      isLoadingLesson,
      uppyStates,
      setUppyStates,
      download,
      isLoadingDownload,
      currentLesson,
      isLoadingCurrentLesson,
      lessonsValues,
      setLessonsValues,
    ]
  );

  return <ProductLessonsContext.Provider value={value}>{children}</ProductLessonsContext.Provider>;
};

ProductLessonsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProductLessonsProvider;

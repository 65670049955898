import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
// @mui
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
// assets
import { UploadIllustration } from '../../assets/illustrations';
//
import Iconify from '../iconify';
//
import Scrollbar from '../scrollbar/Scrollbar';
import RejectionFiles from './errors/RejectionFiles';
import MultiFilePreview from './preview/MultiFilePreview';
import SingleFilePreview from './preview/SingleFilePreview';

// ----------------------------------------------------------------------

const StyledDropZone = styled('div')(({ theme }) => ({
  outline: 'none',
  cursor: 'pointer',
  overflow: 'hidden',
  position: 'relative',
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${alpha(theme.palette.grey[500], 0.32)}`,
  '&:hover': {
    opacity: 0.72,
  },
}));

// ----------------------------------------------------------------------

Upload.propTypes = {
  sx: PropTypes.object,
  error: PropTypes.bool,
  files: PropTypes.array,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  onDelete: PropTypes.func,
  onRemove: PropTypes.func,
  onUpload: PropTypes.func,
  onDownload: PropTypes.func,
  thumbnail: PropTypes.bool,
  helperText: PropTypes.node,
  onRemoveAll: PropTypes.func,
  onCrop: PropTypes.func,
  aspectRatio: PropTypes.string,
  imagesOnly: PropTypes.bool,
  lockCropAspectRatio: PropTypes.number,
  fileTooLargeErrorMessage: PropTypes.string,
  fileInvalidTypeErrorMessage: PropTypes.string,
};

export default function Upload({
  disabled,
  multiple = false,
  error,
  helperText,
  //
  file,
  onDelete,
  //
  files,
  thumbnail,
  onUpload,
  onRemove,
  onRemoveAll,
  onDownload,
  aspectRatio = '1 / 1',
  lockCropAspectRatio = null,
  onCrop = () => {},
  imagesOnly = false,
  sx,
  fileTooLargeErrorMessage,
  fileInvalidTypeErrorMessage,
  ...other
}) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections: rawFileRejections,
  } = useDropzone({
    multiple,
    disabled,
    ...other,
  });

  const mapErrors = {
    'file-too-large': fileTooLargeErrorMessage || 'Arquivo maior que o permitido de 10mb',
    'file-invalid-type':
      fileInvalidTypeErrorMessage ||
      'Arquivo inválido. Somente arquivos em formato de imagem e pdf são válidos',
  };

  const fileRejections = rawFileRejections.map((rejected) => ({
    ...rejected,
    errors: rejected.errors.map((fileError) => ({
      ...fileError,
      message: mapErrors[fileError.code] || fileError.message,
    })),
  }));

  const hasFile = (file instanceof File || file?.preview) && !multiple;

  const hasFiles = files && multiple && files.length > 0;

  const isError = isDragReject || !!error;

  return (
    <Box sx={{ width: 1, position: 'relative', ...sx }}>
      {(!hasFile || !!multiple) && (
        <StyledDropZone
          {...getRootProps()}
          sx={{
            ...(isDragActive && {
              opacity: 0.72,
            }),
            ...(isError && {
              color: 'error.main',
              bgcolor: 'error.lighter',
              borderColor: 'error.light',
            }),
            ...(disabled && {
              opacity: 0.48,
              pointerEvents: 'none',
            }),
            ...(hasFile && {
              padding: '12% 0',
            }),
          }}
        >
          <input {...getInputProps()} />

          <Placeholder
            sx={{
              ...(hasFile && {
                opacity: 0,
              }),
            }}
          />
        </StyledDropZone>
      )}

      {hasFile && (
        <SingleFilePreview
          file={file}
          onRemove={onRemove}
          onCrop={onCrop}
          lockCropAspectRatio={lockCropAspectRatio}
          sx={{
            width: 200,
            aspectRatio,
          }}
        />
      )}

      {helperText && helperText}

      <RejectionFiles fileRejections={fileRejections} />

      {hasFile && onDelete && (
        <IconButton
          size="small"
          onClick={onDelete}
          sx={{
            top: 16,
            right: 16,
            zIndex: 9,
            position: 'absolute',
            color: (theme) => alpha(theme.palette.common.white, 0.8),
            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            '&:hover': {
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
            },
          }}
        >
          <Iconify icon="eva:close-fill" width={18} />
        </IconButton>
      )}

      {hasFiles && (
        <>
          <Box sx={{ my: 3 }}>
            {imagesOnly ? (
              <Scrollbar autoHide={false}>
                <Stack direction="row" gap={1} pb={2}>
                  {files.map((f, i) => (
                    <SingleFilePreview
                      key={i}
                      file={f}
                      onCrop={(cropped) => onCrop(cropped, i)}
                      onRemove={(removed) => onRemove(removed, i)}
                      sx={{
                        minWidth: 120,
                        aspectRatio,
                      }}
                      lockCropAspectRatio={lockCropAspectRatio}
                    />
                  ))}
                </Stack>
              </Scrollbar>
            ) : (
              <MultiFilePreview
                files={files}
                thumbnail={thumbnail}
                onRemove={onRemove}
                onDownload={onDownload}
              />
            )}
          </Box>

          <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
            {onRemoveAll && (
              <Button color="inherit" variant="outlined" size="small" onClick={onRemoveAll}>
                Remove all
              </Button>
            )}

            {onUpload && (
              <Button size="small" variant="contained" onClick={onUpload}>
                Upload files
              </Button>
            )}
          </Stack>
        </>
      )}
    </Box>
  );
}

// ----------------------------------------------------------------------

Placeholder.propTypes = {
  sx: PropTypes.object,
};

function Placeholder({ sx, ...other }) {
  return (
    <Stack
      spacing={5}
      alignItems="center"
      justifyContent="center"
      direction={{
        xs: 'column',
        md: 'row',
      }}
      sx={{
        width: 1,
        textAlign: {
          xs: 'center',
          md: 'left',
        },
        ...sx,
      }}
      {...other}
    >
      <UploadIllustration sx={{ width: 120 }} />

      <div>
        <Typography gutterBottom variant="h6">
          Arraste ou selecione o arquivo
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Solte os arquivos aqui ou clique para
          <Typography
            variant="body2"
            component="span"
            sx={{
              mx: 0.5,
              color: 'primary.main',
              textDecoration: 'underline',
            }}
          >
            buscar
          </Typography>
          em seu computador
        </Typography>
      </div>
    </Stack>
  );
}

import PropTypes from 'prop-types';

import { Card, CardContent, IconButton, Skeleton, Typography } from '@mui/material';

import { Stack } from '@mui/system';
import { useState } from 'react';
import Iconify from '../iconify';
import ConditionSkeleton from '../skeleton/ConditionSkeleton';

DashboardWidget.propTypes = {
  sx: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  footer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  fetching: PropTypes.bool,
};

export default function DashboardWidget({
  title,
  subtitle,
  footer,
  fetching = false,
  sx,
  ...other
}) {
  const [showValue, setShowValue] = useState(true);

  return (
    <Card sx={{ ...sx }} {...other}>
      <CardContent>
        <Stack gap={0.5}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {title}
          </Typography>
          <Stack
            width={1}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap={1}
          >
            {fetching ? (
              <Skeleton variant="text" animation="wave">
                <Typography variant="h3" sx={{ filter: showValue ? 'none' : 'blur(12px)' }}>
                  {subtitle}
                </Typography>
              </Skeleton>
            ) : (
              <Typography variant="h3" sx={{ filter: showValue ? 'none' : 'blur(12px)' }}>
                {subtitle}
              </Typography>
            )}
            <ConditionSkeleton loading={fetching}>
              <IconButton onClick={() => setShowValue(!showValue)} edge="end">
                <Iconify width={30} icon={showValue ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
              </IconButton>
            </ConditionSkeleton>
          </Stack>
          <Typography variant="caption" sx={{ color: 'text.disabled', height: 18 }}>
            {footer}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}

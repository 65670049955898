import { Container, Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { NotificationsTable } from '../../sections/@dashboard/notifications';

export default function StudentNotifications() {
  return (
    <>
      <Container maxWidth="xl">
        <Helmet>
          <title>Notificações</title>
        </Helmet>
      </Container>

      <Grid container justifyContent="center" pb={5}>
        <Grid item xs={11} md={6}>
          <NotificationsTable
            tableLabels={[
              { id: 'title', label: 'Título' },
              { id: 'description', label: 'Descrição' },
              { id: 'date', label: 'Data' },
              { id: 'read', label: 'Lida' },
            ]}
          />
        </Grid>
      </Grid>
    </>
  );
}

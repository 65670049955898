import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { getNotifications, readAllNotifications } from '../services/notifications';

const useNotifications = ({ page = 1, search = '' }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { data: { results: notifications = [], count } = {}, isFetching: fetching } = useQuery({
    queryKey: ['notifications', { page, search }],
    queryFn: () => getNotifications({ page, search }),
    initialData: {
      results: [],
      count: 0,
    },
  });

  const queryClient = useQueryClient();

  const { mutateAsync: readAll, isLoading: reading } = useMutation(readAllNotifications, {
    onSuccess: () => {
      queryClient.invalidateQueries('notifications');
    },
    onError: () => {
      enqueueSnackbar('Erro ao ler notificações', { variant: 'error' });
    },
  });

  const unread = notifications?.filter((item) => item.read === false).length;

  return { notifications, fetching, readAll, reading, unread, count };
};

export default useNotifications;

import PropTypes from 'prop-types';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Button, Card, Tab, TableCell, Typography, Alert, Skeleton, MenuItem } from '@mui/material';
import { Box, Stack } from '@mui/system';
import Uppy from '@uppy/core';
import Portuguese from '@uppy/locales/lib/pt_BR';
import { Dashboard } from '@uppy/react';
import Tus from '@uppy/tus';
import getVideoId from 'get-video-id';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { RHFTextField, RHFUpload, RHFSelect } from '../../../components/hook-form';
import RHFDateTimePicker from '../../../components/hook-form/RHFDateTimePicker';
import Iconify from '../../../components/iconify/Iconify';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import '@uppy/file-input/dist/style.css';
import '@uppy/progress-bar/dist/style.css';
import '@uppy/status-bar/dist/style.css';
import SearchableTable from '../../../components/table/SearchableTable';
import VideoLibrary from '../../../components/video-library';
import useTableFilter from '../../../hooks/useTableFilter';
import { createVideo, getVideoById } from '../../../services/members/videos';
import uuidv4 from '../../../utils/uuidv4';
import { downloadFile } from '../../../utils/files';

import { ProductLessonsContext } from '../../../contexts/ProductLessonsContext';

const startInOptions = [
  {
    label: 'Imediatamente',
    value: 'immediately',
  },
  {
    label: 'Em alguns dias após a compra',
    value: 'after_days',
  },
  {
    label: 'Em uma data específica',
    value: 'fix_date',
  },
];

const ProductLessonDetailsForm = ({ setIsUploadingVideo }) => {
  const { uppyStates, setUppyStates, download, formValues } = useContext(ProductLessonsContext);
  const form = useFormContext();

  const tabName = form.watch('id');

  const [isLoadingVideo, setIsLoadingVideo] = useState(false);

  useLayoutEffect(() => {
    if (!uppyStates[tabName]) {
      const uppy = new Uppy({
        id: 'uppy-lesson',
        autoProceed: false,
        debug: true,
        locale: Portuguese,
        restrictions: {
          maxFileSize: 5368709120,
          maxNumberOfFiles: 1,
          minNumberOfFiles: 1,
          allowedFileTypes: ['video/*'],
        },
      }).use(Tus, {
        endpoint: 'https://video.bunnycdn.com/tusupload',
        allowedMetaFields: [
          'AuthorizationSignature',
          'AuthorizationExpire',
          'LibraryId',
          'VideoId',
        ],
        onBeforeRequest: async (req, file) => {
          if (!file.meta.AuthorizationSignature) {
            throw new Error('Ocorreu um erro ao enviar o vídeo');
          }

          req.setHeader('AuthorizationSignature', file.meta.AuthorizationSignature);

          req.setHeader('AuthorizationExpire', file.meta.AuthorizationExpire);

          req.setHeader('LibraryId', file.meta.LibraryId);

          req.setHeader('VideoId', file.meta.VideoId);

          return req;
        },
      });

      setUppyStates((prevStates) => ({
        ...prevStates,
        [tabName]: uppy,
      }));
    }
  }, [tabName, setUppyStates, uppyStates]);

  const lessonsTable = useTableFilter({});

  const { enqueueSnackbar } = useSnackbar();

  const handleDropFile = useCallback(
    ([file]) => {
      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (newFile) {
        form.setValue('thumbnail', newFile, { shouldValidate: true, shouldDirty: true });
      }
    },
    [form]
  );

  const onFileAdded = useCallback(
    async (file) => {
      setIsLoadingVideo(true);
      createVideo({
        title: file.name,
      })
        .then(({ authorizationSignature, authorizationExpire, libraryId, id }) => {
          uppyStates[tabName].setFileMeta(file.id, {
            AuthorizationSignature: authorizationSignature,
            AuthorizationExpire: authorizationExpire,
            LibraryId: libraryId,
            VideoId: id,
          });
        })
        .catch(() => {
          enqueueSnackbar('Falha ao criar vídeo', { variant: 'error' });

          uppyStates[tabName].removeFile(file.id);

          throw new Error('Falha ao criar vídeo');
        })
        .finally(() => setIsLoadingVideo(false));
    },
    [enqueueSnackbar, tabName, uppyStates]
  );

  useEffect(() => {
    uppyStates[tabName]?.on('file-added', onFileAdded);

    uppyStates[tabName]?.on('upload-started', () => setIsUploadingVideo(true));

    uppyStates[tabName]?.on('upload-success', async (file) => {
      getVideoById({ id: file.meta.VideoId }).then((video) => {
        // Todo: get right video id
        form.setValue('video', video.id, { shouldValidate: true, shouldDirty: true });
        setIsUploadingVideo(false);
        uppyStates[tabName]?.cancelAll();
      });
    });

    return () => {
      uppyStates[tabName]?.off('file-added', null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uppyStates]);

  const ResetButton = useMemo(
    () => (
      <Button
        size="small"
        color="error"
        variant="text"
        startIcon={<Iconify icon="eva:trash-2-fill" />}
        sx={{ mt: 1 }}
        onClick={() => {
          form.setValue('video', '', { shouldValidate: true, shouldDirty: true });
          form.setValue('youtubeUrl', '', { shouldValidate: true, shouldDirty: true });
          form.setValue('youtube', false, { shouldValidate: true, shouldDirty: true });
          form.setValue('panda', false, { shouldValidate: true, shouldDirty: true });
          form.setValue('thumbnail', null, { shouldValidate: true, shouldDirty: true });
          form.setValue('youtubeUrl', '', { shouldValidate: true, shouldDirty: true });
          form.setValue('pandaVideoUrl', '', { shouldValidate: true, shouldDirty: true });
        }}
      >
        Remover
      </Button>
    ),
    [form]
  );

  const youtubeVideoId = useMemo(() => getVideoId(form.watch('youtubeUrl') || '').id || '', [form]);

  const handleChangeYoutubeVideo = useCallback(
    (url) => {
      const { id } = getVideoId(url);
      form.setValue('thumbnail', {
        preview: `https://img.youtube.com/vi/${id}/maxresdefault.jpg`,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [form]
  );

  const handleChangePandaVideo = useCallback(
    (e) => {
      const { value } = e.target;
      const regexPlayer = /player-(.*?)\.tv/;
      const regexVideo = /[?&]v=([^&]+)/;
      const pandaRegexMatchesPlayer = value.toString().match(regexPlayer) || ['', '', ''];
      const pandaRegexMatchesVideo = value.toString().match(regexVideo) || ['', '', ''];

      const pandaPlayerId = pandaRegexMatchesPlayer[1];
      const pandaVideoId = pandaRegexMatchesVideo[1];

      const preview = `https://b-${pandaPlayerId}.tv.pandavideo.com.br/${pandaVideoId}/thumbnail.jpg`;

      form.clearErrors('pandaVideoUrl');

      form.setValue('pandaVideoUrl', value);

      const isCaktoPreview =
        form.watch('thumbnail')?.preview.includes('cakto') ||
        form.watch('thumbnail')?.preview.includes('blob');

      if (!isCaktoPreview) {
        if (!pandaPlayerId?.length || !pandaVideoId?.length) {
          form.setValue('thumbnail', null, { shouldValidate: true, shouldDirty: true });
        } else {
          form.setValue('thumbnail', {
            preview,
          });
        }
      }
    },
    [form]
  );

  const video = form.watch('video');

  useEffect(() => {
    const isYoutubeVideo = video?.includes('youtube');
    form.setValue('youtube', isYoutubeVideo);
    if (isYoutubeVideo) {
      form.setValue('youtubeUrl', video);
    }

    const isPandaVideo = video?.includes('pandavideo');

    if (isPandaVideo) {
      form.setValue('panda', isPandaVideo);
      form.setValue('pandaVideoUrl', video);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [video]);

  const SuccessBanner = useMemo(
    () => (
      <Stack
        height={300}
        alignItems="center"
        justifyContent="center"
        gap={1}
        py={2}
        px={{
          xs: 2,
          sm: 3,
          md: 10,
        }}
      >
        <Iconify icon="eva:checkmark-circle-2-fill" width={100} height={100} color="success.main" />
        <Typography variant="h6" fontWeight="bold" color="success.main">
          Upload concluído
        </Typography>
        <Typography variant="body2" fontWeight="thin" color="text.secondary" textAlign="center">
          Pode levar até 30 minutos para o seu vídeo ficar disponível para visualização. Você deve
          salvar essa aula para o upload ser processado.
        </Typography>
        {ResetButton}
      </Stack>
    ),
    [ResetButton]
  );

  const [tab, setTab] = useState('upload');

  const files = useFieldArray({
    control: form.control,
    name: 'files',
  });

  const handleAddFiles = useCallback(
    (acceptedFiles) => {
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          uuid: uuidv4(),
          preview: URL.createObjectURL(file),
        })
      );
      files.append(newFiles);
    },
    [files]
  );

  const handleRemoveFile = useCallback(
    (_, index) => {
      files.remove(index);
    },
    [files]
  );

  const handleDownloadFile = useCallback(
    (file) => {
      if (file instanceof File) {
        downloadFile({
          url: window.URL.createObjectURL(new Blob([file])),
          filename: file.name,
        });
      } else {
        const { filesKeys } = formValues;
        const fileToDownload = filesKeys.find((key) => key.includes(file));
        download(fileToDownload);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formValues]
  );

  return (
    <Stack gap={2}>
      <RHFTextField
        name="title"
        label="Título"
        size="small"
        placeholder="Título"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <RHFSelect name="startIn" label="Liberação da aula" size="small">
        {startInOptions.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </RHFSelect>

      {form.watch('startIn') === 'after_days' && (
        <RHFTextField
          name="startDays"
          type="number"
          label="Quantidade de dias"
          placeholder="Digite a quantidade de dias para a liberação da aula"
        />
      )}

      {form.watch('startIn') === 'fix_date' && (
        <RHFDateTimePicker name="startDate" label="Data da liberação" />
      )}
      <Stack gap={1}>
        <Typography variant="caption" color="text.secondary">
          Vídeo
        </Typography>
        <Card variant="outlined">
          {!form.watch('youtube') && !form.watch('panda') && !form.watch('video') && (
            <TabContext value={tab || 'upload'}>
              <Card>
                <TabList
                  onChange={(event, newTab) => {
                    setTab(newTab);
                    form.trigger('video');
                    form.trigger('youtubeUrl');
                  }}
                  sx={{ px: 3 }}
                >
                  <Tab label="Novo Vídeo" value="upload" />
                  <Tab label="Biblioteca" value="library" />
                  {/* <Tab label="Reutilizar de outra aula" value="class" /> */}
                </TabList>
              </Card>
              <TabPanel
                value="upload"
                sx={{
                  p: 0,
                }}
              >
                {uppyStates[tabName] &&
                  (isLoadingVideo ? (
                    <Stack gap={2} p={2} position="relative">
                      <Skeleton variant="rounded" w height={300} />
                      <Typography position="absolute" left={180} top={150}>
                        Carregando o vídeo ...
                      </Typography>
                    </Stack>
                  ) : (
                    <Dashboard
                      uppy={uppyStates[tabName]}
                      plugins={['GoogleDrive', 'Dropbox']}
                      metaFields={[{ id: 'name', name: 'Name', placeholder: 'File name' }]}
                      height="300px"
                    />
                  ))}
              </TabPanel>
              <TabPanel
                value="library"
                sx={{
                  p: 0,
                }}
              >
                <VideoLibrary isToAdd />
              </TabPanel>
              <TabPanel
                value="class"
                sx={{
                  p: 0,
                }}
              >
                <SearchableTable
                  count={1}
                  fetching={false}
                  labels={[
                    {
                      id: 'product',
                      label: 'Produto',
                    },
                    {
                      id: 'module',
                      label: 'Módulo',
                    },
                    {
                      id: 'lesson',
                      label: 'Conteúdo',
                    },
                  ]}
                  rows={[
                    {
                      id: '1',
                      product: {
                        id: '1',
                        name: 'Produto 1',
                      },
                      module: {
                        id: '1',
                        name: 'Módulo 1',
                      },
                      lesson: {
                        id: '1',
                        name: 'Conteúdo 1',
                        video: '1',
                      },
                    },
                    {
                      id: '2',
                      product: {
                        id: '1',
                        name: 'Produto 1',
                      },
                      module: {
                        id: '1',
                        name: 'Módulo 1',
                      },
                      lesson: {
                        id: '2',
                        name: 'Conteúdo 2',
                        youtubeUrl: 'https://www.youtube.com/watch?v=LXb3EKWsInQ',
                        video: '',
                      },
                    },
                  ]}
                  table={lessonsTable}
                  renderRow={(row) => (
                    <>
                      <TableCell>{row.product?.name}</TableCell>
                      <TableCell>{row.module.name}</TableCell>
                      <TableCell>{row.lesson.name}</TableCell>
                    </>
                  )}
                  onRowClick={(row) => {
                    form.setValue('video', row.lesson.video, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                    form.setValue('youtubeUrl', row.lesson.youtubeUrl, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });

                    form.setValue('pandaVideoUrl', row.lesson.pandaVideoUrl, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                  }}
                  inMemoryFilter={(row) => {
                    if (lessonsTable.search) {
                      return row.lesson.name
                        .toLowerCase()
                        .includes(lessonsTable.search.toLowerCase());
                    }
                    return true;
                  }}
                />
              </TabPanel>
            </TabContext>
          )}

          {form.watch('video') &&
            !form.watch('video').includes('pandavideo') &&
            !form.watch('video').includes('youtube') &&
            SuccessBanner}

          {form.watch('panda') && (
            <Stack gap={1} p={2}>
              <RHFTextField
                name="pandaVideoUrl"
                label="URL do vídeo (Embed Cademi)"
                placeholder="https://player-vz-75dshdhgj6-f12.tv.pandavideo.com/embed/?v=..."
                size="small"
                type="url"
                onChange={handleChangePandaVideo}
              />

              {form.watch('pandaVideoUrl') && (
                <Box
                  width={1}
                  sx={{
                    aspectRatio: '16/9',
                  }}
                >
                  <iframe
                    title="Panda Video"
                    src={form.watch('pandaVideoUrl')}
                    allowFullScreen
                    frameBorder="0"
                    style={{ borderRadius: 10, width: '100%', height: '100%', display: 'block' }}
                  />
                </Box>
              )}
              <Stack justifyContent="center" alignItems="center">
                {ResetButton}
              </Stack>
            </Stack>
          )}

          {form.watch('youtube') && (
            <Stack gap={1} p={2}>
              <RHFTextField
                name="youtubeUrl"
                label="URL do vídeo"
                size="small"
                type="url"
                onChange={(e) => handleChangeYoutubeVideo(e.target.value)}
                placeholder="https://www.youtube.com/watch?v=..."
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {youtubeVideoId && (
                <Box
                  width={1}
                  sx={{
                    aspectRatio: '16/9',
                  }}
                >
                  <iframe
                    title="YouTube"
                    src={`https://www.youtube.com/embed/${youtubeVideoId}`}
                    allowFullScreen
                    frameBorder="0"
                    style={{ borderRadius: 10, width: '100%', height: '100%', display: 'block' }}
                  />
                </Box>
              )}
              <Stack justifyContent="center" alignItems="center">
                {ResetButton}
              </Stack>
            </Stack>
          )}

          {!form.watch('youtube') && !form.watch('panda') && !form.watch('video') && (
            <Stack justifyContent="center" alignItems="center" py={1}>
              <Button
                startIcon={
                  <Iconify
                    icon="logos:youtube-icon"
                    width={20}
                    height={20}
                    color="text.secondary"
                  />
                }
                variant="text"
                color="inherit"
                sx={{
                  textTransform: 'none',
                }}
                onClick={() => {
                  form.setValue('youtube', !form.watch('youtube'), {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                  form.trigger('video');
                }}
              >
                Usar vídeo do YouTube
              </Button>
              <Button
                startIcon={
                  <Iconify icon="noto-v1:panda" width={20} height={20} color="text.secondary" />
                }
                variant="text"
                color="inherit"
                sx={{
                  textTransform: 'none',
                }}
                onClick={() => {
                  form.setValue('panda', !form.watch('panda'), {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                  form.trigger('video');
                }}
              >
                Usar vídeo do Panda
              </Button>
            </Stack>
          )}
        </Card>
        {form.formState.errors.video && (
          <Typography variant="caption" color="error">
            {form.formState.errors.video?.message}
          </Typography>
        )}
      </Stack>

      <Box>
        <Typography variant="caption" color="text.secondary">
          Thumbnail
        </Typography>
        <RHFUpload
          name="thumbnail"
          maxSize={10000000}
          onDrop={handleDropFile}
          onRemove={() =>
            form.setValue('thumbnail', null, { shouldValidate: true, shouldDirty: true })
          }
          helperText="Formatos aceitos: JPG ou PNG. Tamanho máximo: 10MB"
          accept={{ 'image/jpeg': [], 'image/png': [] }}
          aspectRatio="16/9"
        />
        <Alert severity="info" sx={{ mt: 1 }}>
          Tamanho recomendado: 800x450 pixels
        </Alert>
      </Box>

      <Box>
        <Typography variant="caption" color="text.secondary">
          Arquivos
        </Typography>

        <RHFUpload
          multiple
          name="files"
          maxSize={1073741824}
          onRemove={handleRemoveFile}
          onDrop={handleAddFiles}
          onDownload={handleDownloadFile}
          accept={{ '*': [] }}
          helperText="Tamanho máximo: 1GB."
          fileTooLargeErrorMessage="Nenhum arquivo pode ser maior que 1GB."
        />
      </Box>
    </Stack>
  );
};

ProductLessonDetailsForm.propTypes = {
  setIsUploadingVideo: PropTypes.func,
};

export default ProductLessonDetailsForm;

import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Skeleton,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import Iconify from '../../components/iconify';
import { getCourseById } from '../../services/members/courses';

// ----------------------------------------------------------------------
export default function WatchCourse() {
  const { course_id } = useParams();

  const { data: course, isLoading } = useQuery({
    queryFn: () => getCourseById({ courseId: course_id }),
    queryKey: ['members-courses', course_id],
    select(data) {
      return {
        name: data.name,
        videos: data.modules.map((module) => ({
          id: module.id,
          title: module.name,
          duration: '',
          videoUrl: module.coverImage.preview,
        })),
      };
    },
  });

  return (
    <Container maxWidth="xl" style={{ marginTop: '2rem', padding: '5rem' }}>
      <Helmet>
        <title>{course?.name}</title>
      </Helmet>

      <Typography variant="h4" style={{ marginBottom: '3rem' }}>
        {course?.name}
      </Typography>
      <Grid container spacing={2}>
        {isLoading && (
          <>
            {[...Array(4)].map((_, index) => (
              <Grid key={index} item xs={12} md={6} lg={4}>
                <Skeleton variant="rectangular" sx={{ width: 1, height: 396, borderRadius: 2 }} />
              </Grid>
            ))}
          </>
        )}
        {course?.videos.length ? (
          course?.videos.map((item) => (
            <Grid item key={item.id} xs={12} md={6} lg={4}>
              <Card key={item.id} style={{ height: '100%', width: '100%', cursor: 'pointer' }}>
                <CardMedia component="img" height="240" image={item.videoUrl} alt={item.title} />
                <CardContent style={{ height: '156px' }}>
                  <Typography variant="h6" component="div">
                    {item.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {`Duração:  ${item.duration}`}
                  </Typography>
                  <Button
                    endIcon={<Iconify icon="mdi:play" />}
                    variant="contained"
                    style={{ marginTop: '0.5rem', width: '100%' }}
                  >
                    Play
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <>{!isLoading && <span>Nenhum vídeo encontrado!</span>}</>
        )}
      </Grid>
    </Container>
  );
}

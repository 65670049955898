import { TableCell, Typography, Button } from '@mui/material';
import { Stack } from '@mui/system';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import Iconify from '../../../components/iconify';
import SearchableTable from '../../../components/table/SearchableTable';
import { FormScopes } from '../../../utils/form';

import { ProductStudentsContext } from '../../../contexts/ProductStudentsContext';
import ConfirmDialog from '../../../components/confirm-dialog/ConfirmDialog';
import { getAccessDisplay } from './utils';

ProductStudentsTable.propTypes = {};

const labels = [
  { id: 'name', label: 'Nome' },
  { id: 'email', label: 'Email' },
  { id: 'lastAccess', label: 'Fim do acesso' },
  { id: 'delete', label: '' },
];

export default function ProductStudentsTable() {
  const { fetching, students, increaseStudentsLimit, table, setFormValues, setScope, status } =
    useContext(ProductStudentsContext);

  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
    const clientHeight =
      window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight - 5) {
      increaseStudentsLimit();
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SearchableTable
      fetching={status === 'loading'}
      infiniteLoading={fetching}
      labels={labels}
      rows={students}
      table={table}
      filterable
      renderRow={(row) => <ProductStudentsTableRow row={row} />}
      onRowClick={(row) => {
        setFormValues(row);
        setScope(FormScopes.SHOW);
      }}
    />
  );
}

ProductStudentsTableRow.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    acesso: PropTypes.string,
  }),
};

function ProductStudentsTableRow({ row }) {
  const [isRemoveAccessModalOpen, setRemoveAccessModalOpen] = useState(false);

  const { removeProductStudendAccess, isLoadingRemoveProductStudentAccess } = useContext(ProductStudentsContext);

  return (
    <>
      <TableCell>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {row?.name || '-'}
        </Typography>
      </TableCell>

      <TableCell>
        <Stack direction="row" alignItems="center" gap={1}>
          <Iconify icon="eva:email-fill" width={18} color="text.secondary" />
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {row?.email || '-'}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {getAccessDisplay(row?.acesso)}
        </Typography>
      </TableCell>

      <TableCell>
        <Iconify
          icon="eva:trash-2-fill"
          onClick={() => {
            setRemoveAccessModalOpen(true);
          }}
        />
      </TableCell>

      <ConfirmDialog
        open={isRemoveAccessModalOpen}
        onClose={() => setRemoveAccessModalOpen(false)}
        action={
          <Button
            variant="contained"
            color="error"
            startIcon={<Iconify icon="eva:trash-2-outline" />}
            onClick={() => removeProductStudendAccess({ email: row.email })}
            disabled={isLoadingRemoveProductStudentAccess}
          >
            Remover
          </Button>
        }
        title="Remover acesso"
        content={`Tem certeza que deseja remover o acesso de ${row.email}?`}
      />
    </>
  );
}

import { addDays } from 'date-fns';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Drawer, IconButton, ListItemText, Tab, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import PropTypes from 'prop-types';
import { useContext, useState, useMemo } from 'react';
import Iconify from '../../../components/iconify';
import Label from '../../../components/label';
import { ProductStudentsContext } from '../../../contexts/ProductStudentsContext';
// import { fPercent } from '../../../utils/formatNumber';
import { getDurationInDays } from '../../../utils/formatTime';
import { getAccessDisplay } from './utils';

ProductStudentDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default function ProductStudentDrawer({ open, onClose }) {
  const [tab, setTab] = useState('details');

  const { formValues: student } = useContext(ProductStudentsContext);

  const status = useMemo(() => {
    const duration = getDurationInDays(new Date(), addDays(new Date(student.acesso), 1));
    const lifetimeAccess = student.acesso === 'lifetime';

    return duration >= 0 || lifetimeAccess
      ? {
          color: 'success',
          label: 'Ativo',
        }
      : {
          color: 'error',
          label: 'Inativo',
        };
  }, [student]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          p: 2,
          width: {
            xs: '100%',
            sm: 400,
          },
        },
      }}
    >
      <Stack direction="row" alignItems="center" gap={1}>
        <IconButton onClick={onClose}>
          <Iconify icon="eva:chevron-right-fill" />
        </IconButton>
        <Typography variant="subtitle1">Detalhes do Aluno</Typography>
      </Stack>
      <Box p={2}>
        <TabContext value={tab}>
          <TabList onChange={(event, value) => setTab(value)}>
            <Tab value="details" label="Detalhes" />
          </TabList>
          <TabPanel value="details" sx={{ px: 0, py: 2 }}>
            <Stack spacing={2}>
              <ListItemText
                primary={
                  <Typography variant="body2" fontWeight="bold">
                    Nome
                  </Typography>
                }
                secondary={
                  <Typography variant="body2" color="text.secondary">
                    {student?.name || '-'}
                  </Typography>
                }
              />

              <ListItemText
                primary={
                  <Typography variant="body2" fontWeight="bold">
                    E-mail
                  </Typography>
                }
                secondary={
                  <Typography variant="body2" color="text.secondary">
                    {student?.email || '-'}
                  </Typography>
                }
              />

              {/* <ListItemText
                primary={
                  <Typography variant="body2" fontWeight="bold">
                    Turma
                  </Typography>
                }
                secondary={
                  <Typography variant="body2" color="text.secondary">
                    {student?.class || '-'}
                  </Typography>
                }
              /> */}

              <ListItemText
                primary={
                  <Typography variant="body2" fontWeight="bold">
                    Status
                  </Typography>
                }
                secondary={
                  <Label variant="soft" color={status.color}>
                    {status.label}
                  </Label>
                }
              />

              <ListItemText
                primary={
                  <Typography variant="body2" fontWeight="bold">
                    Fim do acesso
                  </Typography>
                }
                secondary={
                  <Typography variant="body2" color="text.secondary">
                    {getAccessDisplay(student?.acesso)}
                  </Typography>
                }
              />

              {/* <ListItemText
                primary={
                  <Typography variant="body2" fontWeight="bold">
                    Progresso
                  </Typography>
                }
                secondary={
                  <Typography variant="body2" color="text.secondary">
                    {fPercent(student?.progress || '0.00')}
                  </Typography>
                }
              /> */}
            </Stack>
          </TabPanel>
        </TabContext>
      </Box>
    </Drawer>
  );
}

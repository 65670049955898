import { Box, LinearProgress, Toolbar } from '@mui/material';
import { useContext } from 'react';
import { Outlet } from 'react-router';
import Scrollbar from '@/components/scrollbar/Scrollbar';
import { CheckoutBuilderContext } from '@/contexts/CheckoutBuilderContext';
import useResponsive from '@/hooks/useResponsive';
import { CHECKOUT_BUILDER_DRAWER_WIDTH } from '@/sections/@checkout-builder/constants';

import Header from './Header';

const CheckoutBuilderLayout = () => {
  const { loading, checkout } = useContext(CheckoutBuilderContext);
  const isMobile = useResponsive('down', 'sm');

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
      <Header />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: isMobile ? '100%' : `calc(100% - ${CHECKOUT_BUILDER_DRAWER_WIDTH}px)`,
          overflow: 'hidden',
        }}
      >
        <Toolbar />
        <Scrollbar
          autoHide={false}
          sx={{ height: 'calc(100svh - 64px)', pr: 0.5, position: 'relative' }}
        >
          {loading && <LinearProgress />}
          {!!checkout && <Outlet />}
        </Scrollbar>
      </Box>
    </Box>
  );
};

export default CheckoutBuilderLayout;

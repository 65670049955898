import { Button, ButtonGroup, IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useContext, useEffect, useMemo } from 'react';
import ConfirmDialog from '../../../components/confirm-dialog/ConfirmDialog';
import Iconify from '../../../components/iconify/Iconify';
import Label from '../../../components/label/Label';
import {
  ProductLessonDefaultValues,
  ProductLessonsContext,
  ProductLessonstatus,
  ProductModuleLessonTypes,
} from '../../../contexts/ProductLessonsContext';
import { FormScopes } from '../../../utils/form';

const ProductLessonCard = ({ lesson }) => {
  const {
    scope,
    formValues,
    setFormValues,
    setScope,
    update,
    remove,
    lessonsValues,
    setLessonsValues,
  } = useContext(ProductLessonsContext);

  useEffect(() => {
    setLessonsValues((prev) => [
      ...prev,
      {
        id: lesson.id,
        title: lesson.title,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(
    () => lessonsValues.find(({ id }) => id === lesson.id)?.title || '',
    [lessonsValues, lesson]
  );

  const onChange = (e) => {
    setLessonsValues((prev) =>
      prev.map((item) => {
        if (item.id === lesson.id) {
          return {
            ...item,
            title: e.target.value,
          };
        }

        return item;
      })
    );
  };

  return (
    <Stack
      direction="row"
      gap={1}
      alignItems="center"
      sx={{
        mb: 1,
      }}
    >
      <Iconify
        className="drag-handle-lessons"
        icon="ic:sharp-menu"
        sx={{ cursor: 'grab', width: 18, height: 18, mr: 1 }}
      />
      {/* <Checkbox
        size="small"
        labelPlacement="top"
        checked={selected.includes(lesson.id)}
        onChange={(e) => {
          if (e.target.checked) {
            return setSelected([...selected, lesson.id]);
          }
          return setSelected(selected.filter((id) => id !== lesson.id));
        }}
      /> */}
      <TextField
        value={value}
        onChange={onChange}
        size="small"
        sx={{
          flex: 1,
        }}
        variant="filled"
        InputProps={{
          startAdornment: (
            <Stack alignItems="center" pr={1}>
              <Iconify icon={ProductModuleLessonTypes[lesson.type].icon} width={18} height={18} />
            </Stack>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Iconify
                icon="mdi:circle"
                sx={{
                  color: ProductLessonstatus[lesson.status].color.concat('.main'),
                  display: {
                    sm: 'none',
                  },
                }}
                width={10}
              />
              <Label
                variant="soft"
                color={ProductLessonstatus[lesson.status].color}
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'flex',
                  },
                }}
              >
                {ProductLessonstatus[lesson.status].name}
              </Label>
            </InputAdornment>
          ),
          hiddenLabel: true,
        }}
        onBlur={(e) => {
          console.log(e.target.value);
          update({
            ...lesson,
            id: lesson.id,
            title: e.target.value,
          });
        }}
      />

      <ButtonGroup
        size="small"
        sx={{
          '& .MuiButtonBase-root': {
            padding: 0.5,
          },
        }}
      >
        <IconButton>
          <Iconify icon="eva:external-link-fill" />
        </IconButton>
        <IconButton
          onClick={() => {
            setScope(FormScopes.EDIT);
            setFormValues({
              ...ProductLessonDefaultValues,
              ...lesson,
            });
          }}
        >
          <Iconify icon="eva:edit-2-fill" />
        </IconButton>
        <IconButton
          onClick={() => {
            setFormValues({
              ...ProductLessonDefaultValues,
              ...lesson,
            });
            setScope(FormScopes.DELETE);
          }}
        >
          <Iconify icon="eva:trash-2-fill" />
        </IconButton>
      </ButtonGroup>

      <ConfirmDialog
        open={scope === FormScopes.DELETE}
        onClose={() => setScope(FormScopes.INDEX)}
        action={
          <Button
            variant="contained"
            color="error"
            startIcon={<Iconify icon="eva:trash-2-outline" />}
            onClick={() => remove({ ids: [formValues.id] })}
          >
            Remover
          </Button>
        }
        title="Remover conteúdo"
        content={`Tem certeza que deseja remover o conteúdo "${formValues.title}"?`}
      />
    </Stack>
  );
};

ProductLessonCard.propTypes = {
  lesson: PropTypes.object.isRequired,
};

export default ProductLessonCard;

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import '@uppy/file-input/dist/style.css';
import '@uppy/progress-bar/dist/style.css';
import '@uppy/status-bar/dist/style.css';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Dialog,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  Card,
  Tab,
  Skeleton,
} from '@mui/material';
import { Dashboard } from '@uppy/react';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar/Scrollbar';
import VideosLibrary from '../../../components/video-library';
import { BatchVideoUploadContext } from '../../../contexts/BatchVideoUploadContext';

const ProductVideosUploader = ({ open, onClose }) => {
  const [tab, setTab] = useState('library');
  const { uppy, isLoadingFile } = useContext(BatchVideoUploadContext);

  return (
    <Dialog
      fullWidth
      scroll="body"
      maxWidth="sm"
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <Scrollbar>
        <Stack p={2} gap={2}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <ListItem>
              <ListItemIcon>
                <Iconify icon="majesticons:library-line" width={20} height={20} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body2" fontWeight="thin">
                    Biblioteca de Vídeos
                  </Typography>
                }
                secondary={
                  <Typography variant="caption" fontWeight="thin" color="text.secondary">
                    Veja e gerencie seus vídeos
                  </Typography>
                }
              />
            </ListItem>
            <IconButton size="small" onClick={onClose}>
              <Iconify icon="mdi:close" width={20} height={20} />
            </IconButton>
          </Stack>
          <Card variant="outlined">
            <TabContext value={tab}>
              <Card>
                <TabList
                  onChange={(event, newTab) => {
                    setTab(newTab);
                  }}
                  sx={{ px: 3 }}
                >
                  <Tab label="Biblioteca" value="library" />
                  <Tab
                    icon={<Iconify icon="eva:upload-outline" width={20} height={20} />}
                    label="Upload de Vídeos"
                    value="upload"
                  />
                </TabList>
              </Card>
              <TabPanel
                value="library"
                sx={{
                  p: 0,
                }}
              >
                <VideosLibrary />
              </TabPanel>
              <TabPanel
                value="upload"
                sx={{
                  p: 0,
                }}
              >
                {isLoadingFile ? (
                  <Stack gap={2} p={2} position="relative">
                    <Skeleton variant="rounded" w height={500} />
                    <Typography position="absolute" left={200} top={250}>
                      Carregando os vídeos ...
                    </Typography>
                  </Stack>
                ) : (
                  <Dashboard
                    uppy={uppy}
                    metaFields={[{ id: 'name', name: 'Name', placeholder: 'File name' }]}
                  />
                )}
              </TabPanel>
            </TabContext>
          </Card>
        </Stack>
      </Scrollbar>
    </Dialog>
  );
};

ProductVideosUploader.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ProductVideosUploader;

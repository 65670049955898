const { AuthenticatedHttp } = require('../../http/axios');
const { parsePaginationResponse } = require('../../utils/api');

export const getNotifications = async ({ page, search }) =>
  AuthenticatedHttp.get('/notifications/', {
    params: {
      page,
      search,
    },
  }).then(parsePaginationResponse);

export const readAllNotifications = async () =>
  AuthenticatedHttp.post('/notifications/mark-all-read/');

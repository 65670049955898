import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { createContext, useMemo, useState } from 'react';
import useTableFilter from '../hooks/useTableFilter';
import { getAffiliates } from '../services/affiliates';
import {
  exportReport,
  getOrders,
  refundOrderService,
  resendOrderEmailService,
} from '../services/orders';
import { getProductsService } from '../services/products';

export const OrderType = {
  unique: {
    label: 'Pagamento único',
    color: 'primary',
  },
  subscription: {
    label: 'Pagamento recorrente',
    color: 'info',
  },
};

export const OrderStatus = {
  processing: {
    label: 'Processando',
    color: 'warning',
  },
  authorized: {
    label: 'Autorizado',
    color: 'success',
  },
  paid: {
    label: 'Pago',
    color: 'success',
  },
  refunded: {
    label: 'Reembolsado',
    color: 'error',
  },
  waiting_payment: {
    label: 'Aguardando pagamento',
    color: 'warning',
  },
  refused: {
    label: 'Recusado',
    color: 'error',
  },
  blocked: {
    label: 'Recusado',
    color: 'error',
  },
  chargedback: {
    label: 'Chargeback',
    color: 'error',
  },
  canceled: {
    label: 'Cancelado',
    color: 'error',
  },
  in_protest: {
    label: 'Em protesto',
    color: 'error',
  },
  partially_paid: {
    label: 'Parcialmente pago',
    color: 'warning',
  },
  refund_requested: {
    label: 'Reembolso solicitado',
    color: 'warning',
  },
};

export const PERIODS = {
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  LAST_WEEK: 'last_week',
  LAST_MONTH: 'last_month',
  CUSTOM: 'custom',
  ALL_TIME: 'all_time',
};

export const OrderCoproductionType = {
  all: {
    label: 'Todos',
    value: 'all',
  },
  production: {
    label: 'Sou produtor',
    value: 'production',
  },
  coproduction: {
    label: 'Sou co-produtor',
    value: 'coproduction',
  },
  affiliate: {
    label: 'Sou afiliado',
    value: 'affiliate',
  },
};

export const OrderPaymentMethod = {
  credit_card: {
    value: 'credit_card',
    label: 'Cartão de crédito',
    icon: 'eva:credit-card-fill',
  },
  boleto: {
    value: 'boleto',
    label: 'Boleto',
    icon: 'material-symbols:barcode',
  },
  pix: {
    value: 'pix',
    label: 'Pix',
    icon: 'ic:baseline-pix',
  },
};

export const OrdersContext = createContext({
  table: {},
  orders: [],
  fetching: false,
  total: 0,
  refund: [],
  status: 'paid',
  setStatus: () => {},
  products: [],
  fetchingProducts: false,
  affiliates: [],
  fetchingAffiliates: false,
  ordersCount: 0,
  ordersAmount: 0,
  ordersRefundAmount: 0,
  ordersPixAmount: 0,
  refundPercentage: 0,
  ordersChargebackAmount: 0,
  mutateExport: () => {},
  loadingExport: false,
  resedOrderEmail: () => {},
});

export const defaultFilter = {
  period: PERIODS.ALL_TIME,
  startDate: null,
  endDate: null,
  coproductionType: OrderCoproductionType.all.value,
  products: [],
  offerType: 'all',
  offers: [],
  affiliates: [],
  paymentMethods: [],
  status: [],
};

const OrdersProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const table = useTableFilter({
    defaultCurrentPage: 1,
    defaultFilter,
  });

  const [status, setStatus] = useState('paid');
  const queryClient = useQueryClient();

  const {
    data: { products },
    isFetching: fetchingProducts,
  } = useQuery({
    queryFn: () =>
      getProductsService({
        page: 1,
        limit: 1000,
      }),
    queryKey: ['products'],
    initialData: {
      products: [],
    },
  });

  const {
    data: { results: affiliates },
    isFetching: fetchingAffiliates,
  } = useQuery({
    queryFn: () =>
      getAffiliates({
        page: 1,
        limit: 1000,
      }),
    queryKey: ['affiliates'],
    initialData: {
      affiliates: [],
    },
  });

  const {
    data: {
      results: orders,
      count: total,
      ordersCount,
      ordersAmount,
      ordersRefundAmount,
      ordersPixAmount,
      refundPercentage,
      ordersChargebackAmount,
    },
    isFetching: fetching,
  } = useQuery({
    queryFn: async () =>
      getOrders({
        page: table.page,
        search: table.search,
        status: status === 'paid' ? 'paid' : '',
        filter: table.filter,
      }),
    queryKey: ['orders', table.page, table.search, status, table.filter],
    initialData: {
      results: [],
      total: 0,
      ordersCount: 0,
      ordersAmount: 0,
      ordersRefundAmount: 0,
      ordersPixAmount: 0,
      refundPercentage: 0,
      ordersChargebackAmount: 0,
    },
  });

  const { mutateAsync: refund } = useMutation({
    mutationKey: ['orders.refund'],
    mutationFn: (data) => refundOrderService({ data }),
    onSuccess: () => {
      queryClient.invalidateQueries('product');

      enqueueSnackbar('Pedido reembolsado com sucesso!', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('Não foi possível reembolsar a venda!', {
        variant: 'error',
      });
    },
  });

  const { mutate: mutateExport, isLoading: loadingExport } = useMutation({
    async mutationFn(params) {
      await exportReport({
        ...params,
        page: table.page,
        search: table.search,
        status: status === 'paid' ? 'paid' : '',
        filter: table.filter,
      });
    },
    onSuccess() {
      enqueueSnackbar('Exportação realizada com sucesso', { variant: 'success' });
    },
    onError() {
      enqueueSnackbar('Ocorreu um erro com a exportação!', {
        variant: 'error',
      });
    },
  });

  const { mutate: resedOrderEmail } = useMutation({
    mutationFn: (orderId) => resendOrderEmailService(orderId),
    onSuccess({ detail }) {
      enqueueSnackbar(detail, { variant: 'success' });
    },
    onError(error) {
      enqueueSnackbar(error.response.data.detail, {
        variant: 'error',
      });
    },
  });

  const value = useMemo(
    () => ({
      table,
      orders,
      fetching,
      total,
      refund,
      status,
      setStatus,
      products,
      fetchingProducts,
      affiliates,
      fetchingAffiliates,
      ordersCount,
      ordersAmount,
      ordersRefundAmount,
      ordersPixAmount,
      refundPercentage,
      ordersChargebackAmount,
      mutateExport,
      loadingExport,
      resedOrderEmail,
    }),
    [
      table,
      orders,
      fetching,
      refund,
      total,
      status,
      products,
      fetchingProducts,
      affiliates,
      fetchingAffiliates,
      ordersCount,
      ordersAmount,
      ordersRefundAmount,
      ordersPixAmount,
      refundPercentage,
      ordersChargebackAmount,
      mutateExport,
      loadingExport,
      resedOrderEmail,
    ]
  );

  return <OrdersContext.Provider value={value}>{children}</OrdersContext.Provider>;
};

OrdersProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default OrdersProvider;

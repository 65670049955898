import { AuthenticatedHttp } from '../../http/axios';
import { uploadIfNecessary } from './courses';

export const createCourseModule = async ({ courseId, name, coverImage }) =>
  AuthenticatedHttp.post(`/members/${courseId}/modules/`, {
    modulos: [
      {
        nome: name,
        capa: await uploadIfNecessary({ courseId, image: coverImage }),
      },
    ],
  });

export const reorderCourseModules = async ({ courseId, modules }) =>
  AuthenticatedHttp.put(`/members/${courseId}/modules/`, {
    modulos: modules.map((module) => ({
      id: module.id,
      posicao: module.order,
      aulas: module.lessons.map((lesson) => ({
        id: lesson.id,
        posicao: lesson.order,
      })),
    })),
  });

export const updateCourseModule = async ({ courseId, id, name, coverImage, capa }) =>
  AuthenticatedHttp.put(`/members/${courseId}/modules/`, {
    modulos: [
      {
        id,
        nome: name,
        capa: coverImage ? await uploadIfNecessary({ courseId, image: coverImage }) : capa || '',
        aulas: [],
      },
    ],
  });

export const deleteCourseModule = async ({ courseId, ids }) =>
  AuthenticatedHttp.delete(`/members/${courseId}/modules/`, {
    data: {
      moduloIds: ids,
    },
  });

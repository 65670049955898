import PropTypes from 'prop-types';
import { createContext, useMemo } from 'react';
import useNotifications from '../hooks/useNotifications';
import useTableFilter from '../hooks/useTableFilter';

export const NotificationsContext = createContext({
  table: {},
  notifications: [],
  fetching: false,
  readAll: () => {},
  reading: false,
  unread: 0,
  count: 0,
});

const NotificationsProvider = ({ children }) => {
  const table = useTableFilter({
    defaultCurrentPage: 1,
  });

  const { fetching, notifications, readAll, reading, unread, count } = useNotifications({
    page: table.page,
    search: table.search,
  });

  const value = useMemo(
    () => ({
      table,
      notifications,
      fetching,
      readAll,
      reading,
      unread,
      count,
    }),
    [table, notifications, fetching, readAll, reading, unread, count]
  );

  return <NotificationsContext.Provider value={value}>{children}</NotificationsContext.Provider>;
};

NotificationsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NotificationsProvider;

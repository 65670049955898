import { format } from 'date-fns';
import { AuthenticatedHttp } from '../../http/axios';
import { parsePaginationResponse } from '../../utils/api';

export const getWithdrawals = async ({ page, search, start_date, end_date }) =>
  AuthenticatedHttp.get('/withdrawals/', {
    params: {
      page,
      search,
      createdAt__gte: format(new Date(start_date), 'yyyy-MM-dd'),
      createdAt__lte: format(new Date(end_date), 'yyyy-MM-dd'),
    },
  }).then(parsePaginationResponse);

export const getBalance = async () =>
  AuthenticatedHttp.get('/financial/balance/').then((response) => response.data);

export const getDataCompany = async () =>
  AuthenticatedHttp.get('/financial/company/')
    .then((response) => {
      const regex = /\/([^/]+)$/;

      const files = response.data.files.map((data) => ({
        ...data,
        path: data?.file || '',
        name: data?.file.match(regex)[1] || '',
      }));

      const _response = { ...response.data, files };

      return _response;
    })
    .catch(({ response }) => ({ data: response?.data }));

export const doWithdrawal = async ({ amount }) =>
  AuthenticatedHttp.post('/financial/withdraw/', { amount }).then((response) => response.data);

export const anticipatePendingAmount = async ({ amount }) =>
  AuthenticatedHttp.post('/financial/anticipate/', { amount }).then((response) => response.data);

export const getPixKey = async () =>
  AuthenticatedHttp.get('/financial/pix/').then((response) => response.data);

export const savePixKey = async ({ pix }) =>
  AuthenticatedHttp.post('/financial/pix/', { pix }).then((response) => response.data);

// TODO REMOVER CASO NÃO TENHA MAIS CADASTRO DE DADOS BANCÁRIOS
export const saveAccountData = async (data) =>
  AuthenticatedHttp.post('/financial/bank_account/', { ...data, legalName: 'teste' }).then(
    (response) => response.data
  );

export const getFee = async () =>
  AuthenticatedHttp.get('/financial/fee/').then((response) => response.data);

export const updateCompany = async (data) =>
  AuthenticatedHttp.put(`/financial/company/`, { ...data }).then((response) => response.data);

export const addDocumentOnCompany = async ({ files, submitForApproval = false }) => {
  const formData = new FormData();

  files.forEach((file) => {
    formData.append(`files`, file);
  });

  formData.append('submitForApproval', submitForApproval);

  const { data } = await AuthenticatedHttp.put('/financial/company/add_files/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return data;
};

export const deleteDocumentOnCompany = async (id) =>
  AuthenticatedHttp.delete(`/financial/company/delete_file/${id}/`).then(
    (response) => response.data
  );

export const getWithdrawalFee = async () =>
  AuthenticatedHttp.get(`/financial/withdraw_fee/`).then((response) => response.data);

export const verifyDocumentsService = async () =>
  AuthenticatedHttp.post(`/financial/verification/`).then((response) => response.data);

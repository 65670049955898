import axios from 'axios';
import { parse, stringify } from 'qs';
import { clearSession, setSession } from '../auth/utils';

const GuestHttp = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const AuthenticatedHttp = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer: {
    encode: parse,
    serialize: (params) => stringify(params, { arrayFormat: 'comma' }),
  },
});

AuthenticatedHttp.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

AuthenticatedHttp.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status !== 401) {
      return Promise.reject(error);
    }

    const refreshToken = localStorage.getItem('refreshToken');

    if (!refreshToken) {
      return clearSession();
    }

    const originalRequest = error.config;

    return GuestHttp.post('/token/refresh/', { refresh: refreshToken })
      .then(({ data: { access, refresh } }) => {
        setSession({ accessToken: access, refreshToken: refresh });
        originalRequest.headers.Authorization = `Bearer ${access}`;
        return AuthenticatedHttp(originalRequest);
      })
      .catch(() => {
        if (error.response.status === 401) {
          window.location.href = '/auth/login';
        }
        clearSession();
      });
  }
);

export { AuthenticatedHttp, GuestHttp };

import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fNumber(number) {
  return numeral(number).format();
}

export const fShortCurrency = (value, others) =>
  new Intl.NumberFormat('pt-BR', {
    notation: 'compact',
    compactDisplay: 'short',
    style: 'currency',
    currency: 'BRL',
    ...others,
  }).format(value);

export function fCurrency(number) {
  if (!number) return 'R$ 0,00';
  const format = number.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

  return format;
}

export function fPercent(value, format, replace = '.00') {
  if (value === 0) return '0%';
  const number = value ? numeral(Number(value) / 100).format(format ? `${format}%` : '0.0%') : '';
  return result(number, replace).replace(/\./, ',');
}

export function fShortenNumber(number) {
  const format = number ? numeral(number).format('0.00a') : '';

  return result(format, '.00');
}

export function fData(number) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

function result(format, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}

export function fCpf(number) {
  const pattern = /(\d{3})(\d{3})(\d{3})(\d{2})/;

  return (number || '').toString().replace(pattern, '$1.$2.$3-$4');
}

export function fCnpj(number) {
  const pattern = /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/;

  return (number || '').toString().replace(pattern, '$1.$2.$3/$4-$5');
}

export function fPhone(number) {
  const cleaned = `${number || ''}`.replace(/\D/g, '');

  const formats = [
    {
      pattern: /^(\d{2})(\d{1})(\d{4})(\d{4})$/,
      mask: '($1) $2 $3-$4',
    },
    {
      pattern: /^(\d{2})(\d{4})(\d{4})$/,
      mask: '($1) $2-$3',
    },
  ];

  const format = formats.find((f) => f.pattern.test(cleaned));

  if (!format) {
    return number;
  }

  const { mask } = format;

  return cleaned.replace(format.pattern, mask);
}

export function formatPercentage(number = 0) {
  return `${((number * 100) / 100).toString().replace(/\./, ',')}%`;
}

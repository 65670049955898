import { differenceInCalendarDays, format, formatDistanceToNow, getTime } from 'date-fns';
import { formatWithOptions } from 'date-fns/fp';
import ptBR from 'date-fns/locale/pt-BR';

// ----------------------------------------------------------------------

function isValidDate(date) {
  const parsedDate = new Date(date);
  // eslint-disable-next-line no-restricted-globals
  return !isNaN(parsedDate.getTime());
}

export function fDate(date, newFormat) {
  if (!isValidDate(date)) return '';

  if (!date) return '';

  const fm = newFormat || 'dd MMM yyyy';

  const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
  if (dateFormatRegex.test(date)) {
    date = new Date(`${date}T00:00:00`);
  } else {
    date = new Date(date);
  }

  return date ? formatWithOptions({ locale: ptBR }, fm, date) : '';
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
        locale: ptBR,
      })
    : '';
}

export function getDurationInDays(startDate, endDate) {
  return differenceInCalendarDays(endDate, startDate);
}

export function convertTimestampToISODate(timestamp) {
  const date = new Date(timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000);

  return date.toISOString();
}

import PropTypes from 'prop-types';
// @mui
import { TableCell, Tooltip, Typography } from '@mui/material';
// components
import { LoadingButton } from '@mui/lab';
import { useContext } from 'react';
import Iconify from '../../../components/iconify/Iconify';
import SearchableTable from '../../../components/table/SearchableTable';
import { NotificationsContext } from '../../../contexts/NotificationsContext';

// ----------------------------------------------------------------------

NotificationsTable.propTypes = {
  subheader: PropTypes.string,
  tableLabels: PropTypes.array,
};

export default function NotificationsTable({ subheader, tableLabels, ...other }) {
  const { count, table, notifications, fetching, readAll, reading } =
    useContext(NotificationsContext);
  return (
    <SearchableTable
      count={count}
      fetching={fetching}
      labels={[
        { id: 'date', label: 'Data' },
        { id: 'name', label: 'Nome' },
        { id: 'commission', label: 'Comissão' },
        { id: 'status', label: 'Status' },
      ]}
      rows={notifications}
      table={table}
      Actions={
        <LoadingButton variant="contained" loading={reading} onClick={readAll}>
          Ler todas
        </LoadingButton>
      }
      renderRow={(row) => <NotificationsTableRow row={row} />}
      {...other}
    />
  );
}

// ----------------------------------------------------------------------

NotificationsTableRow.propTypes = {
  row: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    createdAt: PropTypes.string,
    read: PropTypes.bool,
  }),
};

function NotificationsTableRow({ row }) {
  return (
    <>
      <TableCell>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {row.title}
        </Typography>
      </TableCell>

      <TableCell>
        <Tooltip title={row.description}>
          <Typography
            variant="body2"
            sx={{
              color: 'text.secondary',
              maxWidth: 400,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {row.description}
          </Typography>
        </Tooltip>
      </TableCell>

      <TableCell>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {Intl.DateTimeFormat('pt-BR').format(new Date(row.createdAt))}
        </Typography>
      </TableCell>

      <TableCell>
        <Iconify
          icon={row.read ? 'eva:checkmark-circle-2-fill' : 'eva:close-circle-fill'}
          sx={{ color: row.read ? 'success.main' : 'error.main' }}
        />
      </TableCell>
    </>
  );
}

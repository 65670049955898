import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Badge,
  Card,
  Drawer,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
  Skeleton,
} from '@mui/material';
import { Stack } from '@mui/system';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ConfirmLeave } from '../../../components/confirm-dialog';
import FormProvider from '../../../components/hook-form/FormProvider';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar/Scrollbar';
import {
  ProductLessonDefaultValues,
  ProductLessonsContext,
  ProductModuleLessonTypes,
} from '../../../contexts/ProductLessonsContext';
import useDirtyFieldsCount from '../../../hooks/useDirtyFieldsCount';
import { FormScopes } from '../../../utils/form';
import ProductLessonContentForm from './ProductLessonContentForm';
import ProductLessonDetailsForm from './ProductLessonDetailsForm';
import ProductQuizDetailsForm from './ProductQuizDetailsForm';

const ProductLessonForm = ({ scope, open, onClose }) => {
  const { update, create, creating, updating, currentLesson, isLoadingCurrentLesson, formValues } =
    useContext(ProductLessonsContext);
  const [leave, setLeave] = useState(false);
  const [isUploadingVideo, setIsUploadingVideo] = useState(false);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const schema = yup.object().shape({
    title: yup.string().required('O título é obrigatório'),
    type: yup.string(),
    youtube: yup.boolean(),
    video: yup.string().when('youtube', {
      is: false,
      then: yup.string(),
    }),
    youtubeUrl: yup
      .string()
      .url('A URL deve estar no formato http://youtube.com/watch?v=xxxxxxxxxxx')
      .when('youtube', {
        is: true,
        then: yup.string().required('A URL do Youtube é obrigatória'),
      }),
    pandaVideoUrl: yup.string().when('panda', {
      is: true,
      then: yup
        .string()
        .required('A URL do Panda é obrigatória')
        .matches(
          /^https:\/\/.*\.tv\.pandavideo\.com(\.br)?\/embed\/\?v=[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/,
          {
            message:
              'A URL deve estar no formato https://player-vz-75dshfetj6-f12.tv.pandavideo.com/embed/?v=...',
          }
        ),
    }),
    content: yup.string(),
    thumbnail: yup.object().nullable(true),
    releaseType: yup.string().required('O tipo de liberação é obrigatório'),
    releaseDays: yup.number().when('releaseType', {
      is: 'days',
      then: yup
        .number()
        .integer('O número de dias deve ser um número inteiro')
        .typeError('O número de dias deve ser um número inteiro')
        .required('O número de dias é obrigatório'),
    }),
    releaseDate: yup.date().when('releaseType', {
      is: 'date',
      then: yup
        .date()
        .typeError('A data deve estar no formato dd/mm/yyyy')
        .required('A data é obrigatória'),
    }),
    limitDuration: yup.boolean(),
    limitDurationDays: yup.number().when('limitDuration', {
      is: true,
      then: yup
        .number()
        .integer('A duração deve ser um número inteiro')
        .typeError('A duração deve ser um número inteiro')
        .required('A duração é obrigatória'),
    }),
    questions: yup.array().when('type', {
      is: ProductModuleLessonTypes.quiz.id,
      then: yup.array().of(
        yup.object().shape({
          title: yup.string().required('O título é obrigatório'),
          required: yup.boolean(),
          answers: yup
            .array()
            .of(
              yup.object().shape({
                title: yup.string().required('O título é obrigatório'),
                correct: yup.boolean(),
              })
            )
            .min(2, 'Insira pelo menos duas respostas')
            .test('at-least-one-correct', 'Insira pelo menos uma resposta correta', (value) =>
              value.some((answer) => answer.correct)
            ),
        })
      ),
    }),
    allowMultipleAnswers: yup.boolean(),
    randomizeQuestions: yup.boolean(),
    randomizeAnswers: yup.boolean(),
    files: yup
      .array()
      .max(10, 'Limite máximo de arquivos excedido (máximo: 10)')
      .nullable()
      .test({
        test: (value) => {
          if (!value || !value.length) return true;

          return value
            .filter((file) => file instanceof File)
            .every((file) => file.size <= 1073741824);
        },
        message: 'Nenhum arquivo pode ser maior que 1GB',
      }),
    startIn: yup.string().nullable().required('A liberação da aula é obrigatória'),
    startDays: yup
      .number()
      .nullable()
      .when('startIn', {
        is: 'after_days',
        then: yup
          .number()
          .transform((value, originalValue) => (originalValue !== '' ? +originalValue : undefined))
          .required('A quantidade de dias é obrigatória')
          .positive('A quantidade de dias deve ser maior que zero')
          .integer('A quantidade de dias deve ser um número inteiro'),
        otherwise: yup
          .number()
          .transform((value, originalValue) => (originalValue !== '' ? +originalValue : undefined))
          .nullable(),
      }),
    startDate: yup.date().when('startIn', {
      is: 'fix_date',
      then: yup
        .date()
        .nullable()
        .transform((value, originalValue) => (originalValue === '' ? null : value))
        .required('A data de liberação é obrigatória'),
      otherwise: yup
        .date()
        .nullable()
        .transform((value, originalValue) => (originalValue === '' ? null : value))
        .nullable(),
    }),
  });

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: ProductLessonDefaultValues,
  });

  const type = currentLesson?.type || ProductModuleLessonTypes?.lesson?.id;

  const { dirtyFields } = form.formState;

  const { dirties } = useDirtyFieldsCount({
    dirtyFields,
  });

  const onSubmit = {
    [FormScopes.CREATE]: (values) => {
      create({
        ...values,
        thumbnail: form.watch('thumbnail'),
      });
    },
    [FormScopes.EDIT]: (values) => {
      update({
        ...values,
        thumbnail: form.watch('thumbnail'),
      });
    },
  };

  useEffect(() => {
    form.reset({
      ...formValues,
      ...currentLesson,
    });

    const isYoutubeVideo = currentLesson?.video?.includes('youtube');
    form.setValue('youtube', isYoutubeVideo);

    if (isYoutubeVideo) {
      form.setValue('youtubeUrl', currentLesson?.video);
    } else {
      form.setValue('video', currentLesson?.video);
    }
    form.setValue('startIn', currentLesson?.startIn || 'immediately');
    setLeave(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scope, currentLesson]);

  return (
    <FormProvider id="lesson-form" methods={form}>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => {
          if (dirties && isUploadingVideo) {
            setLeave({
              isOpen: true,
              message:
                'Você possui um upload de vídeo em andamento e alterações não salvas. Deseja sair mesmo assim?',
            });
            return;
          }

          if (isUploadingVideo) {
            setLeave({
              isOpen: true,
              message: 'Você possui um upload de vídeo em andamento. Deseja sair mesmo assim?',
            });
            return;
          }

          if (dirties) {
            setLeave({
              isOpen: true,
              message: 'Você possui alterações não salvas. Deseja sair mesmo assim?',
            });
            return;
          }

          onClose();
        }}
        PaperProps={{
          sx: {
            width: {
              xs: '100%',
              sm: 600,
            },
          },
          position: 'relative',
        }}
      >
        <Scrollbar>
          <Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              position="sticky"
              top={0}
              zIndex={1}
              p={2}
              gap={2}
              component={Card}
              flexWrap={{
                xs: 'wrap',
                sm: 'nowrap',
              }}
            >
              <ListItem>
                <ListItemIcon>
                  <Iconify icon={ProductModuleLessonTypes.quiz.icon} width={20} height={20} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" fontWeight="thin">
                      {scope === FormScopes.CREATE ? 'Criar' : 'Editar'}{' '}
                      {ProductModuleLessonTypes[type].name}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="caption" fontWeight="thin" color="text.secondary">
                      {type === ProductModuleLessonTypes.quiz.id
                        ? 'Crie um quiz para avaliar o conhecimento dos alunos'
                        : 'Configure o conteúdo da aula'}
                    </Typography>
                  }
                />
              </ListItem>
              <Badge badgeContent={dirties} color="warning">
                <Badge badgeContent={dirties} hidden={scope === FormScopes.CREATE} color="warning">
                  <LoadingButton
                    variant="contained"
                    onClick={form.handleSubmit(onSubmit[scope])}
                    loading={creating || updating}
                  >
                    {scope === FormScopes.CREATE ? 'Adicionar' : 'Salvar alterações'}
                  </LoadingButton>
                </Badge>
              </Badge>
              <IconButton size="small" onClick={onClose}>
                <Iconify icon="mdi:close" width={20} height={20} />
              </IconButton>
            </Stack>

            <ConfirmLeave
              isOpen={leave.isOpen}
              onConfirm={onClose}
              onCancel={() =>
                setLeave({
                  isOpen: false,
                  message: '',
                })
              }
              message={leave.message}
            />

            <Stack gap={2} p={2}>
              {type === ProductModuleLessonTypes.quiz.id && (
                <Stack component={Paper} p={2} variant="outlined" gap={3}>
                  <Stack gap={1}>
                    <Typography variant="subtitle1">Detalhes</Typography>
                  </Stack>
                  <ProductQuizDetailsForm />
                </Stack>
              )}

              {type === ProductModuleLessonTypes.lesson.id && (
                <Stack component={Paper} p={2} variant="outlined" gap={3}>
                  <Stack gap={1}>
                    <Typography variant="subtitle1">Detalhes</Typography>
                  </Stack>
                  {isLoadingCurrentLesson ? (
                    <Stack gap={2}>
                      <Skeleton variant="rounded" w height={40} />
                      <Skeleton variant="rounded" w height={100} />
                      <Skeleton variant="rounded" w height={100} />
                    </Stack>
                  ) : (
                    <ProductLessonDetailsForm setIsUploadingVideo={setIsUploadingVideo} />
                  )}
                </Stack>
              )}

              {type === ProductModuleLessonTypes.lesson.id && (
                <Stack component={Paper} p={2} variant="outlined" gap={3}>
                  <Stack gap={1}>
                    <Typography variant="subtitle1">Editor de conteúdo</Typography>
                  </Stack>
                  {isLoadingCurrentLesson ? (
                    <Skeleton variant="rounded" w height={200} />
                  ) : (
                    <ProductLessonContentForm />
                  )}
                </Stack>
              )}

              {/* {type === ProductModuleLessonTypes.lesson.id && (
                <Stack component={Paper} p={2} variant="outlined" gap={3}>
                  <Stack gap={1}>
                    <Typography variant="subtitle1">Anexos</Typography>
                  </Stack>
                  <ProductLessonAttachmentsForm />
                </Stack>
              )} */}

              {/* {type === ProductModuleLessonTypes.quiz.id && (
                <Stack component={Paper} p={2} variant="outlined" gap={3}>
                  <Stack gap={1}>
                    <Typography variant="subtitle1">Perguntas</Typography>
                  </Stack>
                  <ProductQuizQuestionsForm />
                </Stack>
              )} */}

              {/* {type === ProductModuleLessonTypes.quiz.id && (
                <Stack component={Paper} p={2} variant="outlined" gap={3}>
                  <Stack gap={1}>
                    <Typography variant="subtitle1">Configurações</Typography>
                  </Stack>
                  <ProductQuizSettingsForm />
                </Stack>
              )}

              <Stack component={Paper} p={2} variant="outlined" gap={3}>
                <Stack gap={1}>
                  <Typography variant="subtitle1">Liberação</Typography>
                </Stack>
                <ProductLessonReleaseForm />
              </Stack> */}
            </Stack>

            <Stack
              direction="row"
              justifyContent="end"
              position="sticky"
              bottom={0}
              zIndex={1}
              p={2}
              gap={2}
              component={Card}
            >
              <Badge badgeContent={dirties} hidden={scope === FormScopes.CREATE} color="warning">
                <LoadingButton
                  variant="contained"
                  onClick={form.handleSubmit(onSubmit[scope])}
                  loading={creating || updating}
                >
                  {scope === FormScopes.CREATE ? 'Adicionar' : 'Salvar alterações'}
                </LoadingButton>
              </Badge>
              <IconButton size="small" onClick={onClose}>
                <Iconify icon="mdi:close" width={20} height={20} />
              </IconButton>
            </Stack>
          </Stack>
        </Scrollbar>
      </Drawer>
    </FormProvider>
  );
};

ProductLessonForm.propTypes = {
  scope: PropTypes.oneOf(Object.values(FormScopes)),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ProductLessonForm;

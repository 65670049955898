import { useMutation, useQuery } from '@tanstack/react-query';
import { subMonths } from 'date-fns';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { createContext, useMemo, useState } from 'react';
import useTableFilter from '../hooks/useTableFilter';
import { exportReport, getCoProductionReport } from '../services/reports';
import { fDate } from '../utils/formatTime';

export const ReportCoProductionsContext = createContext({
  table: {},
  coProductionReport: [],
  countCoProduction: 1,
  setType: () => {},
  dates: {},
  setDates: () => {},
  mutateExport: () => {},
  loadingExport: false,
  fetchingCoProduction: false,
});

const ReportCoProductionsProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [type, setType] = useState('received');
  const [unformattedDates, setDates] = useState({
    startDate: subMonths(new Date(), 1),
    endDate: new Date(),
  });

  const dates = {
    startDate: fDate(unformattedDates?.startDate || new Date(), 'dd-MM-yyyy'),
    endDate: fDate(unformattedDates?.endDate || new Date(), 'dd-MM-yyyy'),
  };

  const table = useTableFilter({
    defaultCurrentPage: 1,
  });

  const {
    data: { results: coProductionReport = [], count: countCoProduction },
    isLoading: fetchingCoProduction,
  } = useQuery({
    queryKey: ['reports/coproduction', table.page, type, dates],
    queryFn: () =>
      getCoProductionReport({
        page: table.page,
        type,
        dates,
      }),
    initialData: {
      results: [],
      count: 0,
    },
  });

  const { mutateAsync: mutateExport, isLoading: loadingExport } = useMutation({
    async mutationFn(args) {
      const { extension } = args;
      await exportReport({
        report: 'coproduction',
        extension,
        ...dates,
        type: args.type,
      });
    },
    onSuccess() {
      enqueueSnackbar('Exportação realizada com sucesso', { variant: 'success' });
    },
  });

  const value = useMemo(
    () => ({
      coProductionReport,
      countCoProduction,
      fetchingCoProduction,
      table,
      type,
      setType,
      dates: unformattedDates,
      setDates,
      mutateExport,
      loadingExport,
    }),
    [
      coProductionReport,
      countCoProduction,
      fetchingCoProduction,
      table,
      type,
      setDates,
      unformattedDates,
      mutateExport,
      loadingExport,
    ]
  );

  return (
    <ReportCoProductionsContext.Provider value={value}>
      {children}
    </ReportCoProductionsContext.Provider>
  );
};

ReportCoProductionsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ReportCoProductionsProvider;

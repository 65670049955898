export default function TelegramIcon(props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_4534_7510)">
        <rect width="40" height="40" rx="20" fill="#2AABEE" />
        <g clipPath="url(#clip1_4534_7510)">
          <path
            d="M20 0C14.6969 0 9.60625 2.10844 5.85938 5.85781C2.10866 9.6087 0.00107328 14.6956 0 20C0 25.3022 2.10938 30.3928 5.85938 34.1422C9.60625 37.8916 14.6969 40 20 40C25.3031 40 30.3937 37.8916 34.1406 34.1422C37.8906 30.3928 40 25.3022 40 20C40 14.6978 37.8906 9.60719 34.1406 5.85781C30.3937 2.10844 25.3031 0 20 0Z"
            fill="url(#paint0_linear_4534_7510)"
          />
          <path
            d="M9.05315 19.7887C14.8844 17.2487 18.7719 15.5741 20.7157 14.7649C26.2719 12.4546 27.425 12.0534 28.1782 12.0398C28.3438 12.0371 28.7125 12.0781 28.9532 12.2726C29.1532 12.4367 29.2094 12.6585 29.2375 12.8143C29.2625 12.9699 29.2969 13.3246 29.2688 13.6015C28.9688 16.764 27.6657 24.4384 27.0032 27.9806C26.725 29.4793 26.1719 29.9818 25.6375 30.0309C24.475 30.1378 23.5938 29.2634 22.4688 28.5262C20.7094 27.3721 19.7157 26.654 18.0063 25.5281C16.0313 24.2268 17.3125 23.5115 18.4375 22.3428C18.7313 22.0368 23.85 17.3821 23.9469 16.9599C23.9594 16.9071 23.9719 16.7103 23.8532 16.6065C23.7375 16.5024 23.5657 16.5381 23.4407 16.5662C23.2625 16.6062 20.4532 18.4649 15.0032 22.1421C14.2063 22.6903 13.4844 22.9574 12.8344 22.9434C12.1219 22.9281 10.7469 22.5396 9.72503 22.2078C8.47503 21.8006 7.47815 21.5853 7.56565 20.8937C7.6094 20.5337 8.10628 20.1653 9.05315 19.7887Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_4534_7510"
          x1="2000"
          y1="0"
          x2="2000"
          y2="4000"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2AABEE" />
          <stop offset="1" stopColor="#229ED9" />
        </linearGradient>
        <clipPath id="clip0_4534_7510">
          <rect width="40" height="40" rx="20" fill="white" />
        </clipPath>
        <clipPath id="clip1_4534_7510">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

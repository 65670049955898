import PropTypes from 'prop-types';
import { Alert, AlertTitle } from '@mui/material';

function ProductWarning({ title, message, severity = 'error' }) {
    return (
        <Alert
            severity={severity}
            style={{ marginBottom: 20, position: 'sticky', top: 0, zIndex: 9999 }}
        >
            <AlertTitle>{title}</AlertTitle>
            {message}
            {console.log(title, message)}
        </Alert>
    );
}

ProductWarning.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    severity: PropTypes.string,
};

export default ProductWarning;

import { useQuery, useQueryClient, useMutation, useInfiniteQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import { createContext, useMemo, useState } from 'react';
import useTableFilter from '../hooks/useTableFilter';
import { FormScopes } from '../utils/form';
import { STUDENTS_LIMIT } from '../consts/productStudents';
import {
  getProductStudentsService,
  addProductStudentAccessService,
  removeProductStudentAccessService,
} from '../services/product-students';

export const ProductStudentDefaultFilter = {
  lastEmail: '',
};

export const ProductStudentsContext = createContext({
  table: {},
  students: [],
  fetching: false,
  count: 0,
  classes: [],
  fetchingClasses: false,
  scope: FormScopes.INDEX,
  setScope: () => { },
  formValues: {},
  setFormValues: () => { },
  addProductStudentAccess: () => { },
  isLoadingAddProductStudentAccess: false,
  increaseStudentsLimit: () => { },
  status: '',
});

const ProductStudentsProvider = ({ children }) => {
  const table = useTableFilter({
    defaultCurrentPage: 1,
    defaultFilter: {},
  });

  const [scope, setScope] = useState(FormScopes.INDEX);

  const [formValues, setFormValues] = useState({});

  const { id } = useParams();

  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const {
    data: studentsData,
    fetchNextPage: increaseStudentsLimit,
    isFetching: fetching,
    status,
  } = useInfiniteQuery({
    queryKey: ['students', table.search, table.filter],
    queryFn: () =>
      getProductStudentsService({
        id,
        limit: STUDENTS_LIMIT,
        search: table.search,
        filters: table.filter,
        lastEmail:
          studentsData?.pages[studentsData.pages.length - 1]?.results[STUDENTS_LIMIT - 1]?.email,
      }),
    getNextPageParam: (lastPage, pages) => {
      const totalItems = pages?.reduce((acc, { results }) => {
        acc.push(...results);

        return acc;
      }, []);

      if (totalItems?.length >= lastPage?.count) return undefined;

      if (lastPage) {
        return lastPage;
      }

      return undefined;
    },
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 10,
  });

  const students = useMemo(
    () =>
      studentsData?.pages?.reduce((acc, { results }) => {
        acc.push(...results);

        return acc;
      }, []),
    [studentsData]
  );

  const count = useMemo(() => studentsData?.pages[0].count, [studentsData]);

  const { data: classes, isFetching: fetchingClasses } = useQuery({
    queryKey: ['classes'],
    queryFn: () =>
      new Promise((resolve) =>
        setTimeout(
          () =>
            resolve([
              { id: 1, name: 'Turma A', students: 10, default: true },
              { id: 2, name: 'Turma B', students: 5, default: false },
              { id: 3, name: 'Turma C', students: 0, default: false },
            ]),
          1000
        )
      ),
    initialData: [],
  });

  const { mutate: addProductStudentAccess, isLoading: isLoadingAddProductStudentAccess } =
    useMutation({
      mutationFn: ({ ...values }) => addProductStudentAccessService({ id, ...values }),
      async onMutate() {
        await queryClient.cancelQueries(['students', table.search, table.filter]);
        const previous = await queryClient.getQueryData(['students', table.search, table.filter]);
        const newData = {
          ...previous,
          pages: previous.pages.map((item) => ({
            ...item,
            count: item.count + 1,
          })),
        };

        queryClient.setQueryData(['students', table.search, table.filter], newData);
      },
      onSuccess({ detail }) {
        queryClient.invalidateQueries(['students', table.search, table.filter]);
        setScope(FormScopes.INDEX);
        enqueueSnackbar(detail, { variant: 'success' });
      },
    });

  const { mutate: removeProductStudendAccess, isLoading: isLoadingRemoveProductStudentAccess } =
    useMutation({
      mutationFn: ({ ...values }) => removeProductStudentAccessService({ id, ...values }),
      async onMutate() {
        await queryClient.cancelQueries(['students', table.search, table.filter]);
        const previous = await queryClient.getQueryData(['students', table.search, table.filter]);
        const newData = {
          ...previous,
          pages: previous.pages.map((item) => ({
            ...item,
            count: item.count + 1,
          })),
        };

        queryClient.setQueryData(['students', table.search, table.filter], newData);
      },
      onSuccess({ detail }) {
        queryClient.invalidateQueries(['students', table.search, table.filter]);
        setScope(FormScopes.INDEX);
        enqueueSnackbar(detail, { variant: 'success' });
      },
      onError(error) {
        if (error?.response?.status === 400 && error?.response?.data?.detail) {
          enqueueSnackbar(error?.response?.data?.detail, { variant: 'error' });
        }
      }
    });

  const value = useMemo(
    () => ({
      table,
      students,
      count,
      fetching,
      scope,
      setScope,
      formValues,
      setFormValues,
      classes,
      fetchingClasses,
      addProductStudentAccess,
      removeProductStudendAccess,
      isLoadingAddProductStudentAccess,
      isLoadingRemoveProductStudentAccess,
      increaseStudentsLimit,
      status,
    }),
    [
      table,
      students,
      count,
      fetching,
      scope,
      formValues,
      classes,
      fetchingClasses,
      addProductStudentAccess,
      removeProductStudendAccess,
      isLoadingAddProductStudentAccess,
      isLoadingRemoveProductStudentAccess,
      increaseStudentsLimit,
      status,
    ]
  );

  return (
    <ProductStudentsContext.Provider value={value}>{children}</ProductStudentsContext.Provider>
  );
};

ProductStudentsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProductStudentsProvider;

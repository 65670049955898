import { Skeleton } from '@mui/material';
import PropTypes from 'prop-types';

const ConditionSkeleton = ({ loading, children }) =>
  loading ? (
    <Skeleton variant="text" animation="wave">
      {children}
    </Skeleton>
  ) : (
    children
  );

ConditionSkeleton.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
};

export default ConditionSkeleton;

import { Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { RHFEditor, RHFTextField, RHFUpload } from '../../../components/hook-form';

const ProductQuizDetailsForm = () => {
  const { setValue } = useFormContext();

  const handleDropFile = useCallback(
    ([file]) => {
      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (newFile) {
        setValue('thumbnail', newFile, { shouldValidate: true, shouldDirty: true });
      }
    },
    [setValue]
  );

  return (
    <Stack gap={2}>
      <RHFTextField
        name="title"
        label="Título"
        size="small"
        placeholder="Título do quiz"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <RHFEditor name="description" />
      <Box>
        <Typography variant="caption" color="text.secondary">
          Thumbnail
        </Typography>
        <RHFUpload
          name="thumbnail"
          maxSize={10000000}
          onDrop={handleDropFile}
          onRemove={() => setValue('thumbnail', null, { shouldValidate: true, shouldDirty: true })}
          helperText="Formatos aceitos: JPG ou PNG. Tamanho máximo: 10MB"
          accept={{ 'image/jpeg': [], 'image/png': [] }}
        />
      </Box>
    </Stack>
  );
};

export default ProductQuizDetailsForm;

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { createContext, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import useTableFilter from '../hooks/useTableFilter';
import { getCourseById } from '../services/members/courses';
import {
  createCourseModule,
  deleteCourseModule,
  reorderCourseModules,
  updateCourseModule,
} from '../services/members/modules';
import { FormScopes } from '../utils/form';
import uuidv4 from '../utils/uuidv4';

export const ProductModuleHeaderTypes = {
  image: {
    id: 'image',
    name: 'Imagem',
  },
  slide: {
    id: 'slide',
    name: 'Várias Imagens (Slideshow)',
  },
  video: {
    id: 'video',
    name: 'Vídeo',
  },
};

export const ProductModulesContext = createContext({
  table: {},
  formValues: {},
  modules: [],
  fetching: false,
  classes: [],
  fetchingClasses: false,
  setFormValues: () => {},
  create: () => {},
  creating: false,
  reorder: () => {},
  reordering: false,
  update: () => {},
  updating: false,
  remove: () => {},
  removing: false,
  scope: FormScopes.INDEX,
  setScope: () => {},
  expanded: [],
  setExpanded: () => {},
  syncing: false,
  course: [],
  isError: false,
});

const ProductModulesProvider = ({ children }) => {
  const table = useTableFilter({
    defaultCurrentPage: 1,
  });

  const [scope, setScope] = useState(FormScopes.INDEX);

  const [formValues, setFormValues] = useState({});

  const { enqueueSnackbar } = useSnackbar();

  const [expanded, setExpanded] = useState([]);

  const queryClient = useQueryClient();

  const { id: courseId } = useParams();

  const {
    data: { modules, ...course } = {
      modules: [],
      name: '',
      cover: '',
    },
    isFetching: fetching,
    isError,
  } = useQuery({
    queryKey: ['course', { courseId }],
    queryFn: () => getCourseById({ courseId }),
    initialData: {
      name: '',
      cover: '',
      modules: [],
    },
  });

  const { data: classes, isFetching: fetchingClasses } = useQuery({
    queryKey: ['classes'],
    queryFn: () =>
      new Promise((resolve) =>
        setTimeout(
          () =>
            resolve([
              { id: 1, name: 'Turma 1' },
              { id: 2, name: 'Turma 2' },
              { id: 3, name: 'Turma 3' },
            ]),
          1000
        )
      ),
    initialData: [],
  });

  const { mutateAsync: create, isLoading: creating } = useMutation({
    mutationFn: (data) => createCourseModule({ courseId, ...data }),
    onMutate: async (data) => {
      const previous = queryClient.getQueryData(['course', { courseId }]);

      data = Object.assign(data, {
        id: uuidv4(),
        lessons: [],
        classes: [],
        order: previous.modules.length + 1,
      });

      queryClient.setQueryData(['course', { courseId }], () => ({
        ...previous,
        modules: [...previous.modules, data],
      }));

      return { previous };
    },
    onSuccess: () => {
      enqueueSnackbar('Módulo adicionado com sucesso!', {
        variant: 'success',
      });

      setScope(FormScopes.INDEX);

      queryClient.invalidateQueries(['course', { courseId }]);
    },
    onError: (data, newData, context) => {
      enqueueSnackbar('Não foi possível adicionar o Módulo!', {
        variant: 'error',
      });

      queryClient.setQueryData(['course', { courseId }], context.previous);
    },
  });

  const { mutateAsync: update, isLoading: updating } = useMutation({
    mutationFn: (data) => updateCourseModule({ courseId, ...data }),
    async onMutate(data) {
      const queryKey = ['course', { courseId }];
      await queryClient.cancelQueries({ queryKey });

      const previousData = queryClient.getQueryData(queryKey);

      const { name, coverImage } = data;

      const newData = {
        ...previousData,
        modules: previousData.modules.map((module) => {
          if (module.id === data.id) {
            return {
              ...module,
              name,
              coverImage,
            };
          }

          return module;
        }),
      };

      queryClient.setQueryData(queryKey, newData);

      return { previousData };
    },
    onSuccess: () => {
      setScope(FormScopes.INDEX);

      enqueueSnackbar('Módulo atualizado com sucesso!', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('Não foi possível atualizar o Módulo!', {
        variant: 'error',
      });
      queryClient.invalidateQueries(['course', { courseId }]);
    },
  });

  const { mutateAsync: reorder, isLoading: reordering } = useMutation({
    mutationFn: ({ ordered }) => reorderCourseModules({ courseId, modules: ordered }),
    onMutate: async ({ ordered }) => {
      const previous = queryClient.getQueryData(['course', { courseId }]);

      queryClient.setQueryData(['course', { courseId }], () => ({
        ...previous,
        modules: ordered,
      }));

      return { previous };
    },
    onError: (err, data, context) => {
      enqueueSnackbar('Não foi possível reordenar!', {
        variant: 'error',
      });

      queryClient.setQueryData(['course', { courseId }], context.previous);

      setScope(FormScopes.INDEX);
    },
  });

  const { mutateAsync: remove, isLoading: removing } = useMutation({
    mutationFn: ({ ids }) => deleteCourseModule({ courseId, ids }),
    onMutate: async ({ ids }) => {
      const previous = queryClient.getQueryData(['course', { courseId }]);

      const filter = (m) => m.filter(({ id }) => !ids.includes(id));

      queryClient.setQueryData(['course', { courseId }], () => ({
        ...previous,
        modules: filter(previous.modules),
      }));

      return { previous };
    },
    onSuccess: () => {
      enqueueSnackbar('Módulo removido com sucesso!', {
        variant: 'success',
      });

      setScope(FormScopes.INDEX);
    },
    onError: (data, newData, context) => {
      enqueueSnackbar('Não foi possível remover o Módulo!', {
        variant: 'error',
      });

      queryClient.setQueryData(['course', { courseId }], context.previous);
    },
  });

  const syncing = creating || updating || removing || reordering;

  const value = useMemo(
    () => ({
      classes,
      fetchingClasses,
      table,
      create,
      creating,
      update,
      updating,
      remove,
      removing,
      reorder,
      reordering,
      scope,
      setScope,
      formValues,
      setFormValues,
      expanded,
      setExpanded,
      syncing,
      modules,
      fetching,
      course,
      isError,
    }),
    [
      classes,
      fetchingClasses,
      table,
      create,
      creating,
      update,
      updating,
      remove,
      removing,
      reorder,
      reordering,
      scope,
      formValues,
      expanded,
      syncing,
      modules,
      fetching,
      course,
      isError,
    ]
  );

  return <ProductModulesContext.Provider value={value}>{children}</ProductModulesContext.Provider>;
};

ProductModulesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProductModulesProvider;

import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';

const RHFDatePicker = ({ name, helperText, ...other }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={other?.defaultValue}
      render={({ field, fieldState: { error } }) => (
        <DatePicker
          {...field}
          inputFormat="dd/MM/yyyy"
          renderInput={(params) => (
            <TextField
              fullWidth
              {...params}
              error={!!error}
              helperText={error?.message || helperText}
              {...other}
            />
          )}
        />
      )}
    />
  );
};

RHFDatePicker.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.node,
};

export default RHFDatePicker;

import {
  AppBar,
  CircularProgress,
  Container,
  InputAdornment,
  TextField,
  Toolbar,
  Grid,
} from '@mui/material';
import { Stack } from '@mui/system';
import { useContext, useMemo } from 'react';
import Iconify from '@/components/iconify';
import LogoIcon from '@/components/logo/LogoIcon';
import { CheckoutBuilderContext } from '@/contexts/CheckoutBuilderContext';
import useResponsive from '@/hooks/useResponsive';

export default function Header() {
  const { checkout, loading } = useContext(CheckoutBuilderContext);

  const name = useMemo(() => {
    if (checkout) {
      return checkout.name || 'Sem título';
    }
    if (loading) {
      return 'Buscando checkout...';
    }
    return 'Checkout não encontrado';
  }, [checkout, loading]);

  const isMobile = useResponsive('down', 'sm');

  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      id="checkout-builder-header"
    >
      <Toolbar
        disableGutters
        sx={{
          backgroundColor: (theme) => theme.palette.background.paper,
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Grid direction="row" width="100%">
          <Container
            sx={{
              height: 1,
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              justifyContent: 'space-around',
            }}
            maxWidth="full"
          >
            <LogoIcon />

            <TextField
              size="small"
              InputProps={{
                readOnly: true,
                hiddenLabel: true,
                ...(loading && {
                  endAdornment: (
                    <InputAdornment position="end">
                      <CircularProgress size={16} />
                    </InputAdornment>
                  ),
                }),
                ...(!loading &&
                  !checkout && {
                    endAdornment: (
                      <InputAdornment position="end">
                        <Iconify icon="eva:alert-circle-fill" color="error.main" />
                      </InputAdornment>
                    ),
                  }),
              }}
              value={name}
              variant="filled"
            />
            <Stack
              direction="row"
              gap={4}
              alignItems="center"
              justifyContent="space-between"
              width={isMobile ? undefined : '100%'}
            >
              <Stack id="checkout-builder-header-actions" direction="row" alignItems="center" />
              {!isMobile && (
                <Stack id="checkout-builder-header-buttons" direction="row" alignItems="center" />
              )}
            </Stack>
          </Container>

          {isMobile && (
            <Container
              sx={{ height: 1, display: 'flex', alignItems: 'center', gap: 2, paddingBottom: 2 }}
              maxWidth="full"
            >
              <Stack id="checkout-builder-header-buttons" direction="row" alignItems="center" />
            </Container>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

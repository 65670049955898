import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Link } from '@mui/material';
import { Box } from '@mui/system';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, compact = false, sx, ...other }, ref) => {
  const logo = (
    <Box
      sx={{
        width: 200,
        height: 64,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& img': {
          marginLeft: -3,
        },
        ...sx,
      }}
    >
      <picture>
        <img
          srcSet="/logo/green-text-logo-transparent-background.png"
          alt="logo"
          style={{
            objectFit: 'cover',
            maxWidth: '100%',
          }}
        />
      </picture>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} to="/dashboard/home" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
  compact: PropTypes.bool,
};

export default Logo;

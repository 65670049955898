import PropTypes from 'prop-types';
// @mui
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { RHFSwitch, RHFTextField } from '../../../components/hook-form';
import FormProvider from '../../../components/hook-form/FormProvider';
import Iconify from '../../../components/iconify';
import { ProductClassesContext } from '../../../contexts/ProductClassesContext';

ProductClassForm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default function ProductClassForm({ open, onClose, onSubmit }) {
  const { formValues } = useContext(ProductClassesContext);

  const schema = yup.object().shape({
    name: yup.string().required('Nome é obrigatório'),
    default: yup.boolean(),
  });

  const defaultValues = {
    name: formValues?.name || '',
    default: formValues?.default || false,
  };

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => {
        form.reset();
        onClose();
      }}
    >
      <FormProvider methods={form} onSubmit={form.handleSubmit(onSubmit)}>
        <Stack p={2} gap={2}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <ListItem>
              <ListItemIcon>
                <Iconify icon="mdi:account-group" width={20} height={20} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body2" fontWeight="thin">
                    Turma
                  </Typography>
                }
                secondary={
                  <Typography variant="caption" fontWeight="thin" color="text.secondary">
                    Gerencie as turmas do seu produto
                  </Typography>
                }
              />
            </ListItem>
            <IconButton size="small" onClick={onClose}>
              <Iconify icon="mdi:close" width={20} height={20} />
            </IconButton>
          </Stack>

          <RHFTextField
            name="name"
            label="Nome da Turma"
            placeholder="Ex.: Turma A"
            fullWidth
            size="small"
          />

          <RHFSwitch
            name="default"
            label={formValues?.default ? 'Essa é a turma padrão' : 'Definir esta turma como padrão'}
            disabled={formValues?.default}
          />

          <Stack spacing={1} direction="row" justifyContent="end">
            <Button variant="outlined" onClick={() => onClose()} color="inherit">
              Cancelar
            </Button>
            <LoadingButton variant="contained" type="submit" loading={form.formState.isSubmitting}>
              Salvar
            </LoadingButton>
          </Stack>
        </Stack>
      </FormProvider>
    </Dialog>
  );
}

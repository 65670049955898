import { Button, Stack } from '@mui/material';
import { useContext } from 'react';
import Iconify from '../../../components/iconify';
import { ProductClassesContext } from '../../../contexts/ProductClassesContext';
import { FormScopes } from '../../../utils/form';
import ProductClassForm from './ProductClassForm';
import ProductStudentsTable from './ProductClassesTable';

const ProductClassesPanel = () => {
  const { scope, setScope, setFormValues } = useContext(ProductClassesContext);
  return (
    <>
      <ProductClassForm
        open={[FormScopes.CREATE, FormScopes.EDIT].includes(scope)}
        onClose={() => {
          setScope(FormScopes.INDEX);
        }}
        onSubmit={() => {
          setScope(FormScopes.INDEX);
        }}
      />
      <Stack gap={2}>
        <Stack
          direction={{
            xs: 'column',
            sm: 'row',
          }}
          justifyContent="flex-end"
          alignItems="center"
          gap={1}
        >
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-outline" />}
            color="primary"
            onClick={(e) => {
              setFormValues({});
              setScope(FormScopes.CREATE);
            }}
          >
            Adicionar turma
          </Button>
        </Stack>
        <ProductStudentsTable />
      </Stack>
    </>
  );
};

export default ProductClassesPanel;

import {
  Card,
  CardContent,
  CardHeader,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
} from '@mui/material';
import { Stack } from '@mui/system';
import PropTypes from 'prop-types';
import { useContext, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import Slider from 'react-slick';
import Iconify from '../../../components/iconify';
import Image from '../../../components/image';
import { ProductModulesContext } from '../../../contexts/ProductModulesContext';

const ProductModuleHeaderPreview = ({ device }) => {
  const {
    formValues = {
      lessons: [],
    },
  } = useContext(ProductModulesContext);

  const ratio = {
    desktop: '7 / 9',
    mobile: '9 / 16',
  };

  const sliderSettings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 4000,
    cssEase: 'linear',
    fade: true,
  };

  const form = useFormContext();

  const images = form.watch(`${device}HeaderImages`) || [];

  const classes = useMemo(
    () =>
      formValues.lessons || [
        {
          title: 'Aula 1',
        },
        {
          title: 'Aula 2',
        },
        {
          title: 'Aula 3',
        },
      ],
    [formValues.lessons]
  );

  return (
    <Stack
      sx={{
        aspectRatio: ratio[device],
        height: '450px',
      }}
      component={Card}
      bgcolor="background.default"
    >
      <Stack>
        {images.length > 0 ? (
          <Slider {...sliderSettings}>
            {images.map((image, index) => (
              <Image
                key={index}
                src={image.preview}
                alt={`image ${index}`}
                sx={{
                  objectFit: 'cover',
                  height: '100px',
                }}
              />
            ))}
          </Slider>
        ) : (
          <Image
            src=""
            alt="empty image"
            sx={{
              objectFit: 'cover',
              height: '100px',
            }}
          />
        )}
      </Stack>
      <CardHeader title="Aulas" />
      <CardContent sx={{ pt: 1 }}>
        <Stack>
          {classes.map((item, index) => (
            <ListItemButton key={index} sx={{ mx: -2, borderRadius: 1 }}>
              <ListItemIcon>
                <Stack
                  bgcolor="background.neutral"
                  width={80}
                  component={Paper}
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    aspectRatio: '16 / 9',
                    overflow: 'hidden',
                  }}
                >
                  {item.thumbnail ? (
                    <Image
                      src={item.thumbnail}
                      alt={`thumbnail ${index}`}
                      sx={{
                        objectFit: 'cover',
                      }}
                    />
                  ) : (
                    <Iconify icon="emojione:books" width={32} height={32} />
                  )}
                </Stack>
              </ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItemButton>
          ))}
        </Stack>
      </CardContent>
    </Stack>
  );
};

ProductModuleHeaderPreview.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      preview: PropTypes.string,
    })
  ),
  device: PropTypes.oneOf(['desktop', 'mobile']).isRequired,
};

export default ProductModuleHeaderPreview;

import PropTypes from 'prop-types';
//
import styled from '@emotion/styled';
import { IconButton, Paper } from '@mui/material';
import { Stack, alpha } from '@mui/system';
import { useState } from 'react';
import Iconify from '../../iconify/Iconify';
import Image from '../../image';
import FileCropperDialog from '../cropper/FileCropperDialog';

SingleFilePreview.propTypes = {
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onCrop: PropTypes.func,
  onRemove: PropTypes.func,
  lockCropAspectRatio: PropTypes.number,
  sx: PropTypes.object,
};

const FilledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.grey[900], 0.8),
  color: theme.palette.grey[50],
  '&:hover': {
    backgroundColor: alpha(theme.palette.grey[900], 0.9),
  },
}));

export default function SingleFilePreview({
  file,
  onCrop = () => {},
  onRemove = () => {},
  sx,
  lockCropAspectRatio = null,
  ...props
}) {
  const [cropping, setCropping] = useState(false);

  if (!file) {
    return null;
  }

  const imgUrl = typeof file === 'string' ? file : file.preview;

  return (
    <>
      <Stack
        component={Paper}
        variant="outlined"
        sx={{
          position: 'relative',
          borderRadius: 2,
          overflow: 'hidden',
          ...sx,
        }}
        {...props}
      >
        <Image
          alt="file preview"
          src={imgUrl}
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        />
        <Stack
          position="absolute"
          height={1}
          width={1}
          justifyContent="center"
          alignItems="center"
          direction="row"
          gap={1}
        >
          <FilledIconButton onClick={() => setCropping(true)}>
            <Iconify icon="eva:crop-fill" width={20} height={20} />
          </FilledIconButton>
          <FilledIconButton onClick={() => onRemove(file)}>
            <Iconify icon="eva:trash-2-fill" width={20} height={20} />
          </FilledIconButton>
        </Stack>
      </Stack>
      <FileCropperDialog
        src={imgUrl}
        open={cropping}
        onClose={() => setCropping(false)}
        onCrop={onCrop}
        {...(lockCropAspectRatio && { lockAspectRatio: lockCropAspectRatio })}
      />
    </>
  );
}

import { addDays } from 'date-fns';
import { fDate } from '../../../utils/formatTime';

export function getAccessDisplay(acesso) {
  if (!acesso) return '-';

  if (acesso === 'lifetime') return 'Vitalício';

  return fDate(addDays(new Date(acesso), 1), 'dd/MM/yyyy');
}

import { yupResolver } from '@hookform/resolvers/yup';
import { ListItem, ListItemIcon, Paper, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { RHFSwitch, RHFTextField, RHFUploadAvatar } from '../../../components/hook-form';
import FormProvider from '../../../components/hook-form/FormProvider';
import EditProductBlock from '../products/EditProductBlock';

export default function ProductCourseSettingsForm() {
  const schema = yup.object().shape({
    enableComments: yup.boolean(),
    approveCommentsAutomatically: yup.boolean(),
    shareCommentsBetweenClasses: yup.boolean(),
    moderatorAvatar: yup.mixed(),
    moderatorName: yup.string(),
    certificateEnabled: yup.boolean(),
    watermarkEnabled: yup.boolean(),
    blockCopyAndPaste: yup.boolean(),
    blockPrint: yup.boolean(),
    protectPassword: yup.boolean(),
  });

  const defaultValues = {
    enableComments: false,
    approveCommentsAutomatically: false,
    shareCommentsBetweenClasses: false,
    moderatorAvatar: {
      preview: '/assets/icons/navbar/ic_user_rounded.svg',
    },
    moderatorName: '',
    certificateEnabled: false,
    watermarkEnabled: false,
    blockCopyAndPaste: false,
    blockPrint: false,
    protectPassword: false,
  };

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  return (
    <FormProvider id="edit-product" methods={form} onSubmit={console.log}>
      <Stack gap={5}>
        <EditProductBlock title="Comentários" description="Aprenda mais sobre os comentários">
          <RHFSwitch name="enableComments" label="Habilitar comentários" />
          {form.watch('enableComments') && (
            <>
              <RHFSwitch
                name="approveCommentsAutomatically"
                label="Aprovar comentários automaticamente"
              />
              <RHFSwitch
                name="shareCommentsBetweenClasses"
                label="Alunos podem ver comentários de outras turmas"
              />
              <Stack component={Paper} sx={{ p: 2, mt: 2 }} variant="outlined">
                <Typography variant="subtitle2" color="textSecondary" sx={{ mb: 2 }}>
                  Responder comentários como
                </Typography>
                <ListItem>
                  <ListItemIcon>
                    <RHFUploadAvatar
                      name="moderatorAvatar"
                      label="Avatar"
                      accept="image/*"
                      sx={{
                        width: 85,
                        height: 85,
                      }}
                    />
                  </ListItemIcon>
                  <RHFTextField
                    name="moderatorName"
                    placeholder="Nome do moderador"
                    size="small"
                    fullWidth
                  />
                </ListItem>
              </Stack>
            </>
          )}
        </EditProductBlock>

        <EditProductBlock title="Certificado" description="Aprenda mais sobre os certificados">
          <RHFSwitch name="certificateEnabled" label="Ativar certificado de conclusão do curso" />
        </EditProductBlock>

        <EditProductBlock
          title="Proteção anti pirataria para e-books"
          description="Aprenda mais sobre a proteção anti pirataria"
        >
          <RHFSwitch
            name="watermarkEnabled"
            label="Colocar marca d'água com os dados do comprador em todas as páginas"
          />
          {form.watch('watermarkEnabled') && (
            <>
              <RHFSwitch name="blockCopyAndPaste" label="Bloquear CTRL + C" />
              <RHFSwitch name="blockPrint" label="Bloquear opção de imprimir" />
              <RHFSwitch
                name="protectPassword"
                label="Proteger com senha? A senha será o e-mail do comprador"
              />
            </>
          )}
        </EditProductBlock>
      </Stack>
    </FormProvider>
  );
}

import {
  Badge,
  Button,
  Card,
  Checkbox,
  Grid,
  InputAdornment,
  LinearProgress,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import Iconify from '../iconify/Iconify';
import Scrollbar from '../scrollbar/Scrollbar';
import TableHeadCustom from './TableHeadCustom';
import TableSelectedAction from './TableSelectedAction';

const SearchableTable = ({
  size = 'medium',
  table,
  count,
  rows: rawRows,
  labels,
  fetching,
  infiniteLoading = false,
  selectable = false,
  searchable = true,
  filterable = false,
  pageable = true,
  scrollable = true,
  inMemoryFilter = null,
  renderRow,
  onRowClick,
  Actions,
  ...props
}) => {
  const renderScrollable = (children) => {
    if (scrollable) {
      return <Scrollbar>{children}</Scrollbar>;
    }
    return children;
  };

  const rows = useMemo(() => rawRows || [], [rawRows]);

  const filtered = useMemo(() => {
    if (inMemoryFilter) {
      return rows.filter(inMemoryFilter);
    }

    if (fetching) return [];

    if (!rows) return [];

    return rows;
  }, [rows, inMemoryFilter, fetching]);

  const CPF_REGEX = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
  const CNPJ_REGEX = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;

  const handleCpfCnpj = (value) => {
    if (CPF_REGEX.test(value) || CNPJ_REGEX.test(value)) {
      return value.replace(/\.|-|\//g, '');
    }
    return value;
  };

  return (
    <Card {...props}>
      {(searchable || filterable || Actions) && (
        <Stack
          p={2}
          mb={1}
          direction="row"
          flexWrap="wrap"
          alignItems="center"
          justifyContent="space-between"
          gap={1}
        >
          {searchable && (
            <TextField
              sx={{
                flex: {
                  xs: 1,
                  sm: 'unset',
                },
              }}
              size="small"
              placeholder="Pesquisar"
              onChange={(event) => {
                table.onChangePage(1);
                table.page = 1;
                table.setSearch(handleCpfCnpj(event.target.value));
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="ion:search-outline" />
                  </InputAdornment>
                ),
              }}
            />
          )}
          <Stack
            width={{
              xs: 1,
              sm: 'unset',
            }}
            direction={{
              xs: 'column',
              sm: 'row',
            }}
            alignItems={{
              xs: 'stretch',
              sm: 'center',
            }}
            gap={1}
            justifyContent={{
              xs: 'flex-start',
              sm: 'flex-end',
            }}
            sx={{
              '& > *': {
                flex: {
                  xs: 1,
                  sm: 'unset',
                },
              },
            }}
          >
            {Actions}
            {filterable && (
              <Badge badgeContent={table.dirtyFilters} color="primary">
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => table.setShowFilter(true)}
                  startIcon={<Iconify icon="eva:options-2-outline" />}
                  color="inherit"
                >
                  Filtros
                </Button>
              </Badge>
            )}
          </Stack>
        </Stack>
      )}

      <TableContainer sx={{ overflow: 'unset' }}>
        {fetching && <LinearProgress />}
        {selectable && (
          <TableSelectedAction
            dense={table.dense}
            numSelected={table.selected.length}
            rowCount={rows.length}
            onSelectAllRows={(checked) =>
              table.onSelectAllRows(
                checked,
                rows.map((row) => row.id)
              )
            }
          />
        )}
        {renderScrollable(
          <Table sx={{ minWidth: props.minWidth || 720 }} size={size} stickyHeader>
            <TableHeadCustom
              headLabel={labels}
              rowCount={rows?.length}
              numSelected={table.selected.length}
              onSort={table.onSort}
              onSelectAllRows={
                selectable &&
                ((checked) =>
                  table.onSelectAllRows(
                    checked,
                    rows.map((row) => row.id)
                  ))
              }
            />
            <TableBody>
              {filtered.map((row, index) => (
                <TableRow
                  key={row.id}
                  hover
                  role="button"
                  tabIndex={-1}
                  onClick={() => onRowClick && onRowClick(row)}
                  sx={{
                    cursor: onRowClick ? 'pointer' : 'default',
                  }}
                >
                  {selectable && (
                    <TableCell sx={{ pl: 1 }}>
                      <Checkbox
                        checked={table.selected.includes(row.id)}
                        onDoubleClick={() => {}}
                        onClick={(event) => {
                          event.stopPropagation();
                          table.onSelectRow(row.id);
                        }}
                      />
                    </TableCell>
                  )}
                  {renderRow(row, index)}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        {rows.length === 0 && (
          <Stack width={1} alignItems="center" justifyContent="center" p={2}>
            <Typography variant="caption" color="text.secondary">
              {fetching ? 'Carregando...' : 'Nenhum registro encontrado'}
            </Typography>
          </Stack>
        )}
        {infiniteLoading && !fetching && (
          <Stack width={1} alignItems="center" justifyContent="center" p={2}>
            <Typography variant="caption" color="text.secondary">
              Carregando...
            </Typography>
          </Stack>
        )}
      </TableContainer>
      {count > 0 && pageable && (
        <Grid
          paddingX={2}
          paddingY={2}
          alignItems="center"
          justifyContent="center"
          container
          marginTop={1}
        >
          <Pagination
            count={Math.ceil(count / table.rowsPerPage)}
            page={+table.page || 1}
            onChange={table.onChangePage}
          />
        </Grid>
      )}
    </Card>
  );
};

SearchableTable.propTypes = {
  fetching: PropTypes.bool,
  labels: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, label: PropTypes.string })),
  Actions: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  rows: PropTypes.array,
  renderRow: PropTypes.func,
  count: PropTypes.number,
  selectable: PropTypes.bool,
  searchable: PropTypes.bool,
  pageable: PropTypes.bool,
  filterable: PropTypes.bool,
  onRowClick: PropTypes.func,
  minWidth: PropTypes.oneOf([PropTypes.number, 'auto']),
  table: PropTypes.object,
  size: PropTypes.string,
  scrollable: PropTypes.bool,
  inMemoryFilter: PropTypes.func,
  infiniteLoading: PropTypes.bool,
};

export default SearchableTable;

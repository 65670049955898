import {
  Dialog,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  Skeleton,
} from '@mui/material';
import { Dashboard } from '@uppy/react';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar/Scrollbar';
import { BatchVideoUploadContext } from '../../../contexts/BatchVideoUploadContext';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import '@uppy/file-input/dist/style.css';
import '@uppy/progress-bar/dist/style.css';
import '@uppy/status-bar/dist/style.css';

const ProductVideosUploader = ({ open, onClose }) => {
  const { uppy, isLoadingFile } = useContext(BatchVideoUploadContext);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <Scrollbar>
        <Stack p={2} gap={2}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <ListItem>
              <ListItemIcon>
                <Iconify icon="eva:file-text-outline" width={20} height={20} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body2" fontWeight="thin">
                    Upload de vídeos
                  </Typography>
                }
                secondary={
                  <Typography variant="caption" fontWeight="thin" color="text.secondary">
                    Adicione vídeos em massa
                  </Typography>
                }
              />
            </ListItem>
            <IconButton size="small" onClick={onClose}>
              <Iconify icon="mdi:close" width={20} height={20} />
            </IconButton>
          </Stack>
          {isLoadingFile ? (
            <Stack gap={2} p={2} position="relative">
              <Skeleton variant="rounded" w height={500} />
              <Typography position="absolute" left={200} top={250}>
                Carregando os vídeos ...
              </Typography>
            </Stack>
          ) : (
            <Dashboard
              uppy={uppy}
              metaFields={[{ id: 'name', name: 'Name', placeholder: 'File name' }]}
            />
          )}
        </Stack>
      </Scrollbar>
    </Dialog>
  );
};

ProductVideosUploader.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ProductVideosUploader;

import { AuthenticatedHttp } from '../../http/axios';
import { uploadIfNecessary } from './courses';
import { downloadFile } from '../../utils/files';
import { convertTimestampToISODate } from '../../utils/formatTime';

const mapLesson = (lesson) => ({
  id: lesson.id,
  title: lesson.nome,
  thumbnail: {
    preview: lesson.capa,
  },
  content: lesson.descricao,
  video: lesson.video,
  order: lesson.posicao,
  type: 'lesson',
  status: 'published',
  moduleId: lesson.moduloId,
  files: lesson?.files?.map((file) => file.replace(/^.*?-/, '')),
  filesKeys: lesson?.files,
  startIn: lesson?.startIn,
  startDays: lesson?.startDays,
  startDate: lesson?.startDate,
});

export const createLesson = async ({
  courseId,
  moduleId,
  title,
  video,
  content,
  thumbnail,
  files,
  startIn,
  startDays,
  startDate,
}) => {
  const formData = new FormData();
  files?.forEach((file) => {
    formData.append(`files`, file);
  });

  const { data } = files?.length
    ? await AuthenticatedHttp.post('/members/lessons/files/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    : { data: { files: [] } };

  return AuthenticatedHttp.post(`/members/${courseId}/lessons/`, {
    video,
    capa: await uploadIfNecessary({ courseId, image: thumbnail }),
    descricao: content,
    nome: title,
    moduloId: moduleId,
    files: data.files,
    startIn: startIn === 'immediately' ? null : startIn,
    startDays: startDays?.toString(),
    startDate,
  }).then((response) => mapLesson(response.data));
};

export const getLessonById = async ({ courseId, lessonId }) =>
  AuthenticatedHttp.get(`/members/${courseId}/lessons/${lessonId}/`).then((response) =>
    mapLesson(response.data)
  );

export const getLesson = async (lessonId) =>
  AuthenticatedHttp.get(`/members/lessons/${lessonId}/`).then(({ data }) => ({
    id: data?.id,
    title: data?.nome,
    thumbnail: {
      preview: data?.capa,
    },
    content: data?.descricao,
    video: data?.video,
    order: data?.posicao,
    type: 'lesson',
    status: 'published',
    files: data?.files?.map((file) => file.replace(/^.*?-/, '')),
    filesKeys: data?.files,
    releaseType: 'immediate',
    startIn: data?.startIn || 'immediately',
    startDays: data?.startDays,
    startDate: data?.startDate ? convertTimestampToISODate(data.startDate) : null,
  }));

export const updateLesson = async ({
  courseId,
  lessonId,
  title,
  video,
  content,
  thumbnail,
  capa,
  files,
  startIn,
  startDays,
  startDate,
}) => {
  const formData = new FormData();

  const payloadFiles = files?.filter((file) => file instanceof File) || [];

  payloadFiles.forEach((file) => {
    formData.append(`files`, file);
  });

  const updatedFiles = files?.filter((file) => typeof file === 'string') || [];

  const { data } = payloadFiles?.length
    ? await AuthenticatedHttp.post('/members/lessons/files/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    : { data: { files: [] } };

  return AuthenticatedHttp.put(`/members/${courseId}/lessons/${lessonId}/`, {
    video,
    capa: thumbnail ? await uploadIfNecessary({ courseId, image: thumbnail }) : capa || '',
    descricao: content,
    nome: title,
    startIn,
    startDays: startDays?.toString(),
    startDate,
    files: [...updatedFiles, ...data.files],
  });
};

export const downloadLessonFile = async (fileKey) =>
  AuthenticatedHttp.get(`/members/lessons/files/?fileKey=${fileKey}`).then((response) => {
    downloadFile({
      url: response.data.url,
      filename: fileKey.replace(/^.*?-/, ''),
    });
  });

export const deleteLesson = async ({ courseId, lessonId }) =>
  AuthenticatedHttp.delete(`/members/${courseId}/lessons/${lessonId}/`);

import { LoadingButton } from '@mui/lab';
import {
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useParams } from 'react-router';
import { RHFTextField } from '../../../components/hook-form';
import FormProvider from '../../../components/hook-form/FormProvider';
import Iconify from '../../../components/iconify';
import Image from '../../../components/image';
import { ProductModulesContext } from '../../../contexts/ProductModulesContext';
import { updateCourse as updateCourseService } from '../../../services/members/courses';
import ProductModulesHeaderForm from './ProductModulesHeaderForm';

const ProductCourseHeaderEditor = () => {
  const form = useForm({
    defaultValues: {
      name: '',
      coverImage: null,
    },
  });

  const { enqueueSnackbar } = useSnackbar();

  const [editing, setEditing] = useState(false);

  const { course, fetching: isLoading, isError } = useContext(ProductModulesContext);

  const coverImage = useWatch({
    control: form.control,
    name: 'coverImage',
  });

  const { id: courseId } = useParams();

  const { mutateAsync: updateCourse, isLoading: updatingCourse } = useMutation({
    mutationFn: (data) => updateCourseService({ courseId, ...data }),
    onSuccess: () => {
      setEditing(false);

      enqueueSnackbar('Curso atualizado com sucesso', {
        variant: 'success',
      });
    },
    onError: (error) => {
      enqueueSnackbar(error.response.data.detail || 'Não foi possível atualizar o curso', {
        variant: 'error',
      });
    },
  });

  useEffect(() => {
    if (course) {
      form.setValue('name', course.name);
      form.setValue('coverImage', course.coverImage?.preview ? course.coverImage : null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course]);

  return (
    <FormProvider methods={form} onSubmit={form.handleSubmit(updateCourse)}>
      <Drawer
        open={editing}
        onClose={() => {
          setEditing(false);
        }}
        anchor="right"
        PaperProps={{
          sx: {
            p: 2,
            width: {
              xs: '100%',
              sm: 600,
            },
          },
        }}
      >
        <Stack direction="row" alignItems="center" gap={1}>
          <IconButton onClick={() => setEditing(false)}>
            <Iconify icon="eva:chevron-right-fill" />
          </IconButton>
          <Typography variant="subtitle1">Editar Header</Typography>
        </Stack>

        <Stack my={1}>
          <Typography color="text.secondary" variant="body2">
            Escolha uma imagem para o cabeçalho da sua área de membros.
          </Typography>
        </Stack>

        <ProductModulesHeaderForm />

        <Stack direction="row" alignItems="center" gap={1} my={2} justifyContent="flex-end">
          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            loading={updatingCourse}
            onClick={form.handleSubmit(updateCourse)}
          >
            Salvar Imagem
          </LoadingButton>
        </Stack>
      </Drawer>

      <Stack width={1} height={150} position="relative">
        <Image
          src={coverImage?.preview || '/assets/placeholder.svg'}
          alt="empty image"
          sx={{
            objectFit: 'cover',
            width: '100%',
            height: '150px',
          }}
        />
        <Stack direction="row" alignItems="center" gap={1} position="absolute" bottom={5} right={5}>
          <Button
            startIcon={<Iconify icon="eva:edit-2-outline" />}
            variant="contained"
            color="primary"
            onClick={() => setEditing(true)}
            disabled={isError}
          >
            Editar Header
          </Button>
        </Stack>
      </Stack>
      <Stack flex={1} alignItems="end" gap={1} p={2}>
        <RHFTextField
          name="name"
          fullWidth
          size="small"
          label="Nome da área de membros"
          variant="filled"
          onBlur={form.handleSubmit(updateCourse)}
          disabled={isError}
          InputProps={{
            ...(updatingCourse ||
              (isLoading && {
                endAdornment: (
                  <InputAdornment position="end">
                    <CircularProgress size={18} color="inherit" />
                  </InputAdornment>
                ),
              })),
          }}
        />
      </Stack>
    </FormProvider>
  );
};

export default ProductCourseHeaderEditor;

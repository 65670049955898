import PropTypes from 'prop-types';
import { Navigate, useSearchParams } from 'react-router-dom';
// routes
import { PATH_DASHBOARD } from '../routes/paths';
// components
import LoadingScreen from '../components/loading-screen';
//
import { useAuthContext } from './useAuthContext';

// ---------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const { isAuthenticated, isInitialized } = useAuthContext();
  const [searchParams] = useSearchParams();

  const localStorageNextParam = localStorage.getItem('next');
  const nextParam = searchParams.get('next') || localStorageNextParam;

  if (isAuthenticated) {
    return (
      <Navigate
        to={nextParam && !nextParam.startsWith('/auth') ? `/${nextParam.replace(/^\/+/, '')}` : PATH_DASHBOARD.general.home}
      />
    );
  }

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  return <> {children} </>;
}

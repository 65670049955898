import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

const LogoIcon = forwardRef(({ sx, ...other }, ref) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& img': {
        width: 64,
        height: 64,
      },
      ...sx,
    }}
  >
    <picture>
      <img
        srcSet="/logo/green-logo-transparent-background.png"
        alt="logo"
        style={{
          objectFit: 'cover',
          maxWidth: '100%',
        }}
      />
    </picture>
  </Box>
));

LogoIcon.propTypes = {
  sx: PropTypes.object,
};

export default LogoIcon;

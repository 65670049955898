import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  IconButton,
  LinearProgress,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import Uppy from '@uppy/core';
import Portuguese from '@uppy/locales/lib/pt_BR';
import Tus from '@uppy/tus';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import Iconify from '../components/iconify';
import Image from '../components/image';
import TextMaxLine from '../components/text-max-line/TextMaxLine';
import { createVideo, deleteVideo } from '../services/members/videos';
import { fData } from '../utils/formatNumber';

export const VideoUploadStatus = {
  PENDING: 'pending',
  UPLOADING: 'uploading',
  COMPLETED: 'completed',
  ERROR: 'error',
  PAUSED: 'paused',
  CANCELED: 'canceled',
};

export const UploadScopes = {
  IDLE: 'idle',
  PICKING: 'picking',
  UPLOADING: 'uploading',
  COMPLETED: 'completed',
  ERROR: 'error',
};

export const UploadSources = {
  'react:Dashboard': {
    name: 'react:Dashboard',
    image: '/assets/icons/files/ic_video.svg',
  },
  GoogleDrive: {
    name: 'GoogleDrive',
    icon: 'logos:google-drive',
  },
  Dropbox: {
    name: 'Dropbox',
    icon: 'logos:dropbox',
  },
};

export const BatchVideoUploadContext = createContext({
  videos: [],
  uppy: {},
  isLoadingFile: false,
});

const uppy = new Uppy({
  id: 'uppy',
  autoProceed: false,
  debug: true,
  locale: Portuguese,
  restrictions: {
    maxFileSize: 10740000000,
    maxNumberOfFiles: 10,
    minNumberOfFiles: 1,
    allowedFileTypes: ['video/*'],
  },
}).use(Tus, {
  endpoint: 'https://video.bunnycdn.com/tusupload',
  allowedMetaFields: ['AuthorizationSignature', 'AuthorizationExpire', 'LibraryId', 'VideoId'],
  onBeforeRequest: async (req, file) => {
    if (!file.meta.AuthorizationSignature) {
      throw new Error('Ocorreu um erro ao enviar o vídeo');
    }

    req.setHeader('AuthorizationSignature', file.meta.AuthorizationSignature);

    req.setHeader('AuthorizationExpire', file.meta.AuthorizationExpire);

    req.setHeader('LibraryId', file.meta.LibraryId);

    req.setHeader('VideoId', file.meta.VideoId);

    return req;
  },
});

const BatchVideoUploadProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [scope, setScope] = useState(UploadScopes.IDLE);

  const [tick, setTick] = useState(0);

  const [isLoadingFile, setIsLoadingFile] = useState(false);

  const onUpload = useCallback(() => {
    setScope(UploadScopes.UPLOADING);
    enqueueSnackbar('Iniciando upload dos vídeos', { variant: 'info' });
  }, [enqueueSnackbar]);

  const onComplete = useCallback(
    ({ failed }) => {
      setScope(UploadScopes.COMPLETED);
      if (failed.length > 0) {
        enqueueSnackbar('Alguns vídeos falharam ao serem enviados, tente novamente.', {
          variant: 'warning',
        });
        return;
      }
      enqueueSnackbar('Vídeos enviados com sucesso', { variant: 'success' });
    },
    [enqueueSnackbar]
  );

  const onFileAdded = useCallback(
    async (file) => {
      setIsLoadingFile(true);
      createVideo({
        title: file.name,
      })
        .then(({ authorizationSignature, authorizationExpire, libraryId, id }) => {
          uppy.setFileMeta(file.id, {
            AuthorizationSignature: authorizationSignature,
            AuthorizationExpire: authorizationExpire,
            LibraryId: libraryId,
            VideoId: id,
          });
        })
        .catch(() => {
          enqueueSnackbar('Falha ao criar vídeo', { variant: 'error' });

          uppy.removeFile(file.id);

          throw new Error('Falha ao criar vídeo');
        })
        .finally(() => setIsLoadingFile(false));
    },
    [enqueueSnackbar]
  );

  const onFileRemoved = useCallback((file) => {
    if (file.progress.uploadComplete) {
      return;
    }
    deleteVideo({ id: file.meta.VideoId });
  }, []);

  const finish = useCallback(() => {
    setScope(UploadScopes.IDLE);
    uppy.cancelAll();
  }, []);

  const { files, totalProgress } = uppy.getState();

  const videos = Object.values(files);

  uppy.on('upload', onUpload);

  uppy.on('complete', onComplete);

  uppy.off('file-added', null).on('file-added', onFileAdded);

  uppy.off('file-removed', null).on('file-removed', onFileRemoved);

  const bytes = useMemo(() => videos.reduce((acc, video) => acc + video.size, 0), [videos]);

  const uploadedBytes = useMemo(
    () => videos.reduce((acc, video) => acc + video.progress.bytesUploaded, 0),
    [videos]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (scope === UploadScopes.UPLOADING) {
        setTick((prev) => prev + 1);
      }
    }, 500);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scope]);

  const value = useMemo(
    () => ({
      tick,
      files,
      videos,
      finish,
      uppy,
      scope,
      setScope,
      isLoadingFile,
    }),
    [tick, files, videos, finish, scope, isLoadingFile]
  );

  return (
    <BatchVideoUploadContext.Provider value={value}>
      {children}
      {[UploadScopes.UPLOADING, UploadScopes.COMPLETED].includes(scope) && (
        <Card
          sx={{
            position: 'fixed',
            bottom: 0,
            right: 0,
            width: '100%',
            maxWidth: 400,
            zIndex: (theme) => theme.zIndex.snackbar,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
          }}
        >
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<Iconify icon="eva:arrow-ios-downward-outline" />}
              sx={{ px: 2 }}
            >
              <ListItem>
                <ListItemIcon>
                  <Iconify icon="eva:video-fill" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    scope === UploadScopes.COMPLETED ? (
                      <Typography variant="body2" color="text.secondary">
                        Uploads finalizados
                      </Typography>
                    ) : (
                      <Typography variant="body2" color="text.secondary">
                        Subindo {videos.length} vídeo{videos.length > 1 && 's'}
                      </Typography>
                    )
                  }
                />
                {scope === UploadScopes.COMPLETED && (
                  <IconButton
                    size="small"
                    onClick={() => {
                      finish();
                    }}
                    sx={{ mr: 1 }}
                  >
                    <Iconify icon="eva:checkmark-circle-2-fill" color="success.main" />
                  </IconButton>
                )}
              </ListItem>
            </AccordionSummary>
            <AccordionDetails disableGutters>
              <Stack gap={0.5}>
                {videos.map((video) => (
                  <Paper variant="outlined" key={video.id}>
                    <ListItem>
                      <ListItemIcon>
                        {UploadSources[video.source]?.icon ? (
                          <Iconify icon={UploadSources[video.source]?.icon} />
                        ) : (
                          <Image
                            src={UploadSources[video.source]?.image}
                            alt={UploadSources[video.source]?.name}
                            width={40}
                            height={40}
                          />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <TextMaxLine line={1} variant="body2" fontWeight="bold">
                            {video.name}
                          </TextMaxLine>
                        }
                        secondary={
                          <Typography variant="caption" color="text.secondary">
                            {fData(video.size)}
                          </Typography>
                        }
                      />
                      {video.progress.uploadComplete && (
                        <Iconify
                          icon="ic:round-cloud-done"
                          color="success.main"
                          width={20}
                          height={20}
                        />
                      )}
                      {!!video.error && (
                        <Iconify
                          icon="eva:close-circle-fill"
                          color="error.main"
                          width={20}
                          height={20}
                        />
                      )}
                      {video.progress.uploadStarted &&
                        !video.progress.uploadComplete &&
                        !video.error && (
                          <IconButton size="small" onClick={() => uppy.pauseResume(video.id)}>
                            <Iconify icon={video.isPaused ? 'mdi:play' : 'mdi:pause'} />
                          </IconButton>
                        )}
                    </ListItem>
                    {video.progress.uploadStarted &&
                      !video.progress.uploadComplete &&
                      !video.error && (
                        <LinearProgress
                          value={video.progress.percentage}
                          variant="determinate"
                          color={video.status === VideoUploadStatus.PAUSED ? 'warning' : 'primary'}
                        />
                      )}
                  </Paper>
                ))}
                {scope === UploadScopes.UPLOADING && (
                  <Typography variant="caption" color="text.secondary" sx={{ mt: 2 }}>
                    {totalProgress}% - {fData(uploadedBytes)} de {fData(bytes)}
                  </Typography>
                )}
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Card>
      )}
    </BatchVideoUploadContext.Provider>
  );
};

BatchVideoUploadProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BatchVideoUploadProvider;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  Fab,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { arrayMoveImmutable } from 'array-move';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { Container, Draggable } from 'react-smooth-dnd';
import ConfirmDialog from '../../../components/confirm-dialog/ConfirmDialog';
import Iconify from '../../../components/iconify';
import Label from '../../../components/label';
import MenuPopover from '../../../components/menu-popover/MenuPopover';
import {
  ProductLessonDefaultValues,
  ProductLessonsContext,
  ProductModuleLessonTypes,
} from '../../../contexts/ProductLessonsContext';
import { ProductModulesContext } from '../../../contexts/ProductModulesContext';
import { FormScopes } from '../../../utils/form';
import ProductModuleLessonCard from './ProductLessonCard';

const ProductModuleCard = ({ module, onLessonsReorder }) => {
  const { lessons } = module;

  const empty = lessons?.length === 0;

  const [moreAnchor, setMoreAnchor] = useState(null);

  const [addAnchor, setAddAnchor] = useState(null);

  const [name, setName] = useState(module.name || '');

  const {
    scope,
    setScope,
    formValues: moduleFormValues,
    setFormValues: setModuleFormValues,
    update,
    remove,
    expanded,
    setExpanded,
  } = useContext(ProductModulesContext);

  const {
    // selected,
    // setSelected,
    setScope: setLessonsScope,
    setFormValues: setLessonsFormValues,
  } = useContext(ProductLessonsContext);

  // const allLessonsSelected = useMemo(() => {
  //   // eslint-disable-next-line no-restricted-syntax
  //   for (const lesson of lessons) {
  //     if (!selected.includes(lesson.id)) {
  //       return false;
  //     }
  //   }
  //   return true;
  // }, [lessons, selected]);

  // const anyLessonSelected = useMemo(() => {
  //   // eslint-disable-next-line no-restricted-syntax
  //   for (const lesson of lessons) {
  //     if (selected.includes(lesson.id)) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }, [lessons, selected]);

  useEffect(() => {
    setName(module.name);
  }, [module?.name]);

  return (
    <>
      <Card sx={{ mb: 1, bgcolor: 'background.default', boxShadow: 0 }} variant="outlined">
        <CardContent
          sx={{
            position: 'relative',
            p: 2,
            py: 2.5,
          }}
        >
          <Stack direction="row" gap={1} alignItems="center">
            <Iconify
              className="drag-handle-modules"
              icon="ic:sharp-menu"
              sx={{ cursor: 'grab', width: 25, height: 25, mr: 1 }}
            />
            {/* <Checkbox
              checked={allLessonsSelected && !empty}
              disabled={empty}
              onChange={(e) => {
                setSelected((previous) => {
                  if (e.target.checked) {
                    return [...previous, ...lessons.map(({ id }) => id)];
                  }
                  return previous.filter((id) => !lessons.map((m) => m.id).includes(id));
                });
              }}
              indeterminate={anyLessonSelected && !allLessonsSelected}
            /> */}
            <TextField
              value={name}
              onChange={(e) => setName(e.target.value)}
              sx={{
                flex: 1,
              }}
              variant="filled"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Label variant="soft" color="info">
                      {lessons?.length} conteúdo{module?.lessons.length > 1 && 's'}
                    </Label>
                  </InputAdornment>
                ),
                hiddenLabel: true,
              }}
              onBlur={(e) => {
                update({
                  ...module,
                  id: module.id,
                  name: e.target.value,
                });
              }}
            />
            <IconButton
              onClick={(e) => setMoreAnchor(e.currentTarget)}
              sx={{
                alignSelf: 'center',
              }}
            >
              <Iconify icon="eva:more-vertical-outline" />
            </IconButton>
          </Stack>
          <Fab
            size="small"
            color="primary"
            sx={{
              position: 'absolute',
              left: 18,
              bottom: -18,
            }}
            onClick={(e) => setAddAnchor(e.currentTarget)}
          >
            <Iconify icon="eva:plus-outline" />
          </Fab>
        </CardContent>
        <Accordion
          disableGutters
          expanded={expanded.includes(module.id)}
          onChange={(e, newExpanded) => {
            if (newExpanded) {
              return setExpanded([...expanded, module.id]);
            }
            return setExpanded(expanded.filter((id) => id !== module.id));
          }}
          sx={{
            bgcolor: 'background.default',
          }}
        >
          <AccordionSummary
            expandIcon={<Iconify icon="eva:arrow-ios-downward-outline" />}
            sx={{
              '& .MuiAccordionSummary-content': {
                display: 'none',
              },
              bgcolor: 'background.default',
            }}
          />
          <AccordionDetails sx={{ p: 0, bgcolor: 'background.default' }}>
            <Stack spacing={1} p={1} px={3}>
              {empty ? (
                <Stack direction="row" justifyContent="center" width={1}>
                  <Typography variant="caption" color="text.secondary">
                    Nenhum conteúdo adicionado
                  </Typography>
                </Stack>
              ) : (
                <Container
                  dragHandleSelector=".drag-handle-lessons"
                  lockAxis="y"
                  onDrop={({ removedIndex, addedIndex }) => {
                    const ordered = arrayMoveImmutable(lessons, removedIndex, addedIndex).map(
                      (lesson, index) => ({
                        ...lesson,
                        order: index + 1,
                      })
                    );
                    onLessonsReorder({
                      ordered,
                    });
                  }}
                >
                  {lessons.map((lesson) => (
                    <Draggable key={lesson.id}>
                      <ProductModuleLessonCard
                        key={lesson.id}
                        lesson={{ ...lesson, moduleId: module.id }}
                      />
                    </Draggable>
                  ))}
                </Container>
              )}
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Card>
      <MenuPopover
        open={moreAnchor}
        onClose={() => setMoreAnchor(null)}
        disabledArrow
        sx={{
          minWidth: 120,
        }}
      >
        <MenuItem
          onClick={() => {
            setMoreAnchor(null);
            setModuleFormValues(module);
            setScope(FormScopes.EDIT);
          }}
        >
          <Iconify icon="eva:edit-outline" width={20} height={20} />
          Editar
        </MenuItem>
        <MenuItem
          onClick={() => {
            setModuleFormValues(module);
            setMoreAnchor(null);
            setScope(FormScopes.DELETE);
          }}
        >
          <Iconify icon="eva:trash-2-outline" width={20} height={20} />
          Excluir
        </MenuItem>
      </MenuPopover>
      <MenuPopover open={addAnchor} onClose={() => setAddAnchor(null)} disabledArrow>
        <MenuItem
          onClick={() => {
            setLessonsScope(FormScopes.CREATE);
            setAddAnchor(null);
            setLessonsFormValues({
              ...ProductLessonDefaultValues,
              moduleId: module.id,
              type: ProductModuleLessonTypes.lesson.id,
            });
          }}
        >
          <Iconify icon={ProductModuleLessonTypes.lesson.icon} width={20} height={20} />
          Novo conteúdo
        </MenuItem>
        {/* <MenuItem
          onClick={() => {
            setLessonsScope(FormScopes.CREATE);
            setAddAnchor(null);
            setLessonsFormValues({
              ...ProductLessonDefaultValues,
              moduleId: module.id,
              type: ProductModuleLessonTypes.quiz.id,
            });
          }}
        >
          <Iconify icon={ProductModuleLessonTypes.quiz.icon} width={20} height={20} />
          Novo quiz
        </MenuItem> */}
      </MenuPopover>

      <ConfirmDialog
        open={scope === FormScopes.DELETE}
        onClose={() => setScope(FormScopes.INDEX)}
        action={
          <Button
            variant="contained"
            color="error"
            startIcon={<Iconify icon="eva:trash-2-outline" />}
            onClick={() => {
              remove({ ids: [moduleFormValues.id] });
              setScope(FormScopes.INDEX);
            }}
          >
            Remover
          </Button>
        }
        title="Remover módulo"
        content={`Tem certeza que deseja remover o módulo "${moduleFormValues.name}"?`}
      />
    </>
  );
};

ProductModuleCard.propTypes = {
  module: PropTypes.object.isRequired,
  onLessonsReorder: PropTypes.func.isRequired,
};

export default ProductModuleCard;

import { IconButton, MenuItem, TableCell, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import Iconify from '../../../components/iconify';
import SearchableTable from '../../../components/table/SearchableTable';
import { FormScopes } from '../../../utils/form';

import Label from '../../../components/label';
import MenuPopover from '../../../components/menu-popover/MenuPopover';
import { ProductClassesContext } from '../../../contexts/ProductClassesContext';

ProductClassesTable.propTypes = {};

const labels = [
  { id: 'name', label: 'Nome' },
  { id: 'students', label: 'Alunos' },
  { id: 'actions', label: '' },
];

export default function ProductClassesTable() {
  const { count, fetching, classes, table, setFormValues, setScope } =
    useContext(ProductClassesContext);

  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <>
      <MenuPopover open={anchorEl} onClose={() => setAnchorEl(null)} sx={{ width: 120 }}>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setScope(FormScopes.EDIT);
          }}
        >
          <Iconify icon="eva:edit-2-fill" color="text.secondary" />
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Editar
          </Typography>
        </MenuItem>
      </MenuPopover>

      <SearchableTable
        count={count}
        fetching={fetching}
        labels={labels}
        rows={classes}
        table={table}
        renderRow={(row) => (
          <ProductClassesTableRow
            row={row}
            onMoreClick={(event) => {
              setAnchorEl(event.currentTarget);
              setFormValues(row);
            }}
          />
        )}
        onRowClick={(row) => {
          setFormValues(row);
          setScope(FormScopes.SHOW);
        }}
      />
    </>
  );
}

ProductClassesTableRow.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string,
    students: PropTypes.number,
    default: PropTypes.bool,
  }),
  onMoreClick: PropTypes.func,
};

function ProductClassesTableRow({ row, onMoreClick }) {
  return (
    <>
      <TableCell>
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {row.name}
          </Typography>
          {row.default && (
            <Label variant="soft" color="primary">
              Padrão
            </Label>
          )}
        </Stack>
      </TableCell>

      <TableCell>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {row.students}
        </Typography>
      </TableCell>

      <TableCell align="right">
        <IconButton onClick={onMoreClick}>
          <Iconify icon="eva:more-vertical-fill" width={18} />
        </IconButton>
      </TableCell>
    </>
  );
}

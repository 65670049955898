import { AuthenticatedHttp } from '../../http/axios';

export const getProductStudentsService = async ({ id, limit = 10, search, lastEmail, filters }) =>
  AuthenticatedHttp.get(`/members/courses/${id}/users/`, {
    params: {
      limit,
      search,
      lastEmail,
      ...filters,
    },
  })
    .then(({ data }) => data)
    .catch(() => ({
      results: [],
      count: 0,
    }));

export const addProductStudentAccessService = async ({ id, email, access_time }) =>
  AuthenticatedHttp.post(`/members/courses/${id}/add-access/`, {
    email,
    access_time,
  }).then(({ data }) => data);

export const removeProductStudentAccessService = async ({ id, email }) =>
  AuthenticatedHttp.post(`/members/courses/${id}/remove-access/`, {
    email
  }).then(({ data }) => data);

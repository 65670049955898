import { Paper, Button } from '@mui/material';
import { Stack, alpha } from '@mui/system';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import { DeviceFrameset } from 'react-device-frameset';
// eslint-disable-next-line import/no-unresolved
import 'react-device-frameset/styles/marvel-devices.min.css';

export const CoursePreview = ({ open, onClose, courseUrl }) => {
  const [isMobile, setIsMobile] = useState(false);

  const variants = {
    open: {
      display: 'flex',
      opacity: 1,
    },
    closed: {
      display: 'flex',
      opacity: 0,
      transitionEnd: {
        display: 'none',
      },
    },
  };

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    checkIfMobile();

    window.addEventListener('resize', checkIfMobile);

    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  const ref = useRef();

  if (ref.current) {
    ref.current.contentWindow.postMessage(
      {
        type: 'cakto-checkout-config',
      },
      '*'
    );
  }

  return (
    <Stack
      component={motion.div}
      variants={variants}
      initial="closed"
      animate={open ? 'open' : 'closed'}
      exit="closed"
      transition={{ duration: 0.2 }}
      height="calc(100% - 200px)"
      width={1}
      position="fixed"
      left={1}
      sx={{
        zIndex: (theme) => `${theme.zIndex.tooltip + 2} !important`,
        bgcolor: (theme) => alpha(theme.palette.grey[900], 0.5),
      }}
      justifyContent="center"
      alignItems="center"
      onAbort={onClose}
      onClick={onClose}
    >
      <Stack
        component={Paper}
        width={1}
        height={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          bgcolor: (theme) => alpha(theme.palette.grey[900], 0.9),
        }}
      >
        <DeviceFrameset device={isMobile ? 'iPhone X' : 'MacBook Pro'} landscape={!isMobile}>
          <Button
            color="error"
            variant="outlined"
            style={{
              position: 'absolute',
              top: isMobile ? 35 : 10,
              left: '8px',
              zIndex: 9999,
            }}
            onClick={onClose}
          >
            Fechar Prévia
          </Button>
          <Stack height={1}>
            <iframe
              title="Checkout"
              src={courseUrl}
              width="100%"
              height="100%"
              style={{ border: 'none', display: 'block' }}
            />
          </Stack>
        </DeviceFrameset>
      </Stack>
    </Stack>
  );
};

CoursePreview.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  courseUrl: PropTypes.string,
};

import { AuthenticatedHttp } from '../../http/axios';

export const getCheckout = async ({ id }) =>
  AuthenticatedHttp.get(`/product/builder/checkout/${id}/`).then(({ data }) => data);

export const getCheckouts = async ({ id, search = '', status = 'all', page = 1, limit = 10 }) =>
  AuthenticatedHttp.get(`/product/${id}/checkouts/`, {
    params: {
      page,
      search,
      limit,
      ...(status !== 'all' && { status }),
    },
  }).then(({ data: { results: checkouts, count } }) => ({
    checkouts,
    count,
  }));

export const createCheckoutService = async ({ id, ...data }) =>
  AuthenticatedHttp.post(`/product/${id}/checkouts/`, data);

export const updateCheckoutService = async ({ id, ...data }) =>
  AuthenticatedHttp.put(`/product/builder/checkout/${id}/`, data);

export const deleteCheckoutService = async (data) =>
  AuthenticatedHttp.delete(`/product/builder/checkout/${data.id}/`);

export const duplicateCheckoutService = async ({ id }) =>
  AuthenticatedHttp.post(`/product/builder/checkout/${id}/duplicate/`);

import PropTypes from 'prop-types';
// @mui
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Alert,
  Button,
  Dialog,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tab,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { RHFTextField, RHFUpload } from '../../../components/hook-form';
import FormProvider from '../../../components/hook-form/FormProvider';
import Iconify from '../../../components/iconify';
import { ProductModulesContext } from '../../../contexts/ProductModulesContext';
import ProductModuleCoverPreview from './ProductModuleCoverPreview';
import ProductModuleHeaderPreview from './ProductModuleHeaderPreview';
import ProductModulesHeaderForm from './ProductModulesHeaderForm';

ProductModulesForm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default function ProductModulesForm({ open, onClose, onSubmit }) {
  const { classes = [], formValues = {}, setFormValues } = useContext(ProductModulesContext);

  const [tabValue, setTabValue] = useState('general');

  const [deviceType, setDeviceType] = useState('mobile');

  const schema = yup.object().shape({
    name: yup.string().required('Nome é obrigatório'),
    classes: yup.array(),
    // .min(1, 'Selecione pelo menos uma turma'),
    showTitle: yup.boolean(),
    coverImage: yup.mixed().nullable(),
    mobileHeaderType: yup.string().nullable(),
    mobileHeaderImages: yup.array(),
    // .of(yup.mixed().nullable())
    // .when('mobileHeaderType', {
    //   is: 'image',
    //   then: yup
    //     .array()
    //     .min(1, 'Selecione pelo menos uma imagem')
    //     .max(1, 'Selecione apenas uma imagem'),
    // })
    // .when('mobileHeaderType', {
    //   is: 'images',
    //   then: yup
    //     .array()
    //     .min(1, 'Selecione pelo menos uma imagem')
    //     .max(5, 'Selecione até 5 imagens'),
    // }),
    mobileHeaderVideo: yup.mixed().nullable(),
    desktopHeaderType: yup.string().nullable(),
    desktopHeaderImages: yup.array(),
    // .of(yup.mixed().nullable())
    // .when('desktopHeaderType', {
    //   is: 'image',
    //   then: yup
    //     .array()
    //     .min(1, 'Selecione pelo menos uma imagem')
    //     .max(1, 'Selecione apenas uma imagem'),
    // })
    // .when('desktopHeaderType', {
    //   is: 'images',
    //   then: yup
    //     .array()
    //     .min(1, 'Selecione pelo menos uma imagem')
    //     .max(5, 'Selecione até 5 imagens'),
    // }),
    desktopHeaderVideo: yup.mixed().nullable(),
  });

  const defaultValues = {
    name: formValues?.name || '',
    classes: formValues?.classes || [],
    section: formValues?.section || '',
    showTitle: formValues?.showTitle || true,
    coverImage: formValues?.coverImage?.preview ? formValues.coverImage : null,
    mobileHeaderType: formValues?.mobileHeaderType || 'image',
    mobileHeaderImages: formValues?.mobileHeaderImages || [],
    mobileHeaderVideo: formValues?.mobileHeaderVideo || null,
    desktopHeaderType: formValues?.desktopHeaderType || 'image',
    desktopHeaderImages: formValues?.desktopHeaderImages || [],
    desktopHeaderVideo: formValues?.desktopHeaderVideo || null,
  };

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const selected = form.watch('classes');

  const [, setAllClasses] = useState(classes.length === selected.length);

  useEffect(() => {
    setAllClasses(classes.length === selected.length);
  }, [selected, classes]);

  useEffect(() => {
    if (open) {
      form.reset(defaultValues);
      return;
    }
    setFormValues({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <FormProvider methods={form} onSubmit={form.handleSubmit(onSubmit)}>
        <Stack p={2} gap={2}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <ListItem>
              <ListItemIcon>
                <Iconify icon="codicon:file-submodule" width={20} height={20} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body2" fontWeight="thin">
                    Módulos
                  </Typography>
                }
                secondary={
                  <Typography variant="caption" fontWeight="thin" color="text.secondary">
                    Preencha os campos abaixo
                  </Typography>
                }
              />
            </ListItem>
            <IconButton size="small" onClick={onClose}>
              <Iconify icon="mdi:close" width={20} height={20} />
            </IconButton>
          </Stack>

          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <TabContext value={tabValue}>
                <TabList
                  value={tabValue}
                  onChange={(event, newTab) => {
                    setTabValue(newTab);
                  }}
                  variant="scrollable"
                  scrollButtons="auto"
                  sx={{ px: 2 }}
                >
                  <Tab label="Geral" value="general" />
                  <Tab label="Cover" value="cover" />
                  {/* <Tab label="Header" value="header" /> */}
                </TabList>
                <TabPanel sx={{ p: 1.5, py: 2 }} value="general">
                  <Stack gap={2}>
                    <RHFTextField
                      name="name"
                      label="Nome do Módulo"
                      placeholder="Ex: Módulo 1"
                      fullWidth
                      size="small"
                      focused
                    />
                    {/* <Stack>
                      <Typography variant="body2" fontWeight="thin" color="text.secondary">
                        Quais turmas podem acessar?
                      </Typography>
                      <Stack>
                        <FormControlLabel
                          label="Todas as turmas"
                          control={
                            <Checkbox
                              color="primary"
                              checked={allClasses}
                              onChange={(e) => {
                                setAllClasses(e.target.checked);
                                form.setValue(
                                  'classes',
                                  e.target.checked ? classes.map(({ id }) => id) : []
                                );
                              }}
                              size="small"
                              indeterminate={
                                selected.length > 0 && selected.length < classes.length
                              }
                            />
                          }
                        />
                        <RHFMultiCheckbox
                          options={
                            classes.map(({ id, name }) => ({ value: id, label: name })) || []
                          }
                          name="classes"
                          fullWidth
                          size="small"
                        />
                      </Stack>
                    </Stack> */}
                  </Stack>
                </TabPanel>
                <TabPanel sx={{ p: 1.5, py: 2 }} value="cover">
                  <Stack gap={2}>
                    <RHFUpload
                      name="coverImage"
                      maxSize={10000000}
                      onDrop={([file]) => {
                        form.setValue(
                          'coverImage',
                          Object.assign(file, {
                            preview: URL.createObjectURL(file),
                          }),
                          { shouldValidate: true, shouldDirty: true }
                        );
                      }}
                      onRemove={() =>
                        form.setValue('coverImage', null, {
                          shouldValidate: true,
                          shouldDirty: true,
                        })
                      }
                      helperText="Formatos aceitos: JPG ou PNG. Tamanho máximo: 10MB"
                      accept={{ 'image/jpeg': [], 'image/png': [] }}
                    />
                    <Alert severity="info">Tamanho recomendado: 200x300 pixels</Alert>
                    {/* <RHFSwitch
                      name="showTitle"
                      label="Exibir título do módulo"
                      color="primary"
                      size="small"
                    /> */}
                  </Stack>
                </TabPanel>
                <TabPanel sx={{ p: 1.5, py: 2 }} value="header">
                  <Stack
                    gap={2}
                    sx={{
                      maxHeight: '500px',
                    }}
                  >
                    <Box>
                      <ToggleButtonGroup
                        value={deviceType}
                        exclusive
                        onChange={(_, value) => {
                          setDeviceType(value);
                        }}
                        sx={{
                          border: 'none',
                        }}
                      >
                        <ToggleButton value="desktop" color="primary">
                          <Iconify icon="ion:desktop-outline" width={20} height={20} />
                        </ToggleButton>
                        <ToggleButton value="mobile" color="primary">
                          <Iconify icon="gridicons:phone" width={20} height={20} />
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                    {deviceType === 'desktop' ? (
                      <ProductModulesHeaderForm
                        device="desktop"
                        dimensions={{ width: 1280, height: 480 }}
                      />
                    ) : (
                      <ProductModulesHeaderForm
                        device="mobile"
                        dimensions={{ width: 320, height: 480 }}
                      />
                    )}
                  </Stack>
                </TabPanel>
              </TabContext>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {tabValue === 'header' ? (
                <ProductModuleHeaderPreview device={deviceType} />
              ) : (
                <ProductModuleCoverPreview />
              )}
            </Grid>
          </Grid>

          <Stack spacing={1} direction="row" justifyContent="end">
            <Button variant="outlined" onClick={() => onClose()} color="inherit">
              Cancelar
            </Button>
            <LoadingButton variant="contained" type="submit" loading={form.formState.isSubmitting}>
              Salvar
            </LoadingButton>
          </Stack>
        </Stack>
      </FormProvider>
    </Dialog>
  );
}

import { format } from 'date-fns';
import { AuthenticatedHttp } from '../../http/axios';
import { parsePaginationResponse } from '../../utils/api';
import { downloadFile } from '../../utils/files';

export const getOrders = async ({ page, search, status, filter }) =>
  AuthenticatedHttp.get('/orders/', {
    params: {
      page,
      search,
      status,
      ...(filter.startDate &&
        filter.period !== 'all_time' && {
          startDate: format(filter.startDate, 'dd-MM-yyyy'),
        }),
      ...(filter.endDate &&
        filter.period !== 'all_time' && {
          endDate: format(filter.endDate, 'dd-MM-yyyy'),
        }),
      ...(filter.coproductionType !== 'all' && {
        coproductionType: filter.coproductionType,
      }),
      ...(filter.products.length > 0 && {
        products: filter.products,
      }),
      ...(filter?.offers?.length > 0 && {
        offers: filter.offers,
      }),
      ...(filter.affiliates.length > 0 && {
        affiliates: filter.affiliates,
      }),
      ...(filter.paymentMethods.length > 0 && {
        paymentMethods: filter.paymentMethods,
      }),
      ...(filter.status.length > 0 && {
        status: filter.status,
      }),
      ...(filter?.offerType !== 'all' && {
        offerType: filter.offerType,
      }),
    },
  }).then(parsePaginationResponse);

export const refundOrderService = async ({ data }) =>
  AuthenticatedHttp.post(`/order/${data.id}/refund/`).then(({ data: order }) => order);

export const exportReport = async (values) => {
  const { extension, page, search, status, filter } = values;

  AuthenticatedHttp.defaults.responseType = 'blob';

  return AuthenticatedHttp.get(`/orders/export/${extension}/`, {
    params: {
      page,
      search,
      status,
      ...(filter.startDate &&
        filter.period !== 'all_time' && {
          startDate: format(filter.startDate, 'dd-MM-yyyy'),
        }),
      ...(filter.endDate &&
        filter.period !== 'all_time' && {
          endDate: format(filter.endDate, 'dd-MM-yyyy'),
        }),
      ...(filter.coproductionType !== 'all' && {
        coproductionType: filter.coproductionType,
      }),
      ...(filter.offerType !== 'all' && {
        offerType: filter.offerType,
      }),
      ...(filter.products.length > 0 && {
        products: filter.products,
      }),
      ...(filter?.offers?.length > 0 && {
        offers: filter.offers,
      }),
      ...(filter.affiliates.length > 0 && {
        affiliates: filter.affiliates,
      }),
      ...(filter.paymentMethods.length > 0 && {
        paymentMethods: filter.paymentMethods,
      }),
      ...(filter.status.length > 0 && {
        status: filter.status,
      }),
    },
  }).then((response) => {
    downloadFile({
      url: window.URL.createObjectURL(new Blob([response.data])),
      filename: `orders_report.${extension}`,
    });

    AuthenticatedHttp.defaults.responseType = 'json';
  });
};

export const resendOrderEmailService = async (orderId) =>
  AuthenticatedHttp.post(`/order/${orderId}/resend_approved_email/`).then(({ data }) => data);

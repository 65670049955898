import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { createContext, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import useTableFilter from '../hooks/useTableFilter';
import {
  acceptCollaboratorService,
  createCollaboratorService,
  deleteCollaboratorService,
  getCollaboratorsService,
  reinviteCollaboratorService,
  rejectCollaboratorService,
  updateCollaboratorService,
} from '../services/collaborators';
import { parseResponseError } from '../utils/api';
import { FormScopes } from '../utils/form';

export const CollaboratorsContext = createContext({
  table: {},
  form: {},
  collaborators: [],
  fetching: false,
  count: 0,
  invite: () => { },
  inviting: false,
  reinvite: () => { },
  reinviting: false,
  accept: () => { },
  accepting: false,
  update: () => { },
  updating: false,
  remove: () => { },
  removing: false,
  scope: FormScopes.INDEX,
  setScope: () => { },
  collaborator: {},
  setCollaborator: () => { },
});

export const CollaboratorsStatus = {
  pending: {
    label: 'Pendente',
    color: 'warning',
    value: 'pending',
  },
  approved: {
    label: 'Aprovado',
    color: 'success',
    value: 'approved',
  },
  rejected: {
    label: 'Rejeitado',
    color: 'error',
    value: 'rejected',
  },
};

const CollaboratorsProvider = ({ children }) => {
  const table = useTableFilter({
    defaultCurrentPage: 1,
  });

  const [scope, setScope] = useState(FormScopes.INDEX);

  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const [collaborator, setCollaborator] = useState({});

  const schema = yup.object().shape({
    id: yup.string(),
    email: yup.string().email().required(),
    permissions: yup.array(),
  });

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      id: collaborator.id,
      email: collaborator?.owner?.email || '',
      permissions: collaborator?.owner?.permissions || [],
    },
  });

  const { data: { results: collaborators = [], count = 0 } = {}, fetching } = useQuery({
    queryKey: ['collaborators', { page: table.page, search: table.search }],
    queryFn: () =>
      getCollaboratorsService({ page: table.page, search: table.search, limit: table.rowsPerPage }),
    initialData: {
      results: [],
      count: 0,
    },
  });

  const { mutateAsync: invite, isLoading: inviting } = useMutation({
    mutationFn: createCollaboratorService,
    onSuccess: () => {
      queryClient.invalidateQueries('collaborators');

      enqueueSnackbar('Convite enviado com sucesso!', { variant: 'success' });

      setScope(FormScopes.INDEX);
    },
    onError: (error) => {
      if (error?.response?.status === 404) {
        enqueueSnackbar('Nenhum usuário encontrado com este email', { variant: 'error' });
        return;
      }
      enqueueSnackbar(parseResponseError(error), { variant: 'error' });
    },
  });

  const { mutateAsync: reinvite, isLoading: reinviting } = useMutation({
    mutationFn: reinviteCollaboratorService,
    onSuccess: () => {
      queryClient.invalidateQueries('collaborators');

      enqueueSnackbar('Convite reenviado com sucesso!', { variant: 'success' });

      setScope(FormScopes.INDEX);
    },
    onError: (error) => {
      if (error?.response?.status === 404) {
        enqueueSnackbar('Nenhum usuário encontrado com este email', { variant: 'error' });
        return;
      }
      enqueueSnackbar(parseResponseError(error), { variant: 'error' });
    },
  });

  const { mutateAsync: accept, isLoading: accepting } = useMutation({
    mutationFn: acceptCollaboratorService,
    onSuccess: () => {
      queryClient.invalidateQueries('collaborators');

      enqueueSnackbar('Convite aceito com sucesso!', { variant: 'success' });

      setScope(FormScopes.INDEX);
    },
    onError: (error) => {
      if (error?.response?.status === 404) {
        enqueueSnackbar('Nenhum usuário encontrado com este email', { variant: 'error' });
        return;
      }
      enqueueSnackbar(parseResponseError(error), { variant: 'error' });
    },
  });

  const { mutateAsync: reject, isLoading: rejecting } = useMutation({
    mutationFn: rejectCollaboratorService,
    onSuccess: () => {
      queryClient.invalidateQueries('collaborators');

      enqueueSnackbar('Convite rejeitado com sucesso!', { variant: 'success' });

      setScope(FormScopes.INDEX);
    },
    onError: (error) => {
      if (error?.response?.status === 404) {
        enqueueSnackbar('Nenhum usuário encontrado com este email', { variant: 'error' });
        return;
      }
      enqueueSnackbar(parseResponseError(error), { variant: 'error' });
    },
  });

  const { mutateAsync: update, isLoading: updating } = useMutation({
    mutationFn: updateCollaboratorService,
    onSuccess: () => {
      queryClient.invalidateQueries('collaborators');

      enqueueSnackbar('Acesso atualizado com sucesso!', { variant: 'success' });

      setScope(FormScopes.INDEX);
    },
    onError: (error) => {
      enqueueSnackbar(parseResponseError(error), { variant: 'error' });
    },
  });

  const { mutateAsync: remove, isLoading: removing } = useMutation({
    mutationFn: () => deleteCollaboratorService({ id: collaborator.id }),
    onSuccess: () => {
      queryClient.invalidateQueries('collaborators');

      enqueueSnackbar('Acesso removido com sucesso!', { variant: 'success' });

      setScope(FormScopes.INDEX);
    },
    onError: (error) => {
      enqueueSnackbar(parseResponseError(error), { variant: 'error' });
    },
  });

  const value = useMemo(
    () => ({
      table,
      form,
      collaborators,
      fetching,
      count,
      accept,
      accepting,
      reject,
      rejecting,
      invite,
      inviting,
      reinvite,
      reinviting,
      update,
      updating,
      remove,
      removing,
      scope,
      setScope,
      collaborator,
      setCollaborator,
    }),
    [
      table,
      form,
      collaborators,
      fetching,
      count,
      accept,
      accepting,
      reject,
      rejecting,
      invite,
      inviting,
      reinvite,
      reinviting,
      update,
      updating,
      remove,
      removing,
      scope,
      collaborator,
    ]
  );

  return <CollaboratorsContext.Provider value={value}>{children}</CollaboratorsContext.Provider>;
};

CollaboratorsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CollaboratorsProvider;

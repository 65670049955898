import PropTypes from 'prop-types';
// @mui
import { Card, Grid, Typography } from '@mui/material';

EditProductBlock.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.any,
  padding: PropTypes.number,
};

export default function EditProductBlock({ title, description, children, padding = 20, ...props }) {
  return (
    <Grid container>
      <Grid item xs={12} md={5} lg={5} padding={2}>
        <Typography variant="subtitle1" marginBottom={1}>
          {title}
        </Typography>
        <Typography variant="body2" color="gray">
          {description}
        </Typography>
      </Grid>
      <Grid item xs={12} md={7} lg={7}>
        <Card style={{ padding }} {...props}>
          {children}
        </Card>
      </Grid>
    </Grid>
  );
}

import { Button, Grid, Stack } from '@mui/material';
import { useContext } from 'react';
import Iconify from '../../../components/iconify';
import DashboardWidget from '../../../components/widgets/DashboardWidget';
import { ProductStudentsContext } from '../../../contexts/ProductStudentsContext';
import { FormScopes } from '../../../utils/form';
// import { fPercent } from '../../../utils/formatNumber';
import ProductStudentDrawer from './ProductStudentDrawer';
import ProductStudentsFilter from './ProductStudentFilter';
import ProductStudentForm from './ProductStudentForm';
import ProductStudentsTable from './ProductStudentsTable';

const ProductStudentsPanel = () => {
  const { table, scope, setScope, setFormValues, count } =
    useContext(ProductStudentsContext);
  return (
    <>
      <ProductStudentDrawer
        open={scope === FormScopes.SHOW}
        onClose={() => {
          setScope(FormScopes.INDEX);
        }}
      />
      <ProductStudentsFilter
        open={table.showFilter}
        onClose={() => {
          table.setShowFilter(false);
        }}
      />
      <ProductStudentForm
        open={[FormScopes.CREATE, FormScopes.EDIT].includes(scope)}
        onClose={() => {
          setScope(FormScopes.INDEX);
        }}
      />
      <Stack gap={2}>
        <Stack
          direction={{
            xs: 'column',
            sm: 'row',
          }}
          justifyContent="flex-end"
          alignItems="center"
          gap={1}
        >
          {/* <Button
            variant="contained"
            color="inherit"
            startIcon={<Iconify icon="eva:download-outline" />}
            onClick={() => {}}
          >
            Exportar
          </Button> */}
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-outline" />}
            color="primary"
            onClick={(e) => {
              setFormValues({});
              setScope(FormScopes.CREATE);
            }}
          >
            Adicionar aluno
          </Button>
        </Stack>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={4}>
            <DashboardWidget
              title="Número de alunos"
              subtitle={count}
              fetching={false}
              sx={{
                borderLeft: (theme) => `5px solid ${theme.palette.divider}`,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            {/* <DashboardWidget
              title="Progresso"
              subtitle={fPercent(23.7)}
              fetching={false}
              footer="média dos usuários"
              sx={{
                borderLeft: (theme) => `5px solid ${theme.palette.divider}`,
              }}
            /> */}
          </Grid>
          <Grid item xs={12} md={4}>
            {/* <DashboardWidget
              title="Conclusão"
              subtitle={fPercent(23.7)}
              fetching={false}
              footer="concluíram o curso"
              sx={{
                borderLeft: (theme) => `5px solid ${theme.palette.divider}`,
              }}
            /> */}
          </Grid>
          <Grid item xs={12}>
            <ProductStudentsTable />
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default ProductStudentsPanel;

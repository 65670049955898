import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { createContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import useTableFilter from '../hooks/useTableFilter';
import {
  acceptAffiliateService,
  blockAffiliateService,
  createAffiliateService,
  deleteAffiliateService,
  getAffiliates,
  rejectAffiliateService,
  unblockAffiliateService,
  updateAffiliateService,
} from '../services/affiliates';
import { getProductsService } from '../services/products';
import { parseResponseError } from '../utils/api';
import { FormScopes } from '../utils/form';

export const AffiliateStatus = {
  active: {
    label: 'Ativo',
    value: 'active',
    color: 'success',
  },
  waiting: {
    label: 'Analisando',
    value: 'waiting',
    color: 'warning',
  },
  refused: {
    label: 'Rejeitado',
    value: 'refused',
    color: 'error',
  },
  canceled: {
    label: 'Cancelado',
    value: 'canceled',
    color: 'error',
  },
  blocked: {
    label: 'Bloqueado',
    value: 'blocked',
    color: 'error',
  },
};

export const TabStatusMap = {
  active: ['active'],
  pending: ['waiting'],
  disabled: ['refused', 'blocked', 'canceled'],
};

export const AffiliatesContext = createContext({
  table: {},
  affiliates: [],
  fetching: false,
  count: 0,
  setTotal: () => {},
  accepting: false,
  acceptAffiliate: () => {},
  blockAffiliate: () => {},
  blocking: false,
  unblockAffiliate: () => {},
  unblocking: false,
  deleting: false,
  deleteAffiliate: () => {},
  creating: false,
  createAffiliate: () => {},
  updateAffiliate: () => {},
  rejecting: false,
  rejectAffiliate: () => {},
  products: [],
  fetchingProducts: false,
  scope: FormScopes.INDEX,
  setScope: () => {},
  form: {},
  update: () => {},
  loadingUpdate: false,
});

export const AffiliatesDefaultFilter = {
  products: [],
  status: [],
};

const AffiliatesProvider = ({ children, type }) => {
  const [searchParams] = useSearchParams();

  const [tabValue, setTabValue] = useState(searchParams.get('tab') || 'active');

  const [scope, setScope] = useState(FormScopes.INDEX);

  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const status = useMemo(() => TabStatusMap[tabValue] || [], [tabValue]);

  const table = useTableFilter({
    defaultFilter: AffiliatesDefaultFilter,
  });

  const schema = yup.object().shape({
    commission: yup
      .number()
      .min(1, 'A comissão tem que ser igual ou maior que 1%')
      .max(95, 'A comissão não pode ser maior que 95%')
      .required(),
  });

  const form = useForm({
    resolver: yupResolver(schema),
  });

  const {
    data: { products },
    isFetching: fetchingProducts,
  } = useQuery({
    queryFn: () =>
      getProductsService({
        page: 1,
        limit: 1000,
      }),
    queryKey: ['products'],
    initialData: {
      products: [],
    },
  });

  const affiliatesQueryKey = ['affiliates', table.page, table.search, status, table.filter];

  const {
    data: { results: affiliates, count },
    isFetching,
  } = useQuery(affiliatesQueryKey, {
    queryFn: () =>
      getAffiliates({
        page: table.page,
        search: table.search,
        type: 'producer',
        status,
        filter: table.filter,
      }),
    initialData: {
      affiliates: [],
      count: 0,
    },
  });

  useEffect(() => {
    table.setSelected([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabValue]);

  const { mutateAsync: createAffiliate, isLoading: creating } = useMutation({
    mutationFn: createAffiliateService,
    onSuccess: () => {
      queryClient.invalidateQueries('products');

      enqueueSnackbar('Afiliação criada com sucesso!', { variant: 'success' });

      setScope(FormScopes.INDEX);
    },
    onError: (error) => {
      enqueueSnackbar(parseResponseError(error), { variant: 'error' });
    },
  });

  const { mutateAsync: unblockAffiliate, isLoading: unblocking } = useMutation({
    mutationFn: (ids) => unblockAffiliateService(ids),
    onSuccess: () => {
      queryClient.invalidateQueries('products');

      enqueueSnackbar('Afiliação desbloqueada com sucesso!', { variant: 'success' });

      setScope(FormScopes.INDEX);

      table.setSelected([]);
    },
    onError: (error) => {
      enqueueSnackbar(parseResponseError(error), { variant: 'error' });
    },
  });

  const { mutateAsync: blockAffiliate, isLoading: blocking } = useMutation({
    mutationFn: (ids) => blockAffiliateService(ids),
    onSuccess: () => {
      queryClient.invalidateQueries('products');

      enqueueSnackbar('Afiliação bloqueada com sucesso!', { variant: 'success' });

      setScope(FormScopes.INDEX);

      table.setSelected([]);
    },
    onError: (error) => {
      enqueueSnackbar(parseResponseError(error), { variant: 'error' });
    },
  });

  const { mutateAsync: update, isLoading: updating } = useMutation({
    mutationFn: updateAffiliateService,
    onSuccess: () => {
      enqueueSnackbar('Afiliação atualizada com sucesso!', { variant: 'success' });

      setScope(FormScopes.INDEX);
    },
    async onMutate({ id, info }) {
      await queryClient.cancelQueries({ queryKey: affiliatesQueryKey });
      const previousData = queryClient.getQueryData(affiliatesQueryKey);

      const newData = {
        ...previousData,
        results: previousData.results.map((previous) => {
          if (id.toString() === previous.id.toString()) {
            return {
              ...previous,
              ...info,
            };
          }

          return previous;
        }),
      };

      queryClient.setQueryData(affiliatesQueryKey, newData);

      return { previousData };
    },
    onError: (error) => {
      queryClient.invalidateQueries(affiliatesQueryKey);
      enqueueSnackbar(parseResponseError(error), { variant: 'error' });
    },
  });

  const { mutateAsync: acceptAffiliate, isLoading: accepting } = useMutation({
    mutationFn: (ids) => acceptAffiliateService(ids),
    onSuccess: () => {
      queryClient.invalidateQueries('products');

      enqueueSnackbar('Afiliação aceita com sucesso!', { variant: 'success' });

      setScope(FormScopes.INDEX);

      table.setSelected([]);
    },
    onError: (error) => {
      enqueueSnackbar(parseResponseError(error), { variant: 'error' });
    },
  });

  const { mutateAsync: rejectAffiliate, isLoading: rejecting } = useMutation({
    mutationFn: (ids) => rejectAffiliateService(ids),
    onSuccess: () => {
      queryClient.invalidateQueries('products');

      enqueueSnackbar('Afiliação rejeitada com sucesso!', { variant: 'success' });

      setScope(FormScopes.INDEX);
    },
    onError: (error) => {
      enqueueSnackbar(parseResponseError(error), { variant: 'error' });
    },
  });

  const { mutateAsync: deleteAffiliate, isLoading: deleting } = useMutation({
    mutationFn: deleteAffiliateService,
    onSuccess: () => {
      queryClient.invalidateQueries('products');

      enqueueSnackbar('Afiliação cancelada com sucesso!', { variant: 'success' });

      setScope(FormScopes.INDEX);
    },
    onError: (error) => {
      enqueueSnackbar(parseResponseError(error), { variant: 'error' });
    },
  });

  const value = useMemo(
    () => ({
      table,
      affiliates: affiliates || [],
      fetching: isFetching,
      count,
      status,
      tabValue,
      setTabValue,
      products,
      fetchingProducts,
      type,
      createAffiliate,
      creating,
      deleting,
      deleteAffiliate,
      rejecting,
      rejectAffiliate,
      accepting,
      acceptAffiliate,
      unblockAffiliate,
      blocking,
      unblocking,
      blockAffiliate,
      scope,
      setScope,
      form,
      update,
      loadingUpdate: updating,
    }),
    [
      table,
      affiliates,
      unblockAffiliate,
      blockAffiliate,
      isFetching,
      blocking,
      unblocking,
      count,
      status,
      tabValue,
      products,
      fetchingProducts,
      type,
      createAffiliate,
      creating,
      deleting,
      deleteAffiliate,
      rejecting,
      rejectAffiliate,
      accepting,
      acceptAffiliate,
      scope,
      form,
      update,
      updating,
    ]
  );

  return <AffiliatesContext.Provider value={value}>{children}</AffiliatesContext.Provider>;
};

AffiliatesProvider.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
};

export default AffiliatesProvider;

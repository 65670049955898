import { createContext, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

export const CoursesContext = createContext({
  courses: [],
  isFetchingCourses: false,
  search: '',
  setSearch: () => {},
});

const CoursesProvider = ({ children }) => {
  const [search, setSearch] = useState('');

  const value = useMemo(
    () => ({
      courses: [],
      isFetchingCourses: false,
      search,
      setSearch,
    }),
    [search, setSearch]
  );

  return <CoursesContext.Provider value={value}>{children}</CoursesContext.Provider>;
};

CoursesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CoursesProvider;
